<style>
.wrapper {
  @apply pb-10 text-base;
}

.item-url {
  @apply underline;
}

.separator {
  @apply mx-4;
}
</style>

<script>
import Icon from "@local/components/images/Icon.svelte"

export let items = []
export let className = ""
</script>

<div class="wrapper text {className}">
  {#each items as item, i}
    {#if item.url}
      <a class="item-url" href="{item.url}">
        {#if item.icon}
          <Icon size="xl"><span class="mr-2">{item.icon}</span></Icon>
        {/if}
        <span class="items-center">{item.label}</span>
      </a>
      {#if i !== items.length - 1}<span class="separator">&gt;</span>{/if}
    {:else}
      <span>
        {item.label}
        {#if i !== items.length - 1}<span class="separator">&gt;</span
          >{/if}</span
      >
    {/if}
  {/each}
</div>
