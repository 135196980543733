<script>
import Call from "@local/components/Call.svelte"
import SMS from "@local/components/SMS.svelte"

export let content = []
export let reduced = true
export let typesToShow = []
export let emptyListMessage = "empty list"
export let className = ""
</script>

{#if content?.length && typesToShow?.length}
  <div class="px-2 overflow-y-auto overflow-x-hidden {className}">
    {#each content as item}
      {#if item.type === "call" && (typesToShow.includes("all") || typesToShow.includes("call"))}
        <Call call="{item}" reduced="{reduced}" />
      {:else if item.type === "sms" && (typesToShow.includes("all") || typesToShow.includes("sms"))}
        <SMS sms="{item}" reduced="{reduced}" />
      {/if}
    {/each}
  </div>
{:else}
  <div class="px-4 flex justify-center mt-4 pb-4">
    <p class="text italic">{emptyListMessage}</p>
  </div>
{/if}
