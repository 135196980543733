<style>
button {
  @apply rounded m-0 text-black outline-none p-4 px-5;
}

button :global(.icon) {
  @apply flex justify-center items-center h-7 text-2xl;
}

.selected {
  @apply outline-none border-r;
  border-color: var(--primary);
  color: var(--primary);
  background-color: white;
}
</style>

<script>
import { getContext } from "svelte"
import { TABS } from "./VerticalTabs.svelte"

export let label = ""
export let icon = ""

const tab = {}
const { registerTab, selectTab, selectedTab } = getContext(TABS)

registerTab(tab)
</script>

<button
  class="tab text-left type-paragraph"
  class:selected="{$selectedTab === tab}"
  on:click|preventDefault|stopPropagation="{() => selectTab(tab)}"
>
  <i class="material-icons mr-4 align-middle">{icon}</i>
  {label}
</button>
