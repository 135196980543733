<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-5/12 rounded-md shadow-sm px-4 py-6 overflow-auto;
  max-height: 90vh;
  min-width: 60vw;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import { format } from "date-fns-tz"
import API, { handleErrors } from "@local/utils/api"
import TextArea from "@local/components/form/TextArea.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Select from "@local/components/form/Select.svelte"

export let wfId
export let note

const dispatch = createEventDispatcher()
const types = ["General", "SMS", "No Show", "Follow Up Call"]
const outcomes = {
  General: ["", "HC check done", "Med check done", "VM returned", "Misc"],
  SMS: ["", "Needs call back", "Not interested", "Booked"],
  "No Show": [
    "",
    "1st no show / Call back",
    "2nd no show / Call back",
    "3rd+ no show / No action",
    "Not interested",
  ],
  "Follow Up Call": ["", "Renewal", "Cancel/Reschedule", "Other"],
}
const periods = ["", "Morning EST", "Afternoon EST", "Evening EST"]

let errors
let form = {
  message: note?.message || "",
  reminder: note?.reminder ? format(new Date(note.reminder), "yyyy-MM-dd") : "",
  period: note?.period || "",
  outcome: note?.outcome || "",
  type: note?.type || "General",
}

//$: form.outcome = note?.outcome || ""
//$: form.type = note?.type || "General"

validate.extend(validate.validators.datetime, {
  parse: function (value) {
    return new Date(value)
  },
  format: function (value) {
    return format(new Date(value), "yyyy-MM-dd")
  },
})

async function submit() {
  if (form.type === "General") {
    errors =
      validate(
        form,
        {
          type: { presence: { allowEmpty: false } },
          message: { presence: { allowEmpty: false } },
        },
        { fullMessages: false }
      ) || {}
  } else {
    errors =
      validate(
        form,
        {
          type: { presence: { allowEmpty: false } },
          outcome: { presence: { allowEmpty: false } },
        },
        { fullMessages: false }
      ) || {}
  }

  if (form.reminder) {
    let reminderError = validate(
      form,
      {
        reminder: {
          datetime: {
            earliest: new Date(),
          },
        },
      },
      { fullMessages: false }
    )
    errors = Object.assign(errors || {}, reminderError)
  }

  if (Object.keys(errors).length > 0) {
    return
  }

  try {
    if (!note) {
      await API.post(`admin/workflow/${wfId}/notes`, {
        json: { ...form },
        searchParams: { role: "admin" },
      }).json()
    } else {
      await API.patch(`admin/workflow/${wfId}/notes/${note.noteIndex}`, {
        json: { ...form, originalNote: note },
        searchParams: { role: "admin" },
      }).json()
    }

    dispatch("modal")
    dispatch("close")
  } catch (err) {
    errors = await handleErrors({ err })
  }
}
</script>

<div class="background">
  <div class="modal">
    <h2 class="mb-8 h2">Note</h2>
    <form on:submit|preventDefault="{submit}" class="h-full">
      <Select
        label="Type"
        name="type"
        bind:value="{form.type}"
        options="{types}"
        errors="{errors && errors.type}"
        class="mb-4"
      />
      <Select
        label="Status"
        name="status"
        bind:value="{form.outcome}"
        options="{outcomes[form.type]}"
        errors="{errors && errors.outcome}"
        class="mb-4"
      />
      <TextArea
        bind:value="{form.message}"
        label="Notes"
        name="notes"
        errors="{errors && errors.message}"
        class="mb-4"
        rows="5"
      />
      <div class="flex flex-col md:flex-row mb-4">
        <TextInput
          label="Reminder"
          class="w-full mb-4 md:w-1/2 md:pr-2"
          bind:value="{form.reminder}"
          name="reminder"
          errors="{errors && errors.reminder}"
          type="date"
        />
        <Select
          label="Best time"
          name="period"
          bind:value="{form.period}"
          options="{periods}"
          errors="{errors && errors.period}"
          class="w-full md:w-1/2"
        />
      </div>
      <div class="flex justify-end gap-2 mt-6">
        <button
          type="button"
          class="btn btn-outline-red"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid-red">Save</button>
      </div>
    </form>
  </div>
</div>
