<script>
import { Link } from "svelte-routing"
</script>

<nav
  class="flex flex-wrap items-center justify-center bg-slate-900 text-gray-400 mb-5"
>
  <Link to="/reports/starseed" class="block hover:text-white px-3 py-3"
    >Starseed</Link
  >
  <Link to="/reports/starseed-temp" class="block hover:text-white px-3 py-3"
    >Starseed Temp</Link
  >
  <Link
    to="/reports/starseed-privacy-policy-offenses"
    class="block hover:text-white px-3 py-3"
    >Starseed Commercial Policy Offenses</Link
  >
  <Link to="/reports/purchases" class="block hover:text-white px-3 py-3"
    >Purchases</Link
  >
</nav>
