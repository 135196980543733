<script>
import { createEventDispatcher } from "svelte"
import Icon from "@local/components/images/Icon.svelte"

const dispatch = createEventDispatcher()

export let page = 0
export let hasNextPage = false
export let showCounter = true
export let allowNegativePage = false
export let label = ""
$: hasPreviousPage = page > 0 || allowNegativePage

function next() {
  page = page + 1
  dispatch("next")
}

function prev() {
  page = page - 1
  dispatch("prev")
}
</script>

<div class="flex items-center {$$props.class || ''}">
  <button
    class="flex items-center justify-center w-10 h-10 btn btn-outline"
    disabled="{!hasPreviousPage}"
    on:click|preventDefault="{prev}"><Icon size="xl2">arrow_left</Icon></button
  >

  <div
    class="flex items-center justify-center text-center h-10 px-4 font-semibold bg-white border rounded dark:bg-tertiary-dark dark:text-white dark:border-gray-400 mx-1"
  >
    {#if showCounter}{page + 1}{:else}<span class="text-sm">{label}</span>{/if}
  </div>

  <button
    class="flex items-center justify-center flex-shrink-0 w-10 h-10 btn btn-outline"
    class:bg-gray-100="{!hasNextPage}"
    disabled="{!hasNextPage}"
    on:click|preventDefault="{next}"><Icon size="xl2">arrow_right</Icon></button
  >
</div>
