import { readable, writable } from "svelte/store"

import * as VideoHelpers from "@shared/utils/twilio"

export const devices = writable()
export const cameraId = writable()
export const speakerId = writable()
export const microphoneId = writable()

const syncLocalStorage = (device) => (value) => {
  const storedIds = JSON.parse(localStorage.getItem("storedIds") || "{}")
  storedIds[`${device}Id`] = value
  localStorage.setItem("storedIds", JSON.stringify(storedIds))
}

cameraId.subscribe(syncLocalStorage("camera"))
speakerId.subscribe(syncLocalStorage("speaker"))
microphoneId.subscribe(syncLocalStorage("microphone"))

export const ready = readable(false, function (set) {
  ;(async function () {
    const {
      videoinput: cameras,
      audiooutput: speakers,
      audioinput: microphones,
    } = await VideoHelpers.getDeviceSelectionOptions()

    devices.set({
      cameras,
      speakers,
      microphones,
    })

    const storedIds = JSON.parse(localStorage.getItem("storedIds") || "{}")

    cameraId.set(
      storedIds.cameraId && cameras.find(({}) => storedIds.cameraId)
        ? storedIds.cameraId
        : cameras[0].deviceId
    )

    if (speakers.length > 0)
      speakerId.set(
        storedIds.speakerId && speakers.find(({}) => storedIds.speakerId)
          ? storedIds.speakerId
          : speakers[0].deviceId
      )

    microphoneId.set(
      storedIds.microphoneId && microphones.find(({}) => storedIds.microphoneId)
        ? storedIds.microphoneId
        : microphones[0].deviceId
    )

    set(true)
  })()

  return function () {}
})
