<style>
.isNotMobile {
  display: grid;
  grid-template-columns: 232px 1fr;
}
@media (max-width: 550px) {
  .isNotMobile {
    grid-template-columns: 232px 1fr;
  }
}
</style>

<script>
import { useQueryClient, useQuery } from "@sveltestack/svelte-query"
import {
  addDays,
  isSameHour,
  setHours,
  startOfDay,
  endOfDay,
  subDays,
  parseISO,
} from "date-fns"

import { format, utcToZonedTime, toDate } from "date-fns-tz"
import API from "@local/utils/api"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import Pager from "@local/components/Pager.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import { currentUser } from "@local/store/auth"

import Icon from "@local/components/images/Icon.svelte"
import { hourLabels, hours, slotTypes } from "./constants"
import CreateSlotsModal from "./CreateSlotsModal.svelte"
import ReserveSlotModal from "./ReserveSlotModal.svelte"
import PreAssignDoctorModal from "./PreAssignDoctorModal.svelte"
import ExplainerTagModal from "./ExplainerTagModal.svelte"
import Timezone from "./Timezone.svelte"
import SelectTags from "./SelectTags.svelte"
import ConsultType from "./ConsultType.svelte"
import Jumper from "./Jumper.svelte"
import Capacity from "./Capacity.svelte"
import TagColumn from "./TagColumn.svelte"
import { findTagColumnAvailableToSchedule, getTagColumnsAmount } from "./utils"

export function toDateOnTz(date) {
  return toDate(date, { timeZone })
}

export function parseOnTz(date, timeZone) {
  return utcToZonedTime(date, timeZone)
}

const queryClient = useQueryClient()

const TEN_MINUTES = 10 * 60 * 1000
let page = 0

let jumper = false
let createSlotsModal = false
let reserveSlotModal = false
let preAssignDoctorModal = false
let explainerTagModal = false
let explainerTagModalContent = null
let checkedIds = { type: null, ids: [] }
let workflowIds = []

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
let selectedTags = []
let optionsTags = []
let consultTypeFilter = "All Consults"

let currDay = format(toDateOnTz(new Date()), "yyyy-MM-dd", { timeZone })

let relevantHours = {}

let yPosition

async function fetchPreAssignedDoctors() {
  const searchParams = {
    role: "doctor",
  }
  return await API.get("admin/pre-assigned-doctors", { searchParams }).json()
}
const preAssignedDoctorsQuery = useQuery({
  queryKey: ["pre-assigned-doctors"],
  queryFn: fetchPreAssignedDoctors,
  refetchOnWindowFocus: true,
  refetchInterval: TEN_MINUTES,
})
$: preAssignedDoctors = $preAssignedDoctorsQuery.data || []

async function fetchScheduleTags() {
  return await API.get(`admin/schedule-tags`).json()
}
const scheduleTagsQuery = useQuery({
  queryKey: ["schedule-tags"],
  queryFn: fetchScheduleTags,
  refetchOnWindowFocus: false,
})
$: scheduleTags = $scheduleTagsQuery.data || []

function defineColumnToSchedules(hourSchedules, columnsAllocatedUntil) {
  let schedulesByHour = hourSchedules.map((hourSchedule) => {
    const startsAt = parseOnTz(hourSchedule.startsAt, timeZone)
    const endsAt = parseOnTz(hourSchedule.endsAt, timeZone)

    const column = findTagColumnAvailableToSchedule(
      columnsAllocatedUntil,
      startsAt,
      endsAt
    )

    return { ...hourSchedule, column }
  })

  return schedulesByHour
}

async function fetchSchedules(currDay) {
  const day = parseISO(currDay)
  const startsAt = startOfDay(day).toISOString()
  const endsAt = endOfDay(day).toISOString()
  const searchParams = {
    role: "admin",
    startsAt,
    endsAt,
    consultType: consultTypeFilter,
  }
  const apiResults = await API.get(`admin/schedules/per-tags`, {
    searchParams,
  }).json()

  const sortedApiResults = apiResults.sort((a, b) => {
    const aTag = a.tags[0]
    const bTag = b.tags[0]
    return aTag > bTag ? 1 : aTag < bTag ? -1 : 0
  })

  const results = []
  relevantHours = {}
  for (let schedulesPerTags of sortedApiResults) {
    let tagHours = []

    let columnsAllocatedUntil = {}
    for (let hour of hours) {
      const hourSchedules = schedulesPerTags.schedules.filter((schedule) =>
        isSameHour(parseOnTz(schedule.startsAt, timeZone), setHours(day, hour))
      )

      const schedulesByHour = defineColumnToSchedules(
        hourSchedules,
        columnsAllocatedUntil
      )

      if (schedulesByHour.length > 0) {
        relevantHours = { ...relevantHours, [hour]: 1 }
      }

      tagHours.push(schedulesByHour)
    }

    const columns = getTagColumnsAmount(tagHours)

    results.push({
      tags: schedulesPerTags.tags,
      columns,
      tagHours,
    })
  }

  const newOptionsTag = [...new Set(results.map((tag) => tag.tags[0].label))]
  if (JSON.stringify(newOptionsTag) !== JSON.stringify(optionsTags)) {
    optionsTags = newOptionsTag
  }

  return { results }
}
$: scheduleQueryOpts = {
  refetchOnWindowFocus: true,
  refetchInterval: TEN_MINUTES,
  queryKey: ["schedules", currDay, timeZone, consultTypeFilter],
  queryFn: () => fetchSchedules(currDay),
  keepPreviousData: false,
  enabled: $scheduleTagsQuery.isSuccess,
}
const scheduleQuery = useQuery(scheduleQueryOpts)
$: scheduleQuery.setOptions(scheduleQueryOpts)

function invalidate() {
  queryClient.invalidateQueries("schedules")
}

function toggleCheck({ detail: { _id, type, workflowId, action } }) {
  if (action === "check") {
    checkedIds.type = type
    checkedIds.ids = [...checkedIds.ids, _id]

    if (workflowId) {
      workflowIds.push(workflowId)
    }
  } else {
    checkedIds.ids = [...checkedIds.ids.filter((x) => x !== _id)]
    if (!checkedIds.ids.length) {
      checkedIds.type = null
    }
    if (workflowId) {
      workflowIds = workflowIds.filter((wf) => wf !== workflowId)
    }
  }
}

async function deleteChecked() {
  if (checkedIds.ids.length <= 1) {
    window.alert("Select at least 2 slots")
    return
  }

  if (
    window.confirm("Are you sure you want to deleted the selected time slot?")
  ) {
    const searchParams = checkedIds.ids.map((x) => ["ids", x])
    await API.delete(`admin/schedules`, { searchParams })
    invalidate()
    checkedIds = { type: null, ids: [] }
  }
}

async function hideShowChecked(action) {
  if (checkedIds.ids.length <= 1) {
    window.alert("Select at least 2 slots")
    return
  }

  if (
    window.confirm(
      `Are you sure you want to ${action} the selected time slots?`
    )
  ) {
    const searchParams = checkedIds.ids.map((x) => ["ids", x])

    await API.put(`admin/schedules/${action}`, { searchParams })
    invalidate()
    checkedIds = { type: null, ids: [] }
  }
}

async function slotsPreAssigned() {
  invalidate()
  workflowIds = []
  checkedIds = { type: null, ids: [] }
}

async function showTagExplainer(tag) {
  explainerTagModalContent = scheduleTags.find(
    (scheduleTag) => scheduleTag.label === tag
  )
  explainerTagModal = !!explainerTagModalContent
}
</script>

{#if createSlotsModal && $scheduleTagsQuery.isSuccess}
  <CreateSlotsModal
    scheduleTags="{scheduleTags}"
    timezone="{timeZone}"
    on:close="{() => (createSlotsModal = false)}"
    on:slotsCreated="{invalidate}"
  />
{/if}

{#if reserveSlotModal}
  <ReserveSlotModal
    on:close="{() => (reserveSlotModal = undefined)}"
    on:slotReserved="{invalidate}"
    scheduleId="{reserveSlotModal}"
  />
{/if}

{#if preAssignDoctorModal}
  <PreAssignDoctorModal
    selectedIds="{workflowIds}"
    doctorOptions="{preAssignedDoctors}"
    on:close="{() => (preAssignDoctorModal = false)}"
    on:slotsPreAssigned="{slotsPreAssigned}"
  />
{/if}

{#if explainerTagModal}
  <ExplainerTagModal
    tag="{explainerTagModalContent}"
    on:close="{() => (explainerTagModal = false)}"
  />
{/if}

{#if jumper && $scheduleTagsQuery.isSuccess}
  <Jumper
    on:close="{() => (jumper = false)}"
    on:changeDate="{({ detail }) => {
      currDay = detail
      jumper = false
    }}"
    jumper="{jumper}"
    timezone="{timeZone}"
    scheduleTags="{scheduleTags}"
    date="{currDay}"
  />
{/if}

<div class="isNotMobile p-5">
  {#if $scheduleTagsQuery.isSuccess}
    <Capacity
      timeZone="{timeZone}"
      scheduleTags="{scheduleTags}"
      on:tagClick="{({ detail: tag }) => (jumper = tag)}"
      on:tagInfo="{({ detail: tag }) => showTagExplainer(tag)}"
    />
  {/if}

  {#if $scheduleQuery.isSuccess}
    <div>
      <div class="flex justify-start items-center flex-shrink-0 mb-2">
        <div class="flex items-center gap-2">
          <TextInput
            class="w-40 h-10"
            bind:value="{currDay}"
            name="currDay"
            type="date"
          />

          <Timezone class="w-56" bind:value="{timeZone}" />
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
            on:click="{() => (createSlotsModal = true)}"
          >
            <Icon size="3xl">add</Icon>
          </button>

          <Pager
            bind:page="{page}"
            on:next="{() => {
              currDay = format(addDays(parseISO(currDay), 1), 'yyyy-MM-dd')
            }}"
            on:prev="{() => {
              currDay = format(subDays(parseISO(currDay), 1), 'yyyy-MM-dd')
            }}"
            label="{format(
              parseISO(currDay),
              `${checkedIds.ids.length > 0 ? 'MMM do' : 'E, MMM do'}`
            )}"
            hasNextPage="{true}"
            showCounter="{false}"
            allowNegativePage="{true}"
          />

          {#key optionsTags}
            <SelectTags bind:value="{selectedTags}" placeholder="All calendars">
              {#each optionsTags as tag}
                <option value="{tag}">{tag}</option>
              {/each}
            </SelectTags>
          {/key}

          <ConsultType class="w-32" bind:value="{consultTypeFilter}" />

          {#if checkedIds.ids.length > 0}
            {#if checkedIds.type === slotTypes.available && $currentUser.roleLevel < 2}
              <button
                class="flex items-center justify-center flex-shrink-0 px-4 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
                on:click="{deleteChecked}">Delete Selected</button
              >
              <button
                class="flex items-center justify-center flex-shrink-0 px-4 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
                on:click="{() => hideShowChecked('hide')}">Hide Selected</button
              >
              <button
                class="flex items-center justify-center flex-shrink-0 px-4 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
                on:click="{() => hideShowChecked('show')}">Show Selected</button
              >
            {:else}
              <button
                class="flex items-center justify-center flex-shrink-0 px-4 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
                on:click="{() => (preAssignDoctorModal = true)}"
                >Pre Assign Doctor</button
              >
            {/if}
          {/if}
        </div>
      </div>
      <div
        class="overflow-auto"
        style="max-height: calc(100vh - 8rem); max-width: calc(100vw - 30rem); min-width: 40rem;"
      >
        <div class="flex bg-white border-gray-200 border">
          <div
            class="w-16 pb-2 bg-white border border-l-0 border-t-0 border-gray-200"
          >
            <div class="flex items-center justify-center h-12 border-b"></div>
            <div class="h-2 border-b border-dashed"></div>
            {#each hours as hour}
              <div
                class="flex flex-col items-end w-full pr-2 font-semibold border-b border-dashed"
                class:h-36="{relevantHours[hour]}"
                class:h-8="{!relevantHours[hour]}"
              >
                {hourLabels[hour]}
              </div>
            {/each}
          </div>
          <div class="flex-grow-0 flex flex-row">
            {#each $scheduleQuery.data?.results as { tags, columns, tagHours }}
              <TagColumn
                tags="{tags}"
                columns="{columns}"
                tagHours="{tagHours}"
                visible="{selectedTags.length
                  ? selectedTags.includes(tags[0].label)
                  : true}"
                relevantHours="{relevantHours}"
                checkedIds="{checkedIds}"
                stickyTag="{yPosition > 50}"
                timezone="{timeZone}"
                on:invalidate="{invalidate}"
                on:toggleCheck="{toggleCheck}"
                on:reserveSlotModal="{({ detail }) =>
                  (reserveSlotModal = detail)}"
              />
            {/each}
          </div>
        </div>
      </div>
    </div>
  {/if}
</div>

{#if $scheduleQuery.isError}
  <EmptyMessage icon="error">{$scheduleQuery.error}</EmptyMessage>
{/if}

<svelte:window bind:scrollY="{yPosition}" />
