<script>
import Icon from "@shared/components/Icon.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"
import CommunicationHeader from "./CommunicationHeader.svelte"

export let call
export let reduced = true
</script>

<div class="ml-2 border-l-2">
  <CommunicationHeader
    direction="{call?.direction}"
    reduced="{reduced}"
    target="{call?.target}"
    contact="{call?.contact}"
  />

  <div class="bg-white rounded p-1">
    <!-- {#if call?.calling}
      <div class="text-sm flex items-center" title="calling...">
        <Icon class="text-gray-400 mr-2">settings_phone</Icon>
        <TemplateString
          content="{`{{${call.calling.startedAt}||formatDateShort}} {{${call.calling.startedAt}||formatFullTime}}`}"
        />
      </div>
    {/if}

    {#if call?.ringing}
      <div class="text-sm flex items-center" title="ringing...">
        <Icon class="text-gray-400 mr-2">ring_volume</Icon>
        <TemplateString
          content="{`{{${call.ringing.startedAt}||formatDateShort}} {{${call.ringing.startedAt}||formatFullTime}}`}"
        />
      </div>
    {/if} -->

    {#if call?.hangup}
      {#if call.direction === "inbound"}
        <div class="text-sm flex items-center" title="hangup">
          <Icon class="text-gray-400 mr-2">ring_volume</Icon>
          <TemplateString
            content="{`{{${call.hangup.startedAt}||formatDateShort}} {{${call.hangup.startedAt}||formatFullTime}}`}"
          />
        </div>
      {:else}
        <div class="text-sm flex items-center" title="hangup">
          <Icon class="text-gray-400 mr-2">settings_phone</Icon>
          <TemplateString
            content="{`{{${call.hangup.startedAt}||formatDateShort}} {{${call.hangup.startedAt}||formatFullTime}}`}"
          />
        </div>
      {/if}

      {#if call?.hangup.connectedAt}
        {#if call.hangup.duration}
          <div class="text-sm flex items-center ml-8" title="connected">
            <p class="text-xs text-gray-400 ml-1">
              Connected during {call.hangup.duration}
            </p>
          </div>
        {/if}
        <div class="text-sm flex items-center" title="hangup">
          <Icon class="text-gray-400 mr-2">call_end</Icon>
          <div>
            <TemplateString
              content="{`{{${call.hangup.endedAt}||formatDateShort}} {{${call.hangup.endedAt}||formatFullTime}}`}"
            />
          </div>
        </div>
      {/if}
    {/if}

    {#if call?.dispositions}
      {#if call.direction === "inbound"}
        <div class="text-sm flex items-center" title="hangup">
          <Icon class="text-gray-400 mr-2">ring_volume</Icon>
          <TemplateString
            content="{`{{${call.dispositions.startedAt}||formatDateShort}} {{${call.dispositions.startedAt}||formatFullTime}}`}"
          />
        </div>
      {:else}
        <div class="text-sm flex items-center" title="hangup">
          <Icon class="text-gray-400 mr-2">settings_phone</Icon>
          <TemplateString
            content="{`{{${call.dispositions.startedAt}||formatDateShort}} {{${call.dispositions.startedAt}||formatFullTime}}`}"
          />
        </div>
      {/if}
      {#if call.dispositions.duration}
        <div class="text-sm flex items-center ml-8" title="connected">
          <p class="text-xs text-gray-400 ml-1">
            Connected during {call.dispositions.duration}
          </p>
        </div>
      {/if}
      <div class="text-sm flex items-center" title="hangup">
        <Icon class="text-gray-400 mr-2">call_end</Icon>
        <div>
          <TemplateString
            content="{`{{${call.dispositions.endedAt}||formatDateShort}} {{${call.dispositions.endedAt}||formatFullTime}}`}"
          />
        </div>
      </div>
    {/if}

    {#if call?.voicemailUploaded}
      <div class="text-sm flex items-center" title="hangup">
        <Icon class="text-gray-400 mr-2">ring_volume</Icon>
        <TemplateString
          content="{`{{${call.voicemailUploaded.startedAt}||formatDateShort}} {{${call.voicemailUploaded.startedAt}||formatFullTime}}`}"
        />
      </div>
      <a
        href="{call.voicemailUploaded.voicemailLink}"
        class="text-gray-400 hover:text-slate-900"
        target="_blank"
        rel="noreferrer"
      >
        <div class="text-sm flex items-center my-2">
          <Icon class="mr-2">voicemail</Icon>

          <span class="text-slate-900 hover:underline"> voicemail sent </span>
        </div>
      </a>
    {/if}
  </div>
</div>
