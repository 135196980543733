<style>
.disabled {
  @apply text-gray-400 italic;
}
.denied {
  position: relative;
  background: linear-gradient(to right, #f87171 4px, transparent 4px);
  background-size: 100% 100%;
}
.denied {
  position: relative;
  box-shadow: inset 4px 0 0 0 #f87171;
}
</style>

<script>
import TemplateString from "@shared/components/TemplateString.svelte"
import Icon from "@local/components/images/Icon.svelte"
import { join } from "@local/store/quickVideo"
import { link } from "svelte-routing"
import API from "@local/utils/api"

export let rows = []

const intercomId = "SVELTE_APP_INTERCOM_ID"

$: heads = rows.length > 0 ? rows[0].columns.map((row) => row.label) : []

async function kick(row, button) {
  if (window.confirm("Are you sure you want to kick this user?")) {
    const { wfId } = row
    const {
      props: { steps, direction },
    } = button

    const searchParams = {
      direction,
      role: "admin",
      render: false,
    }

    await API.patch(`workflows/${wfId}`, {
      searchParams,
      json: { steps },
    }).json()
  }
}

async function greet(row, button) {
  const { wfId, userId } = row
  const {
    props: { firstName },
  } = button

  const searchParams = {
    role: "admin",
    render: false,
  }

  await API.patch(`workflows/${wfId}/views/greet`, {
    json: {},
    searchParams,
  }).json()

  join({ userId, firstName })
}
</script>

<table class="table">
  <thead>
    {#each heads as head}
      <th>{head}</th>
    {/each}
    <th>&nbsp;</th>
  </thead>
  <tbody>
    {#each rows as row}
      <tr
        class:denied="{row.classProps?.doesDoctorRecommend === false}"
        class:disabled="{row.disabled}"
      >
        {#each row.columns as { value }}
          <td>
            <TemplateString content="{value}" />
          </td>
        {/each}
        <td class="text-right">
          {#each row.buttons as button}
            {#if button.action === "kick"}
              <button
                type="button"
                class="mr-2 focus:outline-none"
                on:click="{() => kick(row, button)}"
              >
                <Icon>cancel</Icon>
              </button>
            {/if}
            {#if button.action === "greet"}
              <button
                type="button"
                class="mr-2 focus:outline-none"
                on:click="{() => greet(row, button)}"
              >
                <Icon>video_camera_front</Icon>
              </button>
            {/if}
            {#if button.action === "call" && button.props}
              <a href="{button.props?.url || ''}" target="_blank">
                <Icon
                  ><span class="mr-2 material-symbols-outlined">
                    call
                  </span></Icon
                >
              </a>
            {/if}
            {#if button.action === "log"}
              <a
                use:link
                href="{window.origin}/workflows/{row.wfId}/logs"
                class="mr-2"
              >
                <Icon>receipt_long</Icon>
              </a>
            {/if}
            {#if button.action === "internalNotes"}
              <a
                use:link
                href="{window.origin}/workflows/{row.wfId}/notes"
                class="mr-2"
              >
                <Icon>sticky_note_2</Icon>
              </a>
            {/if}
            {#if button.action === "openUser"}
              <a use:link href="{window.origin}/users/{row.userId}">
                <Icon>arrow_forward</Icon>
              </a>
            {/if}
            {#if button.action === "openWorkflow"}
              <a use:link href="{window.origin}/workflows/{row.wfId}">
                <Icon>arrow_forward</Icon>
              </a>
            {/if}
            {#if button.action === "intercom"}
              <a
                href="https://app.intercom.com/a/apps/{intercomId}/users/{button
                  .props?.intercomId}/all-conversations"
                target="_blank"
              >
                <Icon>
                  <span class="material-symbols-outlined"> forum </span>
                </Icon>
              </a>
            {/if}
          {/each}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
