<svg
  xmlns="http://www.w3.org/2000/svg"
  class="text-blue-500 fill-current {$$props.class || ''}"
  viewBox="0 0 114 131"
>
  <polygon
    points="71.25 75.47 112.82 30.84 73.22 7.99 59.39 0 71.25 75.47"
  ></polygon>
  <polygon points="27.79 99.91 0 38.97 0 96.67 27.79 99.91"></polygon>
  <polygon points="114 38.97 86.21 99.91 114 96.67 114 38.97"></polygon>
  <polygon points="76 104.89 76 114.03 80.41 104.37 76 104.89"></polygon>
  <polygon
    points="84.31 103.87 77.83 118.08 107.04 101.22 84.31 103.87"
  ></polygon>
  <polygon
    points="109.33 40.05 71.98 80.14 75.29 101.17 81.8 100.42 109.33 40.05"
  ></polygon>
  <polygon
    points="29.59 103.87 6.86 101.22 36.07 118.08 29.59 103.87"
  ></polygon>
  <polygon points="42.62 99.67 53.99 93.1 45.14 83.6 42.62 99.67"></polygon>
  <polygon points="38 104.89 33.59 104.37 38 114.03 38 104.89"></polygon>
  <polygon
    points="72 121.58 72 104.26 57 95.6 42 104.26 42 121.58 57 130.24 72 121.58"
  ></polygon>
  <polygon points="68.76 83.6 59.91 93.1 71.28 99.67 68.76 83.6"></polygon>
  <polygon
    points="41.92 80.14 4.57 40.05 32.1 100.42 38.61 101.17 41.92 80.14"
  ></polygon>
  <polygon
    points="45.88 78.93 56.95 90.81 68.02 78.93 56.95 8.43 45.88 78.93"
  ></polygon>
  <polygon points="54.51 0 40.67 7.99 1.08 30.84 42.65 75.47 54.51 0"></polygon>
</svg>
