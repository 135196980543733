<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import Breadcrumb from "@local/components/engine/elements/Breadcrumb.svelte"

const queryClient = useQueryClient()

export let wfId
let breadcrumbItems = []

async function fetchLogs({ wfId }) {
  const searchParams = { role: "admin" }
  const response = await API.get(`admin/workflow/${wfId}/updates`, {
    searchParams,
  })
  const rows = await response.json()
  const patientName = rows?.find((x) => x.role === "patient")?.name || ""

  breadcrumbItems = [
    { label: "Home", url: "/", icon: "home" },
    {
      label: "Workflows",
      url: "/workflows?type=medical",
      icon: "medical_information",
    },
    {
      label: patientName,
      url: `/workflows/${wfId}`,
      icon: "person",
    },
    { label: "Logs" },
  ]
  return { rows }
}

$: queryOptions = {
  queryKey: ["activityLogs", wfId],
  queryFn: () => fetchLogs({ wfId }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

<Query options="{queryOptions}">
  <div slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="p-5">
        <Breadcrumb items="{breadcrumbItems}" />
        <h1 class="mb-2 text-3xl font-semibold">Activity Log</h1>
        {#if queryResult.data.rows.length > 0}
          <table class="table">
            <thead>
              <th>Name</th>
              <th>Message</th>
              <!-- <th>Role</th> -->
              <th>Date</th>
            </thead>
            <tbody>
              {#each queryResult.data.rows as row}
                <tr>
                  <td class:text-red-500="{row.role === 'admin'}">
                    {row.name || ""}
                  </td>
                  <td>{row.message}</td>
                  <!-- <td
                    class:text-red-500="{row.role === 'admin'}"
                  >{row.role}</td> -->
                  <td>
                    <TemplateString
                      content="{`{{${row.date}||formatDateTime}}`}"
                    />
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    {/if}
  </div>
</Query>
