<style>
span {
  @apply text-base px-2.5 py-0.5 my-auto rounded;
}
.info {
  background-color: #d5f7c4;
  color: black;
}
.warn {
  background-color: #fd8a8a;
  color: white;
}
.purchase-platinum {
  background-color: black;
  color: white;
}
.purchase-gold {
  background-color: #f7db90;
  color: black;
}
.purchase-silver {
  background-color: #4a4a4a;
  color: white;
}
.purchase-bronze {
  background-color: #fac39a;
  color: black;
}
.purchase-limited-purchase {
  background-color: #f7f0a8;
  color: black;
}
.purchase-no-purchase {
  background-color: #fd8a8a;
  color: white;
}
.appointment-approved {
  background-color: #ade4fb;
  color: black;
}
.appointment-denied {
  background-color: #ffbb68;
  color: white;
}
.stage {
  background-color: #caf7ff;
  color: black;
}
</style>

<script>
import TemplateString from "@shared/components/TemplateString.svelte"

export let content = ""
export let type = ""

$: color = `${type.toLowerCase().replaceAll(" ", "-")}`
</script>

<span
  class="{color} text-base px-2.5 py-0.5 my-auto rounded {$$props.className ||
    ''}"
>
  <TemplateString content="{content}" />
</span>
