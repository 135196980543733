<script>
import API from "@local/utils/api"

export let label = "Move"
export let url = ""
export let enableConfirmation
export let className = ""
let submitting = false

async function click() {
  try {
    if (enableConfirmation) {
      const confirmation = confirm("Are you sure?")
      if (!confirmation) {
        return
      }
    }

    const searchParams = {
      role: "admin",
      render: false,
    }
    submitting = true

    await API.patch(url, {
      json: {},
      searchParams,
    }).json()

    alert("Success!")
  } catch (err) {
    alert("Failed: " + err)
  } finally {
    submitting = false
  }
}
</script>

<div
  on:click="{click}"
  target="_blank"
  class="{`flex items-center cursor-pointer py-2 lg:py-4 px-1 btn btn-outline uppercase font-medium uppercase text-center text-xs lg:text-sm ${className}`}"
>
  <span class="mx-1 lg:mx-2 my-auto" class:cursor-wait="{submitting}">
    {#if submitting}Working...{:else}{label}{/if}
  </span>
</div>
