<style>
.options {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1);
  left: 0;
  list-style: none;
  margin-block-end: 0;
  margin-block-start: 0;
  max-height: 70vh;
  overflow: auto;
  padding-inline-start: 0;
  position: absolute;
  top: calc(100% + 1px);
  width: 100%;
}
</style>

<script>
import { onMount } from "svelte"
import { fly } from "svelte/transition"
import Icon from "@local/components/images/Icon.svelte"
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "@local/utils/localStorage"

export let value = getDataFromLocalStorage(localStorageKey)
export let placeholder

let input
let inputValue
let options = []
let activeOption
let showOptions = false
let selected = {}
let first = true
let slot
let emptyPlaceholder = placeholder
let currentPlaceholder = ""
const UP_AND_DOWN_ARROWS = [38, 40]
const localStorageKey = "calendar"

function add(token) {
  if (token) {
    selected[token.value] = token
  }
  setDataToLocalStorage(localStorageKey, Object.keys(selected))
}

function remove(value) {
  if (value) {
    const { [value]: val, ...rest } = selected
    selected = rest
  }
  setDataToLocalStorage(localStorageKey, Object.keys(selected))
}

function optionsVisibility(show) {
  if (typeof show === "boolean") {
    showOptions = show
    show && input.focus()
  } else {
    showOptions = !showOptions
  }
  if (!showOptions) {
    activeOption = undefined
  }
}

function handleKeyup(e) {
  if (e.keyCode === 13) {
    Object.keys(selected).includes(activeOption.value)
      ? remove(activeOption.value)
      : add(activeOption)
    inputValue = ""
  }
  if (UP_AND_DOWN_ARROWS.includes(e.keyCode)) {
    const increment = e.keyCode === 38 ? -1 : 1
    const calcIndex = filtered.indexOf(activeOption) + increment
    activeOption =
      calcIndex < 0
        ? filtered[filtered.length - 1]
        : calcIndex === filtered.length
        ? filtered[0]
        : filtered[calcIndex]
  }
}

function handleBlur() {
  optionsVisibility(false)
}

function handleTokenClick(e) {
  if (e.target.closest(".remove-all")) {
    selected = {}
    inputValue = ""
    setDataToLocalStorage(localStorageKey, [])
  } else {
    optionsVisibility(true)
  }
}

function handleOptionMousedown(e) {
  const value = e.target.dataset?.value

  if (selected[value]) {
    remove(value)
  } else {
    add(options.filter((o) => o.value === value)[0])
    input.focus()
  }
}

function formatPlaceholder() {
  let updatedPlaceholder = Object.keys(selected)
    .toString()
    .replaceAll(",", ", ")

  if (updatedPlaceholder.length > 20) {
    updatedPlaceholder = updatedPlaceholder.slice(0, 20) + "..."
  }

  return updatedPlaceholder
}

onMount(() => {
  value = getDataFromLocalStorage()

  slot.querySelectorAll("option").forEach((o) => {
    options = [...options, { value: o.value, name: o.textContent }]
  })

  value?.length &&
    (selected = options.reduce(
      (obj, op) =>
        value.includes(op.value) ? { ...obj, [op.value]: op } : obj,
      {}
    ))

  if (value?.length && Object.keys(selected).length < value.length) {
    value.map((value) => {
      Object.assign(selected, { [value]: { value, name: value } })
    })
  }

  first = false
})

$: if (!first) value = Object.keys(selected)

$: filtered = options.filter((option) =>
  inputValue
    ? option.name.toLowerCase().includes(inputValue.toLowerCase())
    : option
)

$: if (
  (activeOption && !filtered.includes(activeOption)) ||
  (!activeOption && inputValue)
) {
  activeOption = filtered[0]
}

$: currentPlaceholder = Object.keys(selected).length
  ? formatPlaceholder()
  : emptyPlaceholder
</script>

<div class="relative h-10 w-56 border border-gray-300 rounded-sm bg-white">
  <div on:click|preventDefault="{handleTokenClick}">
    <div class="flex flex-1 items-center justify-between pl-4 py-1">
      <input
        class="flex-1 w-24 text-sm text-black outline-none leading-6"
        autocomplete="off"
        bind:value="{inputValue}"
        bind:this="{input}"
        on:keyup="{handleKeyup}"
        on:blur="{handleBlur}"
        placeholder="{currentPlaceholder}"
      />
      <div
        title="Remove all"
        class="remove-all"
        class:hidden="{!Object.keys(selected).length}"
      >
        <Icon class="self-end text-gray-400 cursor-pointer hover:text-gray-500">
          cancel
        </Icon>
      </div>
      <Icon class="text-gray-400 cursor-pointer">expand_more</Icon>
    </div>
  </div>

  <select bind:this="{slot}" type="multiple" class="hidden"><slot /></select>

  {#if showOptions}
    <ul
      class="options z-30"
      transition:fly="{{ duration: 200, y: 5 }}"
      on:mousedown|preventDefault="{handleOptionMousedown}"
    >
      {#each filtered as option}
        <li
          class="flex items-center bg-white cursor-pointer p-1 hover:bg-gray-100"
          class:font-bold="{selected[option.value]}"
          class:bg-gray-200="{activeOption === option}"
          data-value="{option.value}"
        >
          <Icon>
            <span data-value="{option.value}">
              {#if selected[option.value]?.name}
                check_box
              {:else}check_box_outline_blank{/if}
            </span>
          </Icon>
          <span class="ml-2" data-value="{option.value}">{option.name}</span>
        </li>
      {/each}
    </ul>
  {/if}
</div>
