<script>
import { createEventDispatcher } from "svelte"

const dispatch = createEventDispatcher()

export let tag = null
let tagStyle = null

$: tag,
  (tagStyle = `background-color: ${tag.backgroundColor}; color: ${tag.textColor};`)
</script>

<div class="background-modal">
  <div class="modal max-w-2xl">
    {#if tag}
      <div
        class="w-48 font-semibold text-base text-center p-1 mb-4 mx-auto rounded-sm text"
        style="{tagStyle}"
      >
        {tag.label}
      </div>

      <div class="max-h-96 overflow-y-auto text">
        {@html tag.explainer}
      </div>

      <div class="flex justify-end mt-4">
        <button
          type="button"
          class="btn btn-outline"
          on:click="{() => dispatch('close')}">Close</button
        >
      </div>
    {/if}
  </div>
</div>
