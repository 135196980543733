// import Video from "twilio-video"

/**
 * Get the list of available media devices of the given kind.
 * @param {Array<MediaDeviceInfo>} deviceInfos
 * @param {string} kind - One of 'audioinput', 'audiooutput', 'videoinput'
 * @returns {Array<MediaDeviceInfo>} - Only those media devices of the given kind
 */
export function getDevicesOfKind(deviceInfos, kind) {
  return deviceInfos.filter(function (deviceInfo) {
    return deviceInfo.kind === kind
  })
}

/**
 * Apply the selected audio output device.
 * @param {string} deviceId
 * @param {HTMLAudioElement} audio
 * @returns {Promise<void>}
 */
export function applyAudioOutputDeviceSelection(deviceId, audio) {
  return typeof audio.setSinkId === "function"
    ? audio.setSinkId(deviceId)
    : Promise.reject(
        "This browser does not support setting an audio output device"
      )
}

/**
 * Apply the selected input device.
 * @param {string} deviceId
 * @param {?LocalTrack} localTrack - LocalAudioTrack or LocalVideoTrack; if null, a new LocalTrack will be created.
 * @param {'audio' | 'video'} kind
 * @returns {Promise<LocalTrack>} - The created or restarted LocalTrack
 */
export function applyInputDeviceSelection(deviceId, localTrack, kind) {
  let constraints = { deviceId: { exact: deviceId } }
  if (localTrack) {
    return localTrack.restart(constraints).then(function () {
      return localTrack
    })
  }
  return kind === "audio"
    ? Video.createLocalAudioTrack(constraints)
    : Video.createLocalVideoTrack(constraints)
}

/**
 * Ensure that media permissions are obtained.
 * @returns {Promise<void>}
 */
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      return devices.every(function (device) {
        return !(device.deviceId && device.label)
      })
    })
    .then(function (shouldAskForMediaPermissions) {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(function (mediaStream) {
            mediaStream.getTracks().forEach(function (track) {
              track.stop()
            })
          })
      }
    })
}

/**
 * Get the list of available media devices.
 * @returns {Promise<DeviceSelectionOptions>}
 * @typedef {object} DeviceSelectionOptions
 * @property {Array<MediaDeviceInfo>} audioinput
 * @property {Array<MediaDeviceInfo>} audiooutput
 * @property {Array<MediaDeviceInfo>} videoinput
 */
export function getDeviceSelectionOptions() {
  // before calling enumerateDevices, get media permissions (.getUserMedia)
  // w/o media permissions, browsers do not return device Ids and/or labels.
  return ensureMediaPermissions().then(function () {
    return navigator.mediaDevices
      .enumerateDevices()
      .then(function (deviceInfos) {
        let kinds = ["audioinput", "audiooutput", "videoinput"]
        return kinds.reduce(function (deviceSelectionOptions, kind) {
          deviceSelectionOptions[kind] = getDevicesOfKind(deviceInfos, kind)
          return deviceSelectionOptions
        }, {})
      })
  })
}

const AudioContext = window.AudioContext || window.webkitAudioContext
const audioContext = AudioContext ? new AudioContext() : null

function rootMeanSquare(samples) {
  const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0)
  return Math.sqrt(sumSq / samples.length)
}

export async function pollAudioLevel(track, onLevelChanged) {
  if (!audioContext) {
    return
  }

  // Due to browsers' autoplay policy, the AudioContext is only active after
  // the user has interacted with your app, after which the Promise returned
  // here is resolved.
  await audioContext.resume()

  // Create an analyzer to access the raw audio samples from the microphone.
  const analyzer = audioContext.createAnalyser()
  analyzer.fftSize = 1024
  analyzer.smoothingTimeConstant = 0.5

  // Connect the LocalAudioTrack's media source to the analyzer.
  const stream = new MediaStream([track.mediaStreamTrack])
  const source = audioContext.createMediaStreamSource(stream)
  source.connect(analyzer)

  const samples = new Uint8Array(analyzer.frequencyBinCount)
  let level = null

  // Periodically calculate the audio level from the captured samples,
  // and if changed, call the callback with the new audio level.
  requestAnimationFrame(function checkLevel() {
    analyzer.getByteFrequencyData(samples)
    const rms = rootMeanSquare(samples)
    const log2Rms = rms && Math.log2(rms)

    // Audio level ranges from 0 (silence) to 10 (loudest).
    const newLevel = Math.ceil((10 * log2Rms) / 8)
    if (level !== newLevel) {
      level = newLevel
      onLevelChanged(level)
    }

    // Continue calculating the level only if the audio track is live.
    if (track.mediaStreamTrack.readyState === "live") {
      requestAnimationFrame(checkLevel)
    } else {
      requestAnimationFrame(() => onLevelChanged(0))
    }
  })
}

export function displayLocalVideo(video) {
  return Video.createLocalVideoTrack().then(function (localTrack) {
    localTrack.attach(video)
  })
}
