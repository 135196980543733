<script>
import Badge from "@local/components/engine/elements/Badge.svelte"
import Render from "@local/components/engine/Render.svelte"

export let wfs = []
</script>

<div
  class="{$$props.className ||
    ''} border-l-2 border-dashed border-gray-400 ml-12 pb-2"
  class:relative="{wfs.at(-1)?.elements.length}"
  class:hidden="{!wfs.at(-1)?.elements.length}"
>
  {#each wfs as wf}
    {#if wf?.elements.length}
      <div class="-ml-12 pt-4 pb-4 text">
        <Badge
          content="{`{{${wf.date}||formatDateShort}} ${
            wf?.returningType === 'renewal'
              ? '(Renewal)'
              : wf?.returningType === 'followUp'
              ? '(Follow Up)'
              : ''
          }`}"
        />
      </div>

      <div class="ml-4">
        {#each wf.elements as element}
          <Render element="{element}" />
        {/each}
      </div>
    {/if}
  {/each}

  <div class="absolute h-4 w-4 bg-slate-900 rounded-full -ml-2"></div>
</div>
