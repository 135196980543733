<style>
.card-system {
  @apply bg-red-200 text-black;
}

.card-patient {
  @apply bg-blue-200 text-black;
}

.card-admin {
  @apply bg-red-200 text-black;
}

.card-employee {
  @apply bg-green-200 text-black;
}

.card-default {
  @apply bg-indigo-200 text-black;
}
</style>

<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import Breadcrumb from "@local/components/engine/elements/Breadcrumb.svelte"

const queryClient = useQueryClient()

export let wfId
let breadcrumbItems = []

async function fetchLogs({ wfId }) {
  const searchParams = { role: "admin" }
  const response = await API.get(`admin/workflow/${wfId}/updates`, {
    searchParams,
  })
  const body = await response.json()
  const rows = body?.updates
  const username = body.username

  breadcrumbItems = [
    { label: "Home", url: "/", icon: "home" },
    {
      label: "Workflows",
      url: "/workflows?type=medical",
      icon: "medical_information",
    },
    {
      label: username,
      url: `/workflows/${wfId}`,
      icon: "person",
    },
    { label: "Logs" },
  ]
  return { rows }
}

$: queryOptions = {
  queryKey: ["activityLogs", wfId],
  queryFn: () => fetchLogs({ wfId }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

<Query options="{queryOptions}">
  <div slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="p-5">
        <Breadcrumb items="{breadcrumbItems}" />
        <h2 class="h2 mb-4">Activity Log</h2>
        {#if queryResult.data.rows.length > 0}
          {#each queryResult.data.rows as row}
            <div class="card p-5 mb-4 max-w-3xl">
              <div
                class="flex w-full items-center justify-between border-b pb-3 mb-4"
              >
                <span
                  class:card-system="{row.role === 'worker'}"
                  class:card-admin="{row.role === 'admin'}"
                  class:card-patient="{row.role === 'patient'}"
                  class:card-employee="{['doctor', 'educator'].includes(
                    row.role
                  )}"
                  class:card-default="{![
                    'doctor',
                    'educator',
                    'admin',
                    'worker',
                    'patient',
                  ].includes(row.role)}"
                  class="text-base px-2.5 py-0.5 my-auto rounded"
                >
                  {row.name || ""}
                </span>
                <div class="flex items-center space-x-3">
                  <div
                    class="italic text-right text-xs md:text-sm lg:text-base"
                  >
                    <TemplateString
                      content="{`{{${row.date}||formatDateTime}}`}"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-2">
                {row.message}
              </div>
            </div>
          {/each}
        {/if}
      </div>
    {/if}
  </div>
</Query>
