<script>
import { tick, onMount } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"
import API, { handleErrors } from "@local/utils/api"
import { twoFactorAuthSucceed, deauth } from "@local/store/auth"
import { workflowLang } from "@shared/store/workflowKey"
import {
  logoutText,
  twoFactorAuthenticationText,
  twoFactorAuthenticationInfoMessageText,
  resendTwoFactorAuthenticationCodeText,
  resendedTwoFactorAuthenticationCodeText,
  resendedErrorTwoFactorAuthenticationCodeText,
} from "@shared/utils/translations"

import Logo from "@local/components/images/Logo.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import { validate } from "validate.js"

export let location = {}

let queryParams
$: queryParams = queryString.parse(location.search)
$: next = queryParams.next
$: code = queryParams.code

let form = {
  code: "",
}
let errors = {}
let resendMessageVisible = false
let resendWithError = false

const customErrMsgs = {
  code: {
    shouldBeValid: "Code expired or incorrect.",
  },
}

const constraints = {
  code: { presence: { allowEmpty: false }, length: { is: 6 } },
}

async function resendCode() {
  try {
    const request = await API.post("two-factor-auth", {
      searchParams: {
        role: "admin",
      },
    }).json()

    const resendedCode = !!request.twoFactorAuth

    if (resendedCode) {
      resendMessageVisible = true
      resendWithError = false
      setTimeout(() => {
        resendMessageVisible = false
      }, 5000)
    }
  } catch (error) {
    resendWithError = true
  }
}

async function verifyCode() {
  const code = form.code.toUpperCase()
  const response = await API.get(`two-factor-auth/${code}`).json()

  if (response.twoFactorAuth) {
    twoFactorAuthSucceed()
  }
  await tick()
  return !!response.twoFactorAuth
}

async function submit() {
  try {
    // errors = validate(form, constraints)
    // if (!errors) {
    if (await verifyCode()) {
      navigate(next || "/", { replace: true })
    }
    // }
  } catch (err) {
    errors = await handleErrors({ err, customErrMsgs })
  }
}

onMount(async () => {
  if (code) {
    form.code = code
    try {
      if (await verifyCode()) {
        navigate(next || "/", { replace: true })
      }
    } catch (err) {
      errors = await handleErrors({ err, customErrMsgs })
    }
  }
})
</script>

<div class="flex items-center justify-center h-screen bg-blue-50">
  <div
    class="w-full max-w-sm bg-white border-t-4 border-blue-500 rounded-lg shadow-md"
  >
    <form on:submit|preventDefault="{submit}">
      <div class="px-6 py-8">
        <div class="flex justify-center mb-8">
          <Logo class="h-24" />
        </div>

        <h1 class="text-center font-bold mb-4">
          {twoFactorAuthenticationText[$workflowLang]}
        </h1>

        <p class="text-sm mb-6 whitespace-pre-wrap">
          {twoFactorAuthenticationInfoMessageText[$workflowLang]}
        </p>

        {#if resendWithError}
          <div class="text-center text-red-500">
            <span class="text-sm font-bold">
              {resendedErrorTwoFactorAuthenticationCodeText[$workflowLang]}
            </span>
          </div>
        {/if}

        <TextInput
          bind:value="{form.code}"
          label="Code"
          name="code"
          class="my-4"
          errors="{errors && errors.code}"
        />

        {#if resendMessageVisible}
          <span class="text-sm">
            {resendedTwoFactorAuthenticationCodeText[$workflowLang]}
          </span>
        {:else}
          <span
            class="text-sm font-bold text-blue-500 hover:underline cursor-pointer"
            on:click="{resendCode}"
          >{resendTwoFactorAuthenticationCodeText[$workflowLang]}</span>
        {/if}
      </div>

      <div class="flex px-6 py-3 rounded-b-lg bg-gray-50">
        <div class="flex flex-1 flex-row justify-between items-center">
          <span
            class="text-sm font-bold text-blue-500 hover:underline cursor-pointer"
            on:click="{deauth}"
          >
            {logoutText[$workflowLang]}
          </span>

          <button type="submit" class="btn btn-solid-blue">Continue</button>
        </div>
      </div>
    </form>
  </div>
</div>
