const WORKFLOWS_FILTERS_KEY = "hellomd+workflowFilters"
const keyMap = new Map([
  ["whoToCall", "hellomd+whoToCallFilters"],
  ["calendar", "hellomd+calendarTags"]
])

export function setWfFiltersToLocalStorage({ wfType, ...filters }) {
  if (!wfType) return
  return localStorage.setItem(`${WORKFLOWS_FILTERS_KEY}+${wfType}`, JSON.stringify(filters))
}

export function getWfFiltersFromLocalStorage(wfType) {
  if (!wfType) return
  return JSON.parse(localStorage.getItem(`${WORKFLOWS_FILTERS_KEY}+${wfType}`)) || []
}

export function setDataToLocalStorage(key, value) {
  return localStorage.setItem(keyMap.get(key), JSON.stringify(value))
}

export function getDataFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(keyMap.get(key))) || []
}

export function clearLocalStorage(key) {
  const value = getDataFromLocalStorage(key)

  localStorage.clear()

  if (value.length)
    setDataToLocalStorage(key, value)
}
