<script>
import { userId, firstName } from "@local/store/quickVideo"
import Room from "./Room.svelte"
</script>

{#if $userId && $firstName}
  <div
    class="fixed bottom-0 right-0 z-10 w-64 mb-8 mr-8 overflow-hidden bg-white rounded-lg shadow-md"
  >
    <Room />
  </div>
{/if}
