<script>
import API from "@local/utils/api"
import Icon from "@local/components/images/Icon.svelte"
import LinkAddCallOutcome from "@local/components/LinkAddCallOutcome.svelte"

export let label = ""
export let url = ""
export let className = ""
export let leftIcon = ""
export let action = ""
export let targetBlank = false
export let wfId = ""
export let userId = undefined
export let partnerOrigin = undefined

async function auth() {
  const { path } = await API.put(`admin/users/${userId}/auth-token`).json()
  const url = `SVELTE_APP_PROTOCOL://${partnerOrigin}.SVELTE_APP_DOMAIN/${path}`
  window.open(url)
}
</script>

{#if url}
  <a
    href="{url}"
    title="{label}"
    class="cursor-pointer"
    target="{targetBlank ? '_blank' : '_self'}"
  >
    <div
      class="{`flex items-center h-8 bg-white hover:bg-slate-900 text-slate-900 hover:text-white border border-slate-900 rounded-md ${className}`}"
    >
      {#if leftIcon}
        <Icon size="lg" class="mx-2">{leftIcon}</Icon>
      {/if}
      <span class="text-sm font-medium mr-2 hidden lg:block">{label}</span>
    </div>
  </a>
{:else if action === "addCallOutcome"}
  <LinkAddCallOutcome
    label="{label}"
    className="{className}"
    leftIcon="{leftIcon}"
    wfId="{wfId}"
  />
{:else if action === "auth"}
  <a on:click="{() => auth()}" href="#" title="{label}" class="cursor-pointer">
    <div
      class="{`flex items-center h-8 bg-white hover:bg-slate-900 text-slate-900 hover:text-white border border-slate-900 rounded-md ${className}`}"
    >
      {#if leftIcon}
        <Icon size="lg" class="mx-2">{leftIcon}</Icon>
      {/if}
      <span class="text-sm font-medium mr-2 hidden lg:block">{label}</span>
    </div>
  </a>
{/if}
