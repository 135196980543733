<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-full max-w-xl rounded-sm shadow-sm;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import {
  endOfWeek,
  startOfWeek,
  eachDayOfInterval,
  isSameDay,
  addWeeks,
  parseISO,
  format as _format,
} from "date-fns"
import { format, toDate } from "date-fns-tz"
import { useQuery } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import Icon from "@local/components/images/Icon.svelte"

const dispatch = createEventDispatcher()

export let jumper = false

let page = 0
export let date
$: startsAt = startOfWeek(addWeeks(parseISO(date), page))
$: endsAt = endOfWeek(addWeeks(parseISO(date), page))
export let timezone
export let scheduleTags

function getCapacityStyle(tags) {
  const tagDef = scheduleTags.find((tagDef) => tagDef.label === tags[0])
  return `background-color: ${tagDef.backgroundColor}; color: ${tagDef.textColor};`
}

function getLabel(tags) {
  const tagDef = scheduleTags.find((tagDef) => tagDef.label === tags[0])
  return tagDef.abbrLabel
}

function dateClick(date) {
  dispatch("changeDate", _format(date, "yyyy-MM-dd"))
}

async function fetchSummary() {
  const apiResults = await API.get(`admin/schedule-summary`, {
    searchParams: {
      role: "admin",
      startsAt: startsAt.toISOString(),
      endsAt: endsAt.toISOString(),
      timezone,
    },
  }).json()

  return eachDayOfInterval({
    start: startsAt,
    end: endsAt,
  }).map((dayOfInterval) => {
    const apiResult = apiResults.find(({ date }) => {
      return isSameDay(
        toDate(date, { timeZone: timezone }),
        toDate(_format(dayOfInterval, "yyyy-MM-dd"), { timeZone: timezone })
      )
    })
    return {
      date: dayOfInterval,
      tagsCapacity: apiResult
        ? apiResult.tagsCapacity.sort((a, b) => {
            const aTag = a.tags[0]
            const bTag = b.tags[0]
            return aTag > bTag ? 1 : aTag < bTag ? -1 : 0
          })
        : [],
    }
  })
}

$: queryOptions = {
  queryKey: ["scheduleSummary", startsAt, endsAt, timezone, page],
  queryFn: fetchSummary,
}

const summaryQuery = useQuery(queryOptions)
$: summaryQuery.setOptions(queryOptions)
$: summary = $summaryQuery.data || []
</script>

<div
  class="background"
  id="modal-overlay"
  on:click="{(evt) => evt.target.id === 'modal-overlay' && dispatch('close')}"
>
  <div class="modal">
    <div class="">
      <div
        class="flex items-center justify-center gap-2 px-4 py-4 border-b border-gray-400 cursor-pointer hover:bg-gray-200"
        on:click="{() => (page = page - 1)}"
      >
        <Icon size="3xl">arrow_upward</Icon>
      </div>
      {#each summary as { date, tagsCapacity }}
        <div
          class="flex items-center gap-2 px-4 py-4 border-b border-gray-400 cursor-pointer hover:bg-gray-200"
          on:click="{dateClick(date)}"
        >
          <div class="mr-auto font-semibold">
            {format(date, 'E, MMM do', { timeZone: timezone })}
          </div>
          {#each tagsCapacity as { tags, abbrLabel, usedCount, totalCount }}
            {#if jumper === true || jumper === tags[0]}
              <div
                class="rounded-sm px-2 py-1 font-semibold"
                style="{getCapacityStyle(tags)}"
              >
                {getLabel(tags)}
                {usedCount}
                /
                {totalCount}
              </div>
            {/if}
          {/each}
        </div>
      {/each}
      <div
        class="flex items-center justify-center gap-2 px-4 py-4 cursor-pointer hover:bg-gray-200"
        on:click="{() => (page = page + 1)}"
      >
        <Icon size="3xl">arrow_downward</Icon>
      </div>
    </div>
  </div>
</div>
