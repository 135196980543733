<script>
import { onMount, onDestroy } from "svelte"
import startOfHour from "date-fns/startOfHour"
import addSeconds from "date-fns/addSeconds"
import format from "date-fns/format"

let clock = startOfHour(new Date())
let formattedClock = "00:00"
let clockInterval

onMount(function () {
  clockInterval = setInterval(() => {
    clock = addSeconds(clock, 1)
    formattedClock = format(clock, "mm:ss")
  }, 1000)
})

onDestroy(function () {
  clearInterval(clockInterval)
  clockInterval = null
})
</script>

{formattedClock}
