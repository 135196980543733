<style>
button {
  @apply rounded m-0 outline-none p-4 px-5;
}

button :global(.icon) {
  @apply flex justify-center items-center h-7 text-2xl;
}

.selected {
  @apply outline-none border-r border-accent-red;
}
</style>

<script>
import { getContext } from "svelte"
import { TABS } from "./VerticalTabs.svelte"
import Icon from "@local/components/images/Icon.svelte"

export let label = ""
export let icon = ""

const tab = {}
const { registerTab, selectTab, selectedTab } = getContext(TABS)

registerTab(tab)
</script>

<button
  class="text-left text-black dark:text-white"
  class:selected="{$selectedTab === tab}"
  class:bg-white="{$selectedTab === tab}"
  class:dark:bg-tertiary-dark="{$selectedTab === tab}"
  on:click|preventDefault|stopPropagation="{() => selectTab(tab)}"
>
  <Icon size="2xl" class="mr-4 align-middle">{icon}</Icon>
  <span class="md:inline hidden">{label}</span>
</button>
