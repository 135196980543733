<script>
import { createEventDispatcher } from "svelte"
import SlotAvailable from "./SlotAvailable.svelte"
import SlotPast from "./SlotPast.svelte"
import SlotTaken from "./SlotTaken.svelte"
import { hours, slotTypes } from "./constants"

const dispatch = createEventDispatcher()

export let tags
export let columns
export let tagHours
export let visible
export let relevantHours
export let stickyTag = false
export let checkedIds
export let timezone
</script>

<div
  class="bg-white pb-2 border border-l-0 border-t-0 flex-shrink-0 flex-grow-0 border-gray-200"
  style="width: calc(16rem * {columns})"
  class:hidden="{!visible}"
>
  <div
    class="flex bg-white items-center gap-4 justify-center h-12 font-semibold border-b z-20"
    style="position: sticky; top: 0;"
  >
    {#each tags as tag}
      <div
        class="rounded-sm px-4 py-1 font-semibold"
        class:sticky="{stickyTag}"
        style="background-color: {tag.backgroundColor}; color: {tag.textColor}"
      >
        {tag.label}
      </div>
    {/each}
  </div>

  <div class="h-2 border-b border-dashed"></div>

  {#each tagHours as hourSchedules, i}
    <div
      class="relative flex flex-col w-full pl-1 pr-4 border-b border-dashed"
      class:h-36="{relevantHours[hours[i]]}"
      class:h-8="{!relevantHours[hours[i]]}"
    >
      {#each hourSchedules as schedules, i}
        {#if schedules.status === 'Taken'}
          <SlotTaken
            {...schedules}
            timeZone="{timezone}"
            columns="{columns}"
            key="{i}"
            checked="{checkedIds.ids.includes(schedules._id)}"
            checkboxVisible="{!checkedIds.type || checkedIds.type === slotTypes.taken}"
            on:cleared="{() => dispatch('invalidate')}"
            on:toggleCheck="{({ detail }) => dispatch('toggleCheck', detail)}"
          />
        {:else if schedules.status === 'Completed' || schedules.status === 'No Show'}
          <SlotPast
            {...schedules}
            timeZone="{timezone}"
            columns="{columns}"
            key="{i}"
          />
        {:else}
          <SlotAvailable
            {...schedules}
            timeZone="{timezone}"
            columns="{columns}"
            key="{i}"
            checked="{checkedIds.ids.includes(schedules._id)}"
            checkboxVisible="{!checkedIds.type || checkedIds.type === slotTypes.available}"
            on:toggleCheck="{({ detail }) => dispatch('toggleCheck', detail)}"
            on:opened="{() => dispatch('reserveSlotModal', schedules._id)}"
            on:updated="{() => dispatch('invalidate')}"
            on:deleted="{() => dispatch('invalidate')}"
          />
        {/if}
      {/each}
    </div>
  {/each}
</div>
