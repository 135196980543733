import { writable } from "svelte/store"

let _payload = {}
let _errors = {}
let _darkMode = false

export let payload = writable(_payload)
export let darkMode = writable(_darkMode)
export let errors = writable(_errors)

export function setPayload(value) {
  _payload = value
  payload.set(value)
}

export function setDarkMode(value) {
  _darkMode = value
  darkMode.set(value)
}

export function setErrors(value) {
  _errors = value
  errors.set(value)
}

export function clearPayload() {
  setPayload({})
}
export function clearErrors() {
  setErrors({})
}