<script>
import { onMount, onDestroy } from "svelte"

export let participant
let videoEl
let videoTrack = null
let audioEl
let audioTrack = null

const trackSubscribed = (track) => {
  if (track.kind === "video") {
    videoTrack = track
    track.attach(videoEl)
  } else {
    audioTrack = track
    track.attach(audioEl)
  }
}
const trackUnsubscribed = (track) => {
  if (track.kind === "video" && track === videoTrack) {
    videoTrack.detach()
    videoTrack = null
  } else {
    audioTrack.detach()
    audioTrack = null
  }
}

onMount(function () {
  videoTrack = Array.from(participant.videoTracks.values())[0]
  if (typeof videoTrack !== "undefined" && videoTrack.track) {
    videoTrack.track.attach(videoEl)
  }

  audioTrack = Array.from(participant.audioTracks.values())[0]
  if (typeof audioTrack !== "undefined" && audioTrack.track) {
    audioTrack.track.attach(audioEl)
  }

  participant.on("trackSubscribed", trackSubscribed)
  participant.on("trackUnsubscribed", trackUnsubscribed)
})

onDestroy(function () {
  participant.removeAllListeners()
  if (videoTrack) {
    if (videoTrack.track && videoTrack.track.detach) {
      videoTrack.track.detach()
    }
    if (videoTrack.detach) {
      videoTrack.detach()
    }
    videoTrack = null
  }
  if (audioTrack) {
    if (audioTrack.track && audioTrack.track.detach) {
      audioTrack.track.detach()
    }
    if (audioTrack.detach) {
      audioTrack.detach()
    }
    audioTrack = null
  }
})
</script>

<div>
  <video bind:this="{videoEl}"></video>
  <audio bind:this="{audioEl}"></audio>
</div>
