<script>
import Icon from "@shared/components/Icon.svelte"
import CallEvents from "@local/components/CallEvents.svelte"
import CommunicationHeader from "./CommunicationHeader.svelte"

export let call
export let reduced = true
</script>

<div
  class="bg-gray-100 rounded mt-4 p-2 border-white"
  class:border-2="{!reduced}"
>
  {#if !reduced}
    <div class="flex items-center">
      {#if call.direction === "outbound"}
        <Icon class="text-slate-900 mx-2 text-xs">call_made</Icon>
      {:else}
        <Icon class="text-slate-900 mx-2 text-xs">call_received</Icon>
      {/if}
      <p class="text-slate-900 text-sm font-semibold">
        {call.direction} call
      </p>
    </div>
  {/if}

  {#if call}
    <CallEvents call="{call}" reduced="{reduced}" />
  {:else}
    <CommunicationHeader
      direction="{call?.direction}"
      reduced="{reduced}"
      target="{call?.target}"
      contact="{call?.contact}"
    />
    <CallEvents call="{call}" reduced="{reduced}" />
  {/if}

  {#if call?.dispositions}
    <div class="mt-2">
      <div class="text-sm">
        <span class="font-semibold">Outcome:</span>
        <span class="ml-1">{call.dispositions.callDispositions} </span>
      </div>

      {#if call?.dispositions?.dispositionNotes?.length}
        <div class="text-xs ml-2 mx-2 text-gray-400">
          {#each call?.dispositions?.dispositionNotes as disposition}
            <p class="mt-1">
              <span class="font-semibold">{disposition?.author || "CS"}:</span>
              <span class="ml-1">{disposition?.text || ""}</span>
            </p>
          {/each}
        </div>
      {/if}
    </div>
  {/if}
</div>
