<script>
import Select from "@local/components/form/Select.svelte"

const allOptions = [
  "Already Renewed",
  "Already Scheduled",
  "Booked",
  "Call Complete (CC)",
  "Consented",
  "Deceased",
  "Emailed (E)",
  "Leave Voicemail (LVM)",
  "Leave Voicemail + Emailed (LVM + E)",
  "Not Interested",
  "Went Elsewhere",
  "Will Renew Later",
]

export let value
</script>

<div class="{$$props.class || ''}">
  <Select bind:value options="{allOptions}" />
</div>
