<style>
.completed {
  @apply text-gray-800  bg-gray-200;
}
.noShow {
  @apply text-red-800 bg-red-200;
}
</style>

<script>
import { link } from "svelte-routing"
import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

import Icon from "@local/components/images/Icon.svelte"

import { calculateScheduleStyles } from "./utils"
import TemplateString from "../../../../../shared/components/TemplateString.svelte"

export let columns = undefined
export let column = undefined
export let workflowId = undefined
export let workflow = undefined
export let status = undefined
export let startsAt = undefined
export let endsAt = undefined
export let timeZone = undefined

$: completed = status === "Completed"
$: noShow = status === "No Show"

let icon = ""
if (workflow?.consultType === "Phone") {
  icon = "phone"
} else if (workflow?.consultType === "Video") {
  icon = "videocam"
} else if (workflow?.consultType === "In Person") {
  icon = "apartment"
}

export function parseOnTz(date, timeZone) {
  return utcToZonedTime(date, timeZone)
}
</script>

<div
  class="absolute text-xs font-semibold leading-none truncate border-b border-transparent z-10"
  style="{calculateScheduleStyles({ startsAt, endsAt, column }, columns)}"
>
  <div
    class="w-full h-full p-1 truncate group"
    class:completed="{completed}"
    class:noShow="{noShow}"
  >
    <div class="flex w-full justify-between items-center self-center">
      <a
        class="flex flex-col gap-1 font-semibold cursor-pointer hover:underline"
        href="{`/workflows/${workflowId}`}"
        target="_blank"
        use:link
      >
        <div>
          <div>
            {format(parseOnTz(startsAt, timeZone), "hh:mmaaa")}
            {workflow?.subdomain}
          </div>
          <div>
            {workflow?.firstName}
            {workflow?.lastName[0]},

            <TemplateString content="{workflow?.age}" />
          </div>
        </div>
      </a>
      <div class="flex gap-1">
        {#if !!workflow.veteran}
          <Icon size="lg">military_tech</Icon>
        {/if}
        <Icon size="md">
          {icon}
        </Icon>
      </div>
    </div>
  </div>
</div>
