<style>
.sm {
  @apply text-sm;
}
.xs {
  @apply text-xs;
}
.lg {
  @apply text-lg;
}
.xl {
  @apply text-xl;
}
.xl2 {
  @apply text-2xl;
}
.xl3 {
  @apply text-3xl;
}
.xl4 {
  @apply text-4xl;
}
.xl5 {
  @apply text-5xl;
}
.xl6 {
  @apply text-6xl;
}
</style>

<script>
export let size = "2xl"
$: sm = size === "sm"
$: lg = size === "lg"
$: xs = size === "xs"
$: xl = size === "xl"
$: xl2 = size === "2xl"
$: xl3 = size === "3xl"
$: xl4 = size === "4xl"
$: xl5 = size === "5xl"
$: xl6 = size === "6xl"
</script>

<i
  class="material-symbols-outlined {$$props.class || ''}"
  class:sm="{sm}"
  class:xs="{xs}"
  class:lg="{lg}"
  class:xl="{xl}"
  class:xl2="{xl2}"
  class:xl3="{xl3}"
  class:xl4="{xl4}"
  class:xl5="{xl5}"
  class:xl6="{xl6}"
  aria-hidden="true"><slot /></i
>
