<script>
import { Query } from "@sveltestack/svelte-query"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import List from "@local/components/List.svelte"
import API from "@local/utils/api"

export let userId
export let className

$: queryOptions = {
  queryKey: ["userWorkflows", userId],
  queryFn: () => fetchUserWorkflows(),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}

async function fetchUserWorkflows() {
  const searchParams = {
    role: "admin",
    query: userId,
  }
  const response = await API.get(`lists/def-key/admUserWorkflows`, {
    searchParams,
  })

  const rows = await response.json()
  const total = response.headers.get("x-total-count")
  const hasNextPage = false
  return { rows, hasNextPage }
}
</script>

<Query options="{queryOptions}">
  <div class="{className}" slot="query" let:queryResult>
    {#if queryResult.data?.rows.length > 0}
      <List rows="{queryResult.data.rows}" />
    {:else}
      <EmptyMessage icon="check">No records</EmptyMessage>
    {/if}
  </div>
</Query>
