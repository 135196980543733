<script>
import Select from "@local/components/form/Select.svelte"
import { timezones } from "@local/utils/timezones"

export let value
</script>

<div class="{$$props.class || ''}">
  <Select bind:value options="{timezones}" />
</div>
