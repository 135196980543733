<script>
import Menu from "./Menu.svelte"
import QuickVideo from "./QuickVideo/index.svelte"
</script>

<QuickVideo />
<Menu />
<div class="flex items-stretch w-full min-h-screen bg-blue-50">
  <div class="flex flex-1 w-full pl-36">
    <div class="flex-1 w-full">
      <slot />
    </div>
  </div>
</div>
