<script>
import Render from "@local/components/engine/Render.svelte"
import Icon from "@local/components/images/Icon.svelte"

export let elements = []
export let label = ""
export let showOpened = false

let collapsed = showOpened === true
</script>

<div class="{$$props.className || ''} transition-transform">
  <div
    class="flex items-center cursor-pointer mb-2 text hover:text-accent-red"
    on:click="{() => (collapsed = !collapsed)}"
  >
    <span class="font-semibold w-full text-lg lg:text-xl">
      {label}
    </span>

    <Icon class="mx-2">
      <span class="text-3xl">
        {#if collapsed}expand_less{:else}expand_more{/if}
      </span>
    </Icon>
  </div>
  <div class="mt-8" class:block="{collapsed}" class:hidden="{!collapsed}">
    {#each elements as element}
      <Render element="{element}" />
    {/each}
  </div>
</div>
