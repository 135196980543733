<script>
import Icon from "@local/components/images/Icon.svelte"
import CreateCallOutcomeModal from "@local/components/CreateCallOutcomeModal.svelte"

export let label = ""
export let className = ""
export let leftIcon = ""
export let wfId = ""

let showCallOutcomeModal = null
</script>

{#if showCallOutcomeModal}
  <CreateCallOutcomeModal
    wfId="{wfId}"
    on:success="{() => alert('Success!')}"
    on:close="{() => (showCallOutcomeModal = null)}"
  />
{/if}

<div
  title="{label}"
  class="cursor-pointer"
  on:click|preventDefault="{() => (showCallOutcomeModal = true)}"
>
  <div class="{`flex items-center h-8 btn btn-outline px-0  ${className}`}">
    {#if leftIcon}
      <Icon size="lg" class="mx-2">{leftIcon}</Icon>
    {/if}
    <span class="text-sm font-medium mr-2 hidden lg:block">{label}</span>
  </div>
</div>
