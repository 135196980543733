<style>
.success {
  color: #508e6f;
}
.failed {
  color: #cd4944;
}
</style>

<script>
import Icon from "@local/components/images/Icon.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"

export let call
</script>

<div class="px-2">
  <div class="flex flex-col p-4 card">
    {#if call?.hangup}
      <div class="" title="hangup">
        {call.target?.name || call.target?.phone}
      </div>
      <div class="text-sm flex items-center w-full">
        <div
          class:success="{call?.hangup?.duration != null ||
            call.hangup.totalDuration != null}"
          class:failed="{call?.hangup?.duration == null ||
            call.hangup.totalDuration == null}"
        >
          {#if call.direction === "inbound"}
            <Icon class="mr-2">call_received</Icon>
          {:else}
            <Icon class="mr-2">call_made</Icon>
          {/if}
        </div>
        <TemplateString
          content="{`{{${call.hangup.startedAt}||formatDateShort}} {{${call.hangup.startedAt}||formatFullTime}}`}"
        />
        <div class="ml-auto">
          {call.hangup.duration || call.hangup.totalDuration}
        </div>
      </div>
    {/if}

    {#if call?.dispositions}
      <div class="" title="hangup">
        {call.target?.name || call.target?.phone}
      </div>
      <div class="text-sm flex items-center w-full">
        <div
          class:success="{call?.dispositions?.duration}"
          class:failed="{!call?.dispositions?.duration}"
        >
          {#if call.direction === "inbound"}
            <Icon class="mr-2">call_received</Icon>
          {:else}
            <Icon class="mr-2">call_made</Icon>
          {/if}
        </div>
        <TemplateString
          content="{`{{${call.dispositions.startedAt}||formatDateShort}} {{${call.dispositions.startedAt}||formatFullTime}}`}"
        />
        <div class="ml-auto">
          {call.dispositions.duration}
        </div>
      </div>
      <div class="text-sm flex items-center w-full mt-2">
        <span class="font-semibold">Outcome:</span>
        <span class="ml-1">{call.dispositions.callDispositions} </span>

        {#if call?.dispositions?.dispositionNotes?.length}
          <div class="text-xs ml-2 mx-2">
            {#each call?.dispositions?.dispositionNotes as disposition}
              <p class="mt-1">
                <span class="font-semibold">{disposition?.author || "CS"}:</span
                >
                <span class="ml-1">{disposition?.text || ""}</span>
              </p>
            {/each}
          </div>
        {/if}
      </div>
    {/if}

    {#if call?.voicemailUploaded}
      <div class="" title="voicemail">
        {call.target?.name || call.target?.phone}
      </div>
      <div class="text-sm flex items-center w-full">
        <div class="success">
          <Icon class="mr-2">voicemail</Icon>
        </div>
        <TemplateString
          content="{`{{${call.voicemailUploaded.startedAt}||formatDateShort}} {{${call.voicemailUploaded.startedAt}||formatFullTime}}`}"
        />
      </div>
      <div class="text-sm">
        <a
          href="{call.voicemailUploaded.voicemailLink}"
          class="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          <span class="text-sm flex items-center my-2 hover:underline">
            voicemail sent
          </span>
        </a>
      </div>
    {/if}
  </div>
  <hr class="m-4 hr" />
</div>
