import { writable } from "svelte/store"

let _payload = {}
let _errors = {}

export let payload = writable(_payload)
export let errors = writable(_errors)

export function setPayload(value) {
  _payload = value
  payload.set(value)
}

export function setErrors(value) {
  _errors = value
  errors.set(value)
}

export function clearPayload() {
  setPayload({})
}
export function clearErrors() {
  setErrors({})
}