<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-96 rounded-sm shadow-sm px-4 py-6;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import API, { handleErrors } from "@local/utils/api"
import {
  canadaTimezonesPerProvince,
  usTimezonesPerState,
} from "@local/utils/timezones"
import TextInput from "@local/components/form/TextInput.svelte"
import Select from "@local/components/form/Select.svelte"
import { partners } from "./constants"

const dispatch = createEventDispatcher()

const allTimezones = {
  ...canadaTimezonesPerProvince,
  ...usTimezonesPerState,
}

let optionTimezoneSelected
let form = {
  email: "",
  partnerId: "5ffe4aa654f4105608116eb3",
  partnerOrigin: "seniorcareroc",
  timezone: "",
}
$: form.timezone = allTimezones[optionTimezoneSelected] || ""

let errors

const customErrMsgs = {
  email: {
    alreadyExists: "This email is taken.",
  },
  partnerId: {
    doesNotExist: "This partner does not exist.",
  },
}

const constraints = {
  email: { presence: { allowEmpty: false } },
  partnerId: { presence: { allowEmpty: false } },
  partnerOrigin: { presence: { allowEmpty: false } },
  timezone: {
    presence: { allowEmpty: false },
    inclusion: Object.values(allTimezones),
  },
}

$: currPartner = form.partnerId
  ? partners.find((x) => x.id === form.partnerId)
  : undefined

async function submit() {
  errors = validate(form, constraints)

  if (!errors) {
    try {
      await API.post(`admin/users`, { json: form }).json()
      dispatch("userCreated")
      dispatch("close")
    } catch (err) {
      errors = await handleErrors({ err, customErrMsgs })
    }
  }
}
</script>

<div class="background">
  <div class="modal">
    <h2 class="mb-8 h2">Create User</h2>
    <form on:submit|preventDefault="{submit}">
      <TextInput
        bind:value="{form.email}"
        label="Email"
        name="email"
        errors="{errors && errors.email}"
        class="mb-4"
      />
      <Select
        bind:value="{optionTimezoneSelected}"
        label="Timezone"
        name="timezone"
        options="{Object.entries(allTimezones).map(([label]) => label)}"
        errors="{errors && errors.timezone}"
        allowEmpty="{true}"
        class="mb-4"
      />
      <Select
        bind:value="{form.partnerId}"
        label="Partner"
        name="partnerId"
        options="{partners.map(({ label, id: value }) => ({ label, value }))}"
        errors="{errors && errors.partnerId}"
        allowEmpty="{false}"
        class="mb-4"
      />
      {#if currPartner}
        <Select
          bind:value="{form.partnerOrigin}"
          label="Origin"
          name="origin"
          options="{currPartner.origins}"
          errors="{errors && errors.partnerOrigin}"
          allowEmpty="{false}"
          class="mb-4"
        />
      {/if}
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline-red"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid-red">Create</button>
      </div>
    </form>
  </div>
</div>
