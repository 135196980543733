<style>
.checkbox {
  border: 1px solid #c5c5c5;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import API, { handleErrors } from "@local/utils/api"
import TextInput from "@local/components/form/TextInput.svelte"

const dispatch = createEventDispatcher()

export let scheduleId
let email = ""
let triggerNotifications = false
let forceReserve = false
let errors
let loading = false

async function submit() {
  if (loading) return
  loading = true

  try {
    await API.put(`admin/schedule-allocations/${scheduleId}`, {
      json: { email, triggerNotifications, forceReserve },
    })
    dispatch("close")
    dispatch("slotReserved")
  } catch (err) {
    errors = await handleErrors({ err })
  } finally {
    loading = false
  }
}

$: forceReserve = errors?.wrongTag?.length > 0
</script>

<div class="background-modal">
  <div class="modal w-96">
    <h2 class="mb-8 h2">Reserve Slot</h2>
    <form on:submit|preventDefault="{submit}">
      <TextInput
        bind:value="{email}"
        label="User Email"
        placeholder="Email"
        name="email"
        allowEmpty="{false}"
        errors="{errors && errors.email}"
      />

      {#if errors && errors?.wrongTag}
        <div class="mt-4 text">
          <div class="mt-2 error">{errors.wrongTag[0]}</div>
          <div class="my-2 font-bold">
            Would you like to confirm the schedule reservation anyway?
          </div>
        </div>
      {/if}

      <div class="mt-4">
        <input
          type="checkbox"
          bind:checked="{triggerNotifications}"
          id="{`triggerNotifications`}"
          class="checkbox mr-4"
        />
        <label for="{`triggerNotifications`}" class="text">
          Trigger notifications?
        </label>
      </div>
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid">Reserve</button>
      </div>
    </form>
  </div>
</div>
