<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-3/5 rounded-sm shadow-sm px-4 py-6;
}

dd {
  @apply font-semibold mb-2;
}
</style>

<script>
import validate from "validate.js"
import { createEventDispatcher } from "svelte"
import API, { handleErrors } from "@local/utils/api"
import TextInput from "@local/components/form/TextInput.svelte"
import Select from "@local/components/form/Select.svelte"

export let payment
const allowedRefund = payment.amount - payment.refundedAmount

const dispatch = createEventDispatcher()

const constraints = {}

const reasonOptions = [
  { label: "Duplicate payment", value: "duplcatePayment" },
  { label: "Appointment time issue", value: "appointmentTimeIssue" },
  { label: "Changed mind", value: "changedMind" },
  {
    label: "Unable to provide proof of residency",
    value: "noProofOfResidency",
  },
  { label: "Missed appointment", value: "missedAppointment" },
  { label: "Ineligible to register", value: "ineligibleToRegister" },
  { label: "Denial", value: "denial" },
  { label: "Dissatisfied with services", value: "dissatisfiedWithServices" },
  {
    label: "Deaf/Sign Language Translator",
    value: "deafSignLanguageTranslator",
  },
  { label: "Minor", value: "minor" },
  { label: "Unknown", value: "unknown" },
  { label: "Others", value: "others" },
]

let form = {
  amount: payment.amount - payment.refundedAmount,
  reasonOption: null,
  otherReason: null,
}
let errors

async function submit() {
  if (confirm("Are you sure you want to do this?")) {
    errors = validate(form, constraints)

    if (!errors) {
      try {
        await API.patch(`admin/payments/${payment.id}`, {
          json: form,
        })

        dispatch("refundFinished")
        dispatch("close")
      } catch (err) {
        if (err.response.status === 422) errors = await handleErrors({ err })
        else errors = { refund: "Error to refund" }
      }
    }
  }
}
</script>

<div class="background">
  <div class="modal">
    <h2 class="mb-8 h2">Refund Payment</h2>
    {#if errors && errors.refund}
      <div class="mb-6 text-center text-red-500">{errors.refund}</div>
    {/if}
    <dl>
      <dt>Name</dt>
      <dd>{payment.fullName}</dd>
      <dt>Email</dt>
      <dd>{payment.email}</dd>
      <dt>Transaction ID</dt>
      <dd>{payment.transaction}</dd>
      <dt>Original Amount</dt>
      <dd>{payment.amount}</dd>
      <dt>Refunded Amount</dt>
      <dd>{payment.refundedAmount}</dd>
      <dt>Available to Refund</dt>
      <dd>{payment.amount - payment.refundedAmount}</dd>
      {#if payment.refunds && payment.refunds.length > 0}
        <dt>Refund Reason</dt>
        {#if payment.refunds[0].reasonOption !== "others"}
          <dd>
            {reasonOptions.find(
              (r) => r.value === payment.refunds[0].reasonOption
            ).label}
          </dd>
        {:else}
          <dd>{payment.refunds[0].otherReason}</dd>
        {/if}
      {/if}
    </dl>
    <form on:submit|preventDefault="{submit}">
      <TextInput
        bind:value="{form.amount}"
        label="Amount*"
        name="amount"
        mask="{`000`}"
        class="mb-4"
        errors="{errors && errors.amount}"
      />
      <Select
        bind:value="{form.reasonOption}"
        label="Reason"
        options="{reasonOptions}"
        errors="{errors && errors.reasonOption}"
        class="mb-4"
      />
      {#if form.reasonOption === "others"}
        <TextInput
          bind:value="{form.otherReason}"
          type="text"
          label="Other reason*"
          name="reason"
          class="mb-4"
          errors="{errors && errors.otherReason}"
        />
      {/if}
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline-red"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid-red">Refund</button>
      </div>
    </form>
  </div>
</div>
