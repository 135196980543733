<script>
import IMask from "imask"

export let type = "text"
export let label
export let placeholder = ""
export let value

export let errors = []
$: hasError = errors && errors.length > 0

export let mask = undefined
let inputEl
let imask
$: inputEl, mask, updateMask()
function updateMask() {
  if (!mask || !inputEl) {
    imask && imask.destroy()
    return
  }
  if (imask) {
    imask.updateOptions({ mask })
    return
  }
  imask = new IMask(inputEl, { mask })
  imask.on("accept", () => (value = imask.value))
}

function input(evt) {
  value = evt.target.value
}
</script>

<label class="block cursor-pointer {$$props.class || 'w-full'}">
  {#if label}
    <span class="block mb-2" class:error="{hasError}">{label}</span>
  {/if}
  <input
    type="{type}"
    value="{value}"
    placeholder="{placeholder}"
    bind:this="{inputEl}"
    on:input="{!mask && input}"
    class="w-full input"
    class:with-error="{hasError}"
    on:change
  />
  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</label>
