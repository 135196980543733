<script>
import { createEventDispatcher } from "svelte"
import Icon from "@local/components/images/Icon.svelte"

const dispatch = createEventDispatcher()

export let page = 0
export let hasNextPage = false
export let showCounter = true
export let allowNegativePage = false
export let label = ""
$: hasPreviousPage = page > 0 || allowNegativePage

function next() {
  page = page + 1
  dispatch("next")
}

function prev() {
  page = page - 1
  dispatch("prev")
}
</script>

<div class="flex items-center {$$props.class || ''}">
  <button
    class="flex items-center justify-center w-10 h-10 mr-auto bg-white border border-gray-200 shadow-sm focus:outline-none"
    class:bg-gray-100="{!hasPreviousPage}"
    disabled="{!hasPreviousPage}"
    on:click|preventDefault="{prev}"
  ><Icon size="3xl">arrow_left</Icon></button>

  <div
    class="flex items-center justify-center text-center h-10 px-4 mr-auto font-semibold bg-white border border-gray-200 shadow-sm"
  >
    {#if showCounter}{page + 1}{:else}{label}{/if}
  </div>

  <button
    class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
    class:bg-gray-100="{!hasNextPage}"
    disabled="{!hasNextPage}"
    on:click|preventDefault="{next}"
  ><Icon size="3xl">arrow_right</Icon></button>
</div>
