<style>
.wrapper {
  @apply w-full;
}
.inputs-wrapper {
  @apply grid grid-cols-3 gap-4;
}
</style>

<script>
import getDate from "date-fns/getDate"
import getMonth from "date-fns/getMonth"
import getYear from "date-fns/getYear"
import parseISO from "date-fns/parseISO"
import { payload } from "@local/store/engine"

export let name = ""
export let label = ""
export let className = ""
export let useUTC = false
export let startYear = getYear(new Date())
export let sortYear = "desc"
export let qtyYear = 110

let value = $payload[name]

let year = value ? `${getYear(new Date(value))}` : ""
let month = value ? `${getMonth(new Date(value)) + 1}` : ""
let day = value ? `${getDate(new Date(value))}` : ""

const monthOptions = buildOptions(1, 12, "asc")
const yearOptions = buildOptions(startYear, qtyYear, sortYear)
const dayOptions = buildOptions(1, 31, "asc")

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0

$: updateValue(year, month, day)

function updateValue(year, month, day) {
  if (year && month && day) {
    const dd = `${day}`.padStart(2, "0")
    const mm = `${month}`.padStart(2, "0")
    const selectedDate = `${year}-${mm}-${dd}`
    value = $payload[name] = useUTC
      ? parseISO(`${selectedDate}T12:59:59.000-0000Z`)
      : parseISO(selectedDate)
  } else {
    $payload[name] = undefined
  }
}
function buildOptions(startOption, qtyOption, sort) {
  return new Array(qtyOption)
    .fill(0)
    .map((_, i) => (sort === "asc" ? startOption + i : startOption - i))
    .map((x) => `${x}`)
}
</script>

<div class="wrapper {className}" id="{name}">
  <div class="mb-2" class:error="{hasErrors}">
    <label for="{name}">{label}</label>
  </div>
  <div class="inputs-wrapper">
    <select
      class="input"
      class:with-error="{hasErrors}"
      label="{'mm'}"
      bind:value="{month}"
    >
      <option value></option>
      {#each monthOptions as option}
        <option value="{option}">{option}</option>
      {/each}
    </select>
    <select
      class="input"
      class:with-error="{hasErrors}"
      label="{'dd'}"
      bind:value="{day}"
    >
      <option value></option>
      {#each dayOptions as option}
        <option value="{option}">{option}</option>
      {/each}
    </select>
    <select
      class="input"
      class:with-error="{hasErrors}"
      label="{'yyyy'}"
      bind:value="{year}"
    >
      <option value></option>
      {#each yearOptions as option}
        <option value="{option}">{option}</option>
      {/each}
    </select>
  </div>
  {#if hasErrors}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</div>
