<style>
.icon {
  @apply px-4 flex items-center font-medium cursor-pointer;
  font-size: 12px;
  letter-spacing: -0.41px;
}
.icon:hover {
  color: #e63c24;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import { payload } from "@local/store/engine"
import { addMoreText } from "@shared/utils/translations"

export let name = undefined
export let label = undefined

let value = $payload[name]

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0

let values = !value || value.length === 0 ? [""] : value

function addValue(i) {
  values.push("")
  values = values
}

function removeValue(i) {
  values.splice(i, 1)
  values = values
}

function onChange() {
  value = values.filter((x) => !!x)
  $payload[name] = value
}

$: values, onChange()
</script>

<div class="w-full {$$props.className || ''}">
  {#if label}
    <span class="block mb-2 text" class:error="{hasErrors}">{label}</span>
  {/if}
  {#each values as value, i}
    <div class="flex items-center mb-2">
      <input
        bind:value="{value}"
        class="w-full input"
        class:error="{errors.length > 0}"
        type="text"
        name="{name}"
        id="{name}"
      />
      {#if values.length > 1}
        <div class="icon dark:text-white" on:click="{() => removeValue(i)}">
          <i class="material-symbols-outlined"> remove_circle </i>
        </div>
      {/if}
    </div>
    {#if i === values.length - 1}
      {#if hasErrors}
        <div class="error">{errors[0]}</div>
      {/if}
    {/if}
  {/each}

  <div class="icon dark:text-white" on:click="{addValue}">
    <i class="material-symbols-outlined mr-2">add_circle</i>
    <span class="text-base">{addMoreText["en"]}</span>
  </div>
</div>
