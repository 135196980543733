<script>
import { createEventDispatcher } from "svelte"
import { link } from "svelte-routing"
import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

import API from "@local/utils/api"
import Icon from "@local/components/images/Icon.svelte"

import { calculateScheduleStyles } from "./utils"
import { slotTypes } from "./constants"
import TemplateString from "@shared/components/TemplateString.svelte"

const dispatch = createEventDispatcher()

export let columns = undefined
export let column = undefined
export let _id = undefined
export let workflowId = undefined
export let workflow = undefined
export let startsAt = undefined
export let endsAt = undefined
export let timeZone = undefined
export let checked = false
export let checkboxVisible = true

let icon = ""
if (workflow?.consultType === "Phone") {
  icon = "phone"
} else if (workflow?.consultType === "Video") {
  icon = "videocam"
} else if (workflow?.consultType === "In Person") {
  icon = "apartment"
}

export function parseOnTz(date, timeZone) {
  return utcToZonedTime(date, timeZone)
}

async function clear() {
  if (window.confirm("Are you sure you want to clear this schedule?")) {
    await API.delete(`admin/schedule-allocations/${_id}`)
    dispatch("cleared")
  }
}

async function toggleCheck() {
  checked = !checked
  dispatch("toggleCheck", {
    _id,
    type: slotTypes.taken,
    workflowId,
    action: checked ? "check" : "uncheck",
  })
}
</script>

<div
  class="absolute text-xs font-semibold leading-none truncate border-b border-transparent z-10"
  style="{calculateScheduleStyles({ startsAt, endsAt, column }, columns)}"
>
  <div
    class="flex w-full h-full p-1 truncate group text-yellow-800 bg-yellow-200"
  >
    <div class="flex w-full space-between items-center self-center">
      {#if checkboxVisible}
        <button class="focus:outline-none mr-1" on:click="{toggleCheck}">
          <Icon size="xl">
            {#if checked}check_box{:else}check_box_outline_blank{/if}
          </Icon>
        </button>
      {/if}
      <a
        class="flex flex-col gap-1 font-semibold cursor-pointer hover:underline"
        href="{`/workflows/${workflowId}`}"
        target="_blank"
        use:link
      >
        <div>
          {#if (!!workflow.returningPatient && workflow.returningType !== "followUp") || (workflow.starseedStatus && workflow.starseedStatus === "Renewal")}
            <Icon size="sm">autorenew</Icon>
          {:else if !!workflow.returningPatient && workflow.returningType === "followUp"}
            <Icon size="sm">sync_alt</Icon>
          {/if}
          {format(parseOnTz(startsAt, timeZone), "hh:mmaaa")}
          {workflow.subdomain}

          <TemplateString content="{workflow.place}" />
        </div>
        <div>
          {#if !!workflow.extraInfo}
            <Icon size="sm">info</Icon>
          {/if}
          {workflow.firstName}
          {workflow.lastName[0]},

          <TemplateString content="{workflow.age}" />
        </div>
      </a>
      <div class="flex gap-1 ml-auto">
        {#if !!workflow.preAssignedTo}
          <Icon size="lg">person</Icon>
        {/if}
        {#if !!workflow.veteran}
          <Icon size="lg">military_tech</Icon>
        {/if}
        <button
          class="opacity-0 group-hover:opacity-100 focus:outline-none"
          on:click="{() => clear()}"><Icon size="md">clear</Icon></button
        >
        <Icon size="md">
          {icon}
        </Icon>
      </div>
    </div>
  </div>
</div>
