<script>
export let type = "text"
export let label
export let placeholder = ""
export let value
export let options = []
export let allowEmpty = false
export let errors = []

let _options = []

$: options, optionsChanged(options)
$: hasError = errors && errors.length > 0

function optionsChanged(options) {
  _options = options
  if (!options.some((x) => (x.value || x) === value)) {
    value = allowEmpty ? "" : _options[0].value || _options[0] || ""
  }
}
</script>

<label class="block cursor-pointer {$$props.class || 'w-full'}">
  {#if label}
    <span class="block mb-2" class:error="{hasError}">{label}</span>
  {/if}
  <select
    type="{type}"
    bind:value
    placeholder="{placeholder}"
    class="w-full input"
    class:with-error="{hasError}"
  >
    {#if allowEmpty}
      <option value></option>
    {/if}
    {#each _options as option, i}
      <option value="{option.value || option}">{option.label || option}</option>
    {/each}</select>
  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</label>
