<style>
button {
  @apply rounded m-0 text-black outline-none p-2 px-5;
}

.selected {
  @apply outline-none;
  border-color: var(--primary);
  color: var(--primary);
  background-color: white;
}
</style>

<script>
import { getContext } from "svelte"
import { TABS } from "./Tabs.svelte"

const tab = {}
const { registerTab, selectTab, selectedTab } = getContext(TABS)

registerTab(tab)
</script>

<button
  class="tab type-paragraph"
  class:selected="{$selectedTab === tab}"
  on:click|preventDefault|stopPropagation="{() => selectTab(tab)}"
>
  <slot />
</button>
