<script>
import TemplateString from "@shared/components/TemplateString.svelte"

export let label = ""
export let exposeLineBreaks = false
let value = ""
export { value as data }
</script>

<div class="{$$props.className || ''}">
  <div class="text-xs font-semibold text-slate-900">{label}</div>
  <div class="text-gray-600" class:whitespace-pre-wrap="{exposeLineBreaks}">
    {#if value}
      <TemplateString content="{value}" />
    {:else}--{/if}
  </div>
</div>
