<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-96 rounded-sm shadow-sm px-4 py-6;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import API, { handleErrors } from "@local/utils/api"
import TextArea from "@local/components/form/TextArea.svelte"

const dispatch = createEventDispatcher()

export let wfId
export let note

let form
let errors

const constraints = {
  notes: { presence: { allowEmpty: false } },
}

async function submit() {
  if (note === form.notes) {
    dispatch("notesUpdated")
    dispatch("close")
    return
  }

  errors = validate(form, constraints)
  if (errors) return

  try {
    await API.patch(`admin/workflow/${wfId}/notes`, {
      json: { ...form, originalNote: note },
      searchParams: { role: "admin" },
    }).json()

    dispatch("notesUpdated")
    dispatch("close")
  } catch (err) {
    console.log({ err })
    errors = await handleErrors({ err })
  }
}

$: form = {
  notes: note || "",
}
</script>

<div class="background">
  <div class="modal">
    <h2 class="mb-8 h2">Edit Note</h2>
    <form on:submit|preventDefault="{submit}" class="h-full">
      <TextArea
        bind:value="{form.notes}"
        label="Notes"
        name="notes"
        errors="{errors && errors.notes}"
        class="mb-4"
        rows="5"
      />

      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline-blue"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid-blue">Save</button>
      </div>
    </form>
  </div>
</div>
