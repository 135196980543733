<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import { format, toDate, utcToZonedTime } from "date-fns-tz"
import API, { handleErrors } from "@local/utils/api"
import TextInput from "@local/components/form/TextInput.svelte"
import TextArea from "@local/components/form/TextArea.svelte"
import CallOutcome from "@local/components/CallOutcome.svelte"

const needReturnCall = ["Leave Voicemail (LVM)", "Will Renew Later"]

const dispatch = createEventDispatcher()
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export let wfId

let form = {
  callOutcome: "Leave Voicemail + Emailed (LVM + E)",
  returnCallAt: format(toDate(new Date(), { timezone }), "yyyy-MM-dd", {
    timezone,
  }),
  note: "",
}
let errors

validate.extend(validate.validators.datetime, {
  parse: function (value) {
    return new Date(value)
  },
  format: function (value) {
    return format(toDate(value, { timezone }), "yyyy-MM-dd", { timezone })
  },
})

const constraints = {
  returnCallAt: {
    datetime: {
      dateOnly: true,
      earliest: new Date(),
    },
  },
}

const searchParams = { role: "admin" }
async function submit() {
  let { callOutcome, returnCallAt } = form

  if (!needReturnCall.includes(callOutcome)) {
    returnCallAt = null
  }

  if (returnCallAt) {
    returnCallAt = utcToZonedTime(new Date(returnCallAt), timezone)

    errors = validate({ returnCallAt }, constraints)

    returnCallAt = form.returnCallAt
  }

  if (!errors) {
    try {
      await API.patch(`admin/workflow/${wfId}/who-to-call-outcome`, {
        json: { ...form, returnCallAt },
        searchParams,
      }).json()

      dispatch("success")
      dispatch("close")
    } catch (err) {
      console.log({ err })
      errors = await handleErrors({ err })
    }
  }
}
</script>

<div class="background-modal">
  <div class="modal w-96">
    <h2 class="mb-8 h2">Call outcome</h2>
    <form on:submit|preventDefault="{submit}" class="h-full">
      <CallOutcome class="w-full mb-4" bind:value="{form.callOutcome}" />
      {#if needReturnCall.includes(form.callOutcome)}
        <TextInput
          class="w-full mb-4"
          name="returnCallAt"
          type="date"
          label="Return call at"
          bind:value="{form.returnCallAt}"
          errors="{errors && errors.returnCallAt}"
        />
      {/if}
      <TextArea
        bind:value="{form.note}"
        label="Note"
        name="note"
        class="mb-4"
        rows="5"
      />
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid">Save</button>
      </div>
    </form>
  </div>
</div>
