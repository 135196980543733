<script>
import { useQuery } from "@sveltestack/svelte-query"

import Render from "@local/components/engine/Render.svelte"
import API from "@local/utils/api"

export let wfId

async function fetchWfViews({ wfId }) {
  const role = ["role", "admin"]

  const searchParams = [role]
  return await API.get(`workflows/${wfId}/views/csWorkflow`, {
    searchParams,
  }).json()
}
const queryResult = useQuery(["workflowId", wfId], () => fetchWfViews({ wfId }))
</script>

{#if $queryResult.isLoading}
  <div></div>
{:else if $queryResult.error}
  <div>{$queryResult.error}</div>
{:else}
  <div class="p-5">
    {#each $queryResult.data.elements as element}
      <Render element="{element}" />
    {/each}
  </div>
{/if}
