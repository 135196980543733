<style>
.isNotMobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 45px;
  height: fit-content;
}
@media (max-width: 550px) {
  .isMobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45px;
    grid-template-columns: unset;
  }
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import { useQuery } from "@sveltestack/svelte-query"
import {
  addYears,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  subDays,
  subMonths,
} from "date-fns"
import { toDate } from "date-fns-tz"

const dispatch = createEventDispatcher()

import API from "@local/utils/api"
import Select from "@local/components/form/Select.svelte"
import Icon from "@local/components/images/Icon.svelte"

const modes = [
  {
    value: "future",
    label: "Future Capacity",
    startsAtFn: () => toDateOnTz(new Date()),
    endsAtFn: (startsAt) => toDateOnTz(addYears(startsAt, 5)),
  },
  {
    value: "lastWeek",
    label: "Last Week's Capacity",
    startsAtFn: () => startOfWeek(subDays(toDateOnTz(new Date()), 7)),
    endsAtFn: (startsAt) => endOfWeek(startsAt),
  },
  {
    value: "thisWeek",
    label: "This Week's Capacity",
    startsAtFn: () => startOfWeek(toDateOnTz(new Date())),
    endsAtFn: (startsAt) => endOfWeek(startsAt),
  },
  {
    value: "nextWeek",
    label: "Next Week's Capacity",
    startsAtFn: () => startOfWeek(addWeeks(toDateOnTz(new Date()), 1)),
    endsAtFn: (startsAt) => endOfWeek(startsAt),
  },
  {
    value: "lastMonth",
    label: "Last Month's Capacity",
    startsAtFn: () => startOfMonth(subMonths(toDateOnTz(new Date()), 1)),
    endsAtFn: (startsAt) => endOfMonth(startsAt),
  },
  {
    value: "thisMonth",
    label: "This Month's Capacity",
    startsAtFn: () => startOfMonth(toDateOnTz(new Date())),
    endsAtFn: (startsAt) => endOfMonth(startsAt),
  },
  {
    value: "nextMonth",
    label: "Next Month's Capacity",
    startsAtFn: () => startOfMonth(addMonths(toDateOnTz(new Date()), 1)),
    endsAtFn: (startsAt) => endOfMonth(startsAt),
  },
]

let mode = "thisWeek"
$: queryOptions = {
  queryKey: ["schedule-capacity", mode],
  queryFn: () => fetch(mode),
  refetchOnWindowFocus: false,
  refetchInterval: 5000,
}
const query = useQuery(queryOptions)
$: query.setOptions(queryOptions)
$: scheduleCapacity = fillCapacity($query.data)

export let timeZone
export let scheduleTags = []

function toDateOnTz(date) {
  return toDate(date, { timeZone })
}

function getStyle(tags) {
  if (tags[0] === "All") {
    return `background-color: black; color: white;width:155px;font-size:14px;`
  }

  const tagDef = scheduleTags.find((tagDef) => tagDef.label === tags[0])
  return `background-color: ${tagDef.backgroundColor}; color: ${tagDef.textColor};width:155px;font-size:14px;`
}

function fillCapacity(capacity = []) {
  let newCapacity = [...capacity]

  const existingTagLabels = capacity.map((capacity) => capacity.tags[0])
  const missingTags = scheduleTags
    .filter((tagDef) => !existingTagLabels.includes(tagDef.label))
    .map((tagDef) => tagDef.label)
  const emptyCapacity = missingTags.map((x) => ({
    tags: [x],
    usedCount: 0,
    totalCount: 0,
  }))

  const all = {
    tags: ["All"],
    usedCount: capacity.reduce((acc, { usedCount }) => acc + usedCount, 0),
    totalCount: capacity.reduce((acc, { totalCount }) => acc + totalCount, 0),
  }

  newCapacity = [...newCapacity, ...emptyCapacity].sort((a, b) => {
    const aTag = a.tags[0]
    const bTag = b.tags[0]
    return aTag > bTag ? 1 : aTag < bTag ? -1 : 0
  })

  return [...newCapacity, all]
}

async function fetch(mode) {
  const { startsAtFn, endsAtFn } = modes.find((x) => x.value === mode)
  const startsAt = startsAtFn()
  const endsAt = endsAtFn(startsAt)
  const searchParams = { timeZone, startsAt, endsAt }
  return await API.get(`admin/schedule-capacity`, { searchParams }).json()
}
</script>

<div
  class="flex justify-start gap-4 mb-4 pb-4 border-b border-gray-300 isMobile isNotMobile"
>
  <Select class="w-52" bind:value="{mode}" options="{modes}" />
  {#each scheduleCapacity as { tags, usedCount, totalCount }}
    <div class="flex justify-center items-center font-semibold">
      <div
        class="flex text-green-500 items-center rounded-sm px-1 mr-2 cursor-pointer"
        style="{getStyle(tags)}"
      >
        <div
          class="flex flex-1 justify-center leading-7"
          on:click="{() =>
            dispatch('tagClick', tags[0] === 'All' ? true : tags[0])}"
        >
          {tags[0]}
        </div>
        {#if tags[0] !== "All"}
          <div
            class="opacity-60 hover:opacity-100"
            on:click="{() => dispatch('tagInfo', tags[0])}"
          >
            <Icon size="lg">info_outline</Icon>
          </div>
        {/if}
      </div>

      <div
        style="width: 5rem;"
        class:text-red-700="{usedCount === totalCount}"
        class:text-red-500="{usedCount !== totalCount &&
          totalCount > 0 &&
          usedCount / totalCount > 0.6}"
        class:text-yellow-500="{usedCount !== totalCount &&
          totalCount > 0 &&
          usedCount / totalCount <= 0.6}"
        class:text-green-700="{usedCount !== totalCount &&
          totalCount > 0 &&
          usedCount / totalCount < 0.4}"
      >
        {usedCount}/{totalCount}
      </div>
    </div>
  {/each}
</div>
