<style>
.content {
  @apply flex w-full min-h-screen overflow-x-auto;
}
</style>

<script>
import Menu from "./Menu.svelte"
import QuickVideo from "./QuickVideo/index.svelte"
import { getDataFromLocalStorage } from "@local/utils/localStorage"

let userPreference = getDataFromLocalStorage("userPreference")
let darkMode = userPreference?.darkMode || false
</script>

<QuickVideo />
<Menu />

<div class="content" class:dark="{darkMode}">
  <div class="flex-1 background py-6 px-4 pl-28 md:pl-56">
    <slot />
  </div>
</div>
