<script>
import Render from "@local/components/engine/Render.svelte"
export let rows = []

$: cols = rows.reduce((max, row) => (max < row.length ? row.length : max), 0)
$: gridClass = `grid-cols-1 md:grid-cols-${cols}`

function colSpan(row, i) {
  if (row.length === cols || i !== row.length - 1) {
    return `md:col-span-1`
  } else {
    const extra = cols - row.length + 1
    return `md:col-span-${extra}`
  }
}
</script>

<div class="w-full grid gap-4 {$$props.className || ''} {gridClass}">
  {#each rows as elements}
    {#each elements as element, i}
      <div class="{colSpan(elements, i)}">
        <Render element="{element}" />
      </div>
    {/each}
  {/each}
</div>
