<script>
import { Router, Route } from "svelte-routing"
import { QueryClient, QueryClientProvider } from "@sveltestack/svelte-query"
import { isLoggedIn, currentUser, isOnTwoFactorAuth } from "@local/store/auth"
import Tailwind from "@local/config/Tailwind.svelte"
import Redirect from "@shared/config/Redirect.svelte"
import MainLayout from "@local/components/layouts/Main/index.svelte"
import Signin from "@local/routes/signin.svelte"
import Queue from "@local/routes/index/index.svelte"
import Workflows from "@local/routes/workflows/index.svelte"
import Workflow from "@local/routes/workflows/[workflowId]/index.svelte"
import WorkflowLogs from "@local/routes/workflows/[workflowId]/logs.svelte"
import WorkflowNotes from "@local/routes/workflows/[workflowId]/notes.svelte"
import WorkflowCommunications from "@local/routes/workflows/[workflowId]/communications.svelte"
import WorkflowViewEdit from "@local/routes/workflows/[workflowId]/views/[viewKey].svelte"
import Users from "@local/routes/users/index.svelte"
import User from "@local/routes/users/[userId]/index.svelte"
import Calendar from "@local/routes/calendar/index.svelte"
import CancellationList from "@local/routes/cancellations/index.svelte"
import ReportPostConsults from "@local/routes/reports/post-consults.svelte"
import ReportReferrals from "@local/routes/reports/referrals.svelte"
import ReportApprovals from "@local/routes/reports/approvals.svelte"
import ReportStarseed from "@local/routes/reports/starseed.svelte"
import ReportStarseedTemp from "@local/routes/reports/starseed-temp.svelte"
import ReportStarseedPrivacyPolicyOffenses from "@local/routes/reports/starseed-privacy-policy-offenses.svelte"
import ReportPurchases from "@local/routes/reports/purchases.svelte"
import ReportMDBilling from "@local/routes/reports/md-billing.svelte"
import Payments from "@local/routes/payments/index.svelte"
import Coupons from "@local/routes/coupons/index.svelte"
import Analytics from "@local/routes/analytics/index.svelte"
import WhoToCall from "@local/routes/whoToCall/index.svelte"
import TwoFactorAuth from "@local/routes/twoFactorAuth/index.svelte"

const queryClient = new QueryClient()
</script>

<QueryClientProvider client="{queryClient}">
  <Tailwind />
  <Router>
    {#if !$isLoggedIn}
      <Route path="signin" component="{Signin}" />
      <Route path="*" let:location>
        <Redirect
          to="/signin"
          replace="{true}"
          keepNext="{true}"
          location="{location}"
        />
      </Route>
    {:else if $currentUser.roles.includes("admin")}
      {#if $isOnTwoFactorAuth}
        <Route path="two-factor-auth" component="{TwoFactorAuth}" />
        <Route path="*" let:location>
          <Redirect
            to="/two-factor-auth"
            replace="{true}"
            keepNext="{true}"
            location="{location}"
          />
        </Route>
      {:else}
        <MainLayout>
          <Route path="/" component="{Queue}" />
          <Route path="/workflows" component="{Workflows}" />
          <Route path="/users" component="{Users}" />
          <Route path="/users/:userId" component="{User}" />
          <Route path="/workflows/:wfId/logs" component="{WorkflowLogs}" />
          <Route path="/workflows/:wfId/notes" component="{WorkflowNotes}" />
          <Route
            path="/workflows/:wfId/communications"
            component="{WorkflowCommunications}"
          />
          <Route path="/workflows/:wfId" component="{Workflow}" />
          <Route
            path="/workflows/:wfId/views/:viewKey"
            component="{WorkflowViewEdit}"
          />
          <Route path="/calendar" component="{Calendar}" />
          <Route path="/who-to-call" component="{WhoToCall}" />
          <Route path="/cancellation-list" component="{CancellationList}" />
          <Route path="/payments" component="{Payments}" />
          <Route path="/coupons" component="{Coupons}" />
          <Route path="/analytics" component="{Analytics}" />
          <Route
            path="/reports/post-consults"
            component="{ReportPostConsults}"
          />
          <Route path="/reports/referrals" component="{ReportReferrals}" />
          <Route path="/reports/approvals" component="{ReportApprovals}" />
          <Route
            path="/reports/starseed-temp"
            component="{ReportStarseedTemp}"
          />
          <Route
            path="/reports/starseed-privacy-policy-offenses"
            component="{ReportStarseedPrivacyPolicyOffenses}"
          />
          <Route path="/reports/starseed" component="{ReportStarseed}" />
          <Route path="/reports/purchases" component="{ReportPurchases}" />
          <Route path="/reports/mdbilling" component="{ReportMDBilling}" />
          <Route path="*" let:location>
            <Redirect
              to="/"
              replace="{false}"
              keepNext="{true}"
              location="{location}"
            />
          </Route>
        </MainLayout>
      {/if}
    {/if}
  </Router>
</QueryClientProvider>
