import { writable } from "svelte/store"

export let userId = writable(null)
export let firstName = writable(null)

export function leave() {
  userId.set(null)
  firstName.set(null)
}

export function join(props) {
  leave()
  userId.set(props.userId)
  firstName.set(props.firstName)
}
