<script>
import Icon from "@local/components/images/Icon.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"

export let sms
export let reduced = true
</script>

<div class="px-2">
  <div class="flex flex-col card p-4" class:pointer="{sms?.mms && sms?.mmsUrl}">
    <div class="" title="hangup">
      {sms.target?.name || sms.target?.phone}
    </div>
    <div class="text-sm flex items-center w-full">
      <Icon class="mr-2 text-blue-400">sms</Icon>
      <TemplateString
        content="{`{{${sms.createdDate}||formatDateShort}} {{${sms.createdDate}||formatFullTime}}`}"
      />
    </div>
    <div class="text-sm mt-4">
      {#if sms?.mms}
        <a
          href="{sms.mmsUrl}"
          class="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          <Icon class="mr-2">mms</Icon>
          MMS sent (click to visualize)
        </a>
      {:else}
        <p class="whitespace-pre-wrap" class:text-xs="{reduced}">
          {sms.text}
        </p>
      {/if}
    </div>
  </div>
  <hr class="m-4 hr" />
</div>
