<script>
export let label = "Download"
export let url = ""
export let className = ""
export let docReferences = []
let submitting = false

async function click() {
  try {
    submitting = true
    await fetch(url, {
      method: "POST",
      body: JSON.stringify({ docReferences }),
      headers: { "Content-Type": "application/json" },
    })
    alert("Resent!")
  } catch (err) {
    alert("Failed to resend: " + err)
  } finally {
    submitting = false
  }
}
</script>

{#if submitting}
  <div>Faxing</div>
{:else}
  <div
    on:click="{click}"
    target="_blank"
    class="{`block hover:underline cursor-pointer ${className}`}"
  >
    {label}
  </div>
{/if}
