<script>
import { createEventDispatcher } from "svelte"
import API, { handleErrors } from "@local/utils/api"
import Select from "@local/components/form/Select.svelte"

const dispatch = createEventDispatcher()

export let selectedIds = []
export let doctorOptions = []
let doctorSelected

let error

async function submit() {
  error = []
  if (!doctorSelected) {
    error.push("Can't be empty")
    return
  }
  try {
    await API.put("admin/workflow/pre-assigned-doctors", {
      json: { ids: selectedIds, preAssignedDoctor: doctorSelected },
    })
    dispatch("close")
    dispatch("slotsPreAssigned")
  } catch (err) {
    error = await handleErrors({ err })
  }
}
</script>

<div class="background-modal">
  <div class="modal w-96">
    <h2 class="mb-8 h2">Pre Assign Doctor</h2>
    <form on:submit|preventDefault="{submit}">
      <div class="mt-4">
        <Select
          label="{'Doctor'}"
          options="{doctorOptions}"
          allowEmpty="{true}"
          errors="{error}"
          bind:value="{doctorSelected}"
        />
      </div>
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid">Pre assign</button>
      </div>
    </form>
  </div>
</div>
