<style>
.card-general,
.card-default {
  @apply bg-blue-200 text-black;
}
.card-sms {
  @apply bg-teal-200 text-black;
}
.card-noshow {
  @apply bg-red-200	text-black;
}
.card-followupcall {
  @apply bg-orange-200	text-black;
}
</style>

<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import queryString from "query-string"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import Icon from "@local/components/images/Icon.svelte"
import NotesModal from "./NotesModal.svelte"
import Breadcrumb from "@local/components/engine/elements/Breadcrumb.svelte"
import Select from "@local/components/form/Select.svelte"
import { currentUser } from "@local/store/auth"

const queryClient = useQueryClient()
const types = ["All Types", "General", "SMS", "No Show", "Follow Up Call"]

export let wfId

let modal
let originalNote
let breadcrumbItems = []
let currentUserId = $currentUser.id
let currentUserRoleLevel = $currentUser.roleLevel
let optionType = "All Types"

$: allNotes = queryString.parse(location.search).allNotes || false

async function fetchNotes({ wfId }) {
  const searchParams = { role: "admin", typeFilter: optionType, allNotes }
  const response = await API.get(`admin/workflow/${wfId}/notes`, {
    searchParams,
  })?.json()
  const rows = (await response.notes) || []

  breadcrumbItems = [
    { label: "Home", url: "/", icon: "home" },
    {
      label: "Workflows",
      url: "/workflows?type=medical",
      icon: "medical_information",
    },
    {
      label: `${response?.name || ""}`,
      url: `/workflows/${wfId}`,
      icon: "person",
    },
    { label: "CS Notes" },
  ]
  return { rows: rows.filter((x) => !x.disabled) }
}

async function deleteNote(note) {
  const confirmation = confirm("Are you sure you want to remove this cs note?")
  if (!confirmation) {
    return
  }

  try {
    await API.delete(`admin/workflow/${note.wfId}/notes/${note.noteIndex}`, {
      json: { note },
      searchParams: { role: "admin" },
    }).json()

    queryClient.invalidateQueries("internalNotes")
  } catch (err) {
    window.alert("Only the author can remove this note.")
    console.log({ err })
  }
}

async function pin(note) {
  const confirmation = confirm(
    "Are you sure you want to pin this internal note?"
  )
  if (!confirmation) return
  await updatePin(note, true)
}

async function unpin(note) {
  const confirmation = confirm(
    "Are you sure you want to unpin this internal note?"
  )
  if (!confirmation) return
  await updatePin(note, false)
}

async function updatePin(note, pin) {
  try {
    await API.patch(`admin/workflow/${note.wfId}/notes/${note.noteIndex}`, {
      json: { originalNote: note, pin },
      searchParams: { role: "admin" },
    })

    queryClient.invalidateQueries("internalNotes")
  } catch (err) {
    console.log({ err })
  }
}

async function openNotesModal(note) {
  originalNote = note
  modal = true
}

$: queryOptions = {
  queryKey: ["internalNotes", wfId, optionType],
  queryFn: () => fetchNotes({ wfId, optionType }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

{#if modal}
  <NotesModal
    note="{originalNote}"
    on:modal="{() => queryClient.invalidateQueries('internalNotes')}"
    on:close="{() => (modal = undefined)}"
    wfId="{wfId}"
  />
{/if}
<Query options="{queryOptions}">
  <div slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="p-5">
        <Breadcrumb items="{breadcrumbItems}" />
        <div class="flex flex-1 items-center">
          <h2 class="h2 mb-4">CS Notes</h2>
        </div>
        <div class="flex items-end mb-4">
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 btn btn-outline mr-4"
            on:click="{() => openNotesModal()}"
            ><Icon size="3xl">post_add</Icon></button
          >
          <Select
            placeholder="Type"
            name="type"
            bind:value="{optionType}"
            options="{types}"
            class="w-full mx-2"
          />
        </div>
        {#if queryResult.data.rows.length > 0}
          {#each queryResult.data.rows as row}
            <div class="rounded-xl  p-5 w-full card mb-8">
              <div
                class="flex w-full items-center justify-between border-b pb-3 mb-4"
              >
                <div class="flex items-center space-x-8 mr-8">
                  <span
                    class="card-{row.type
                      ?.toLowerCase()
                      ?.replaceAll(
                        ' ',
                        ''
                      )} text-base px-2.5 py-0.5 my-auto rounded"
                  >
                    {row.type}
                  </span>
                  <div class="flex flex-row">
                    {#if row.reminder}
                      <div class="mr-2">
                        <Icon size="lg">notifications_active</Icon>
                      </div>
                      <div class="pt-1 pr-4">
                        {row.reminder}
                      </div>
                    {/if}
                    {#if row.period}
                      <div class="pt-1">
                        {row.period}
                      </div>
                    {/if}
                  </div>
                </div>
                <div class="flex items-center space-x-3">
                  <div
                    class="italic text-right text-xs md:text-sm lg:text-base"
                  >
                    {row.authorName}
                    @
                    <TemplateString
                      content="{`{{${row.date}||formatDateTime}}`}"
                    />
                  </div>
                </div>
              </div>
              {#if row.outcome}
                <div class="text-xl font-semibold">
                  {row.outcome}
                </div>
              {/if}
              <div class="mt-2">
                {row.message}
              </div>
              <div class="flex flex-row justify-end">
                {#if !row.pin}
                  <div class="link mr-2" on:click="{() => pin(row)}">
                    <Icon size="2xl">keep</Icon>
                  </div>
                {/if}
                {#if row.pin && currentUserRoleLevel < 2}
                  <div class="link mr-2" on:click="{() => unpin(row)}">
                    <Icon size="2xl">keep_off</Icon>
                  </div>
                {/if}
                {#if row?.authorId === currentUserId || currentUserRoleLevel < 2}
                  <div
                    class="link icon-{row.type?.toLowerCase()} mr-2"
                    on:click="{() => openNotesModal(row)}"
                  >
                    <Icon size="2xl">edit</Icon>
                  </div>
                {/if}
                {#if row?.authorId === currentUserId || currentUserRoleLevel < 2}
                  <div
                    class="link icon-{row.type?.toLowerCase()}"
                    on:click="{() => deleteNote(row)}"
                  >
                    <Icon size="2xl">close</Icon>
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        {:else}
          <EmptyMessage icon="check">No records</EmptyMessage>
        {/if}
      </div>
    {/if}
  </div>
</Query>
