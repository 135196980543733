<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white rounded-sm shadow-sm px-4 py-6;
}
</style>

<script>
import { createEventDispatcher } from "svelte"

const dispatch = createEventDispatcher()

export let tag = null
let tagStyle = null

$: tag,
  (tagStyle = `background-color: ${tag.backgroundColor}; color: ${tag.textColor};`)
</script>

<div class="background">
  <div class="modal max-w-2xl ">
    {#if tag}
      <div
        class="w-48 font-semibold text-base text-center p-1 mb-4 mx-auto rounded-sm"
        style="{tagStyle}"
      >
        {tag.label}
      </div>

      <div class="max-h-96 overflow-y-auto">
        {@html tag.explainer}
      </div>

      <div class="flex justify-center mt-4">
        <button
          type="button"
          class="btn btn-outline-blue"
          on:click="{() => dispatch('close')}"
        >Close</button>
      </div>
    {/if}
  </div>
</div>
