<script>
import Icon from "@shared/components/Icon.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"

export let content = []
export let leftIcon = ""
export let label = ""
export let targetBlank = false
export let url = ""
export let showOpened = true
export let collapsible = false
export let classContent = ""

let collapsed = showOpened === true

$: content = content ? JSON.parse(content) : []
</script>

<div class="{$$props.className || ''}">
  <div class="flex flex-row items-center m-4 cursor-pointer">
    <a
      href="{url}"
      class="flex flex-1 items-center rounded p-2 select-none"
      target="{targetBlank ? '_blank' : '_self'}"
    >
      {#if leftIcon}
        <Icon class="text-slate-900 mr-2">{leftIcon}</Icon>
      {/if}
      <span class="h2 text-slate-900 font-semibold w-full text-base lg:text-xl"
        >{label}</span
      >
    </a>
    {#if collapsible}
      <div class="select-none" on:click="{() => (collapsed = !collapsed)}">
        <Icon class="text-gray-400 mx-2 hover:text-gray-600">
          <span class="text-3xl">
            {#if collapsed}expand_less{:else}expand_more{/if}
          </span>
        </Icon>
      </div>
    {/if}
  </div>

  <div
    class:block="{collapsible && collapsed}"
    class:hidden="{collapsible && !collapsed}"
  >
    {#if content?.length}
      <div
        class="px-4 overflow-y-auto overflow-x-hidden p-4 {classContent || ''}"
      >
        {#each content as item}
          {#if item?.date}
            <p class="text-gray-400 text-sm my-1">
              <TemplateString content="{`{{${item.date}||formatDateTime}}`}" />
            </p>
          {/if}
          {#if item?.author}
            <p class="text-gray-600 font-semibold text-sm">{item.author}</p>
          {/if}
          <p class="text-gray-600 my-2 text-sm">{item.value}</p>
          <hr class="m-2 border-gray-200" />
        {/each}
      </div>
    {:else}
      <div class="px-4 flex justify-center mt-4 pb-4">
        <p class="text-gray-600 italic">empty list</p>
      </div>
    {/if}
  </div>
</div>
