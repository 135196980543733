<style>
</style>

<script>
import Icon from "@local/components/images/Icon.svelte"

export let direction
export let target
export let contact
export let reduced = true
</script>

<div
  class="text-gray-400 flex items-center justify-around"
  class:text-xs="{reduced}"
>
  {target?.name || target?.phone}
  <Icon class="text-gray-500 my-1">
    {#if direction === "outbound"}
      chevron_right
    {:else}
      chevron_left
    {/if}
  </Icon>
  {contact?.name || contact?.phone}
</div>
