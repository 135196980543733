<script>
import Icon from "@local/components/images/Icon.svelte"
export let icon = ""
</script>

<div
  class="flex flex-col py-8 space-y-2 text-xl font-semibold text-center border border-gray-300 border-dashed bg-gray-50 {$$props.class || ''}"
>
  <Icon size="6xl">{icon}</Icon>
  <span><slot /></span>
</div>
