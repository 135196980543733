<style>
.item {
  @apply flex flex-row justify-center items-center p-2 text-gray-400 mb-2 cursor-pointer sticky self-start;
  grid-gap: 3px;
}

.item:hover {
  @apply text-white;
}
</style>

<script>
import { link } from "svelte-routing"
import { currentUser, deauth } from "@local/store/auth"
import Logo from "@local/components/images/Logo.svelte"
import Icon from "@local/components/images/Icon.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import { logoutText } from "@shared/utils/translations"

const itemsSidebar = [
  { to: "/", label: "Dashboard", icon: "queue_play_next" },
  { to: "/users", label: "Users", icon: "person", accessLevel: 3 },
  {
    to: "/workflows?type=medical",
    label: "Medical Workflows",
    icon: "medical_information",
    accessLevel: 3,
  },
  {
    to: "/workflows?type=educator",
    label: "Educator Workflows",
    icon: "history_edu",
    accessLevel: 3,
  },
  {
    to: "/calendar",
    label: "Calendar",
    icon: "calendar_month",
    accessLevel: 3,
  },
  { to: "/who-to-call", label: "Who to call", icon: "call", accessLevel: 3 },
  {
    to: "/cancellation-list",
    label: "Cancellations",
    icon: "event_busy",
    accessLevel: 3,
  },
  { to: "/payments", label: "Payments", icon: "local_atm", accessLevel: 1 },
  { to: "/coupons", label: "Coupons", icon: "local_atm", accessLevel: 1 },
  {
    to: "/reports/post-consults",
    label: "Reports",
    icon: "plagiarism",
    accessLevel: 1,
  },
  {
    to: "/analytics",
    label: "Analytics",
    icon: "insert_chart_outlined",
    accessLevel: 5,
  },
]
</script>

<div class="fixed z-50 h-full pb-4 w-36 overflow-y-auto bg-slate-900">
  <div class="absolute flex flex-col flex-shrink-0 w-full">
    <a href="/" class="flex justify-center my-10" use:link>
      <Logo class="w-12" />
    </a>

    {#each itemsSidebar as item}
      {#if item.accessLevel && $currentUser && $currentUser.roleLevel <= item.accessLevel}
        <a class="item" href="{item.to}" use:link>
          <Icon class="text-3xl">{item.icon}</Icon>
          <span class="text-sm">{item.label}</span>
        </a>
      {/if}
    {/each}

    <div class="item mt-8" on:click="{deauth}">
      <Icon class="text-3xl">logout</Icon>
      <span class="text-sm">{logoutText[$workflowLang]}</span>
    </div>
  </div>
</div>
