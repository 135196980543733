<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import API, { handleErrors } from "@local/utils/api"
import TextInput from "@local/components/form/TextInput.svelte"

const dispatch = createEventDispatcher()

export let data
let errors
let form = {
  limit: data?.limit || "",
}
const constraints = {
  limit: { presence: { allowEmpty: false } },
}

async function submit() {
  errors = validate(form, constraints)
  if (errors || !isValidThreshold(form.limit)) {
    return
  }

  try {
    await API.patch(`admin/coupons/${data._id}`, {
      json: {
        limit: parseInt(form.limit, 10),
      },
    }).json()
    dispatch("dataUpdated")
    dispatch("close")
  } catch (err) {
    errors = await handleErrors({ err })
  }
}

const isValidThreshold = (value) =>
  data && data.limit && parseInt(value, 10) >= parseInt(data.qtyUsed, 10)
</script>

<div class="background-modal">
  <div class="modal w-96">
    <h2 class="mb-8 h2">Edit coupon limit</h2>
    <form on:submit|preventDefault="{submit}">
      <TextInput
        bind:value="{form.limit}"
        label="Limit"
        name="limit"
        errors="{errors && errors.limit}"
        class="mb-4"
        type="number"
      />
      <span
        class="text block text-xs italic"
        class:invisible="{isValidThreshold(form.limit)}"
        >Value must not be less than {data.qtyUsed}</span
      >
      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button
          disabled="{!isValidThreshold(form.limit)}"
          type="submit"
          class="btn btn-solid"
          class:opacity-50="{!isValidThreshold(form.limit)}">Save</button
        >
      </div>
    </form>
  </div>
</div>
