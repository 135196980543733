<script>
import TemplateString from "@shared/components/TemplateString.svelte"

export let label = ""
export let exposeLineBreaks = false
let value = ""
export { value as data }
</script>

<div class="{$$props.className || ''}">
  <div class="text-sm font-semibold text-slate-900 dark:text-white">
    {label}
  </div>
  <div
    class="text-base text-gray-600 dark:text-white"
    class:whitespace-pre-wrap="{exposeLineBreaks}"
  >
    {#if value}
      <TemplateString content="{value}" />
    {:else}--{/if}
  </div>
</div>
