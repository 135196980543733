<script>
import { Query } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import ListCommunications from "@local/components/ListCommunications.svelte"
import BackBar from "@local/components/BackBar.svelte"
import * as transformCommunications from "@local/utils/transformCommunications"

export let wfId

let showSMS = true
let showCall = true
let typesSelected = []

$: {
  typesSelected = []
  if (showSMS) {
    typesSelected.push("sms")
  }
  if (showCall) {
    typesSelected.push("call")
  }
}

async function fetchNotes({ wfId }) {
  const searchParams = { role: "admin" }
  const response = await API.get(`admin/workflow/${wfId}/communications`, {
    searchParams,
  })
  const rows = await response.json()
  return { rows }
}

$: queryOptions = {
  queryKey: ["communications", wfId],
  queryFn: () => fetchNotes({ wfId }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

<Query options="{queryOptions}">
  <div slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <BackBar on:navigateBack="{() => history.back()}" />
      <div class="p-5">
        <div class="flex flex-1 items-center mb-8">
          <h1 class="text-lg lg:text-2xl font-semibold text-slate-900">
            Communication Events
          </h1>
        </div>

        <div class="flex flex-1 items-center">
          <p>Filter by type:</p>
          <div class="ml-8 font-semibold select-none">
            <input type="checkbox" id="call" bind:checked="{showCall}" />
            <label class="ml-2" for="call">Calls</label>
          </div>

          <div class="ml-8 font-semibold select-none">
            <input type="checkbox" id="sms" bind:checked="{showSMS}" />
            <label class="ml-2" for="sms">SMSs</label>
          </div>
        </div>
        {#if queryResult.data.rows.length > 0}
          <div class="md:w-1/2">
            <ListCommunications
              content="{transformCommunications.toAllCommunications(
                queryResult.data.rows
              )}"
              reduced="{false}"
              typesToShow="{typesSelected}"
              emptyListMessage="no events found"
            />
          </div>
        {/if}
      </div>
    {/if}
  </div>
</Query>
