<script>
export let label
export let placeholder = ""
export let value
export let rows
export let name

export let errors = []
$: hasError = errors && errors.length > 0

export let inputEl
$: inputEl

function input(evt) {
  value = evt.target.value
}
</script>

<label class="block cursor-pointer {$$props.class || 'w-full'}">
  {#if label}
    <span class="block mb-2" class:error="{hasError}">{label}</span>
  {/if}
  <textarea
    name="{name}"
    value="{value}"
    placeholder="{placeholder}"
    rows="{rows}"
    bind:this="{inputEl}"
    on:input="{input}"
    class="w-full h-full input"
    class:with-error="{hasError}"
    on:change></textarea>

  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</label>
