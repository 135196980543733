<script>
import { useQuery } from "@sveltestack/svelte-query"

import Render from "@local/components/engine/Render.svelte"
import API from "@local/utils/api"
import BackBar from "@local/components/BackBar.svelte"

export let userId

async function fetchUserViews({ userId }) {
  const role = ["role", "admin"]
  const searchParams = [role]

  return await API.get(`admin/users/${userId}/views/csUser`, {
    searchParams,
  }).json()
}
const queryResult = useQuery(["userId", userId], () =>
  fetchUserViews({ userId })
)
</script>

{#if $queryResult.isLoading}
  <div></div>
{:else if $queryResult.error}
  <div>{$queryResult.error}</div>
{:else}
  <BackBar
    on:navigateBack="{() => {
      history.back()
    }}"
  />

  <div class="p-5">
    {#each $queryResult.data.elements as element}
      <Render element="{element}" />
    {/each}
  </div>
{/if}
