<style>
</style>

<script>
import Icon from "@local/components/images/Icon.svelte"
</script>

<button type="button" class="focus:outline-none" on:click>
  <Icon>
    <slot />
  </Icon>
</button>
