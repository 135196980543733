<style>
.open {
  @apply flex;
}
.closed {
  @apply hidden;
}
</style>

<script>
import { onDestroy } from "svelte"
import Video from "twilio-video"
import { userId, firstName, leave } from "@local/store/quickVideo"

import { ready } from "@shared/store/devices"
import BtnIcon from "@local/components/BtnIcon.svelte"
import Icon from "@local/components/images/Icon.svelte"
import Clock from "@local/components/Clock.svelte"
import API from "@local/utils/api"
import Participant from "./Participant.svelte"

let participants = []
let room = null
let myVideoTrack, myAudioTrack
let myVideoEnabled = true
let myAudioEnabled = true

let open = true
$: closed = !open

async function onReady(ready, roomId) {
  if (ready && roomId) {
    let { accessToken } = await API.post(
      `video-rooms/${roomId}/access-tokens`
    ).json()

    let myTracks = await Video.createLocalTracks()

    myVideoTrack = myTracks.find(({ kind }) => kind === "video")
    myAudioTrack = myTracks.find(({ kind }) => kind === "audio")

    room = await Video.connect(accessToken, {
      name: roomId,
      tracks: myTracks,
    })

    participants = Array.from(room.participants.values())
    room.on("participantConnected", (participant) => {
      participants = [...participants, participant]
    })
    room.on("participantDisconnected", (participant) => {
      participants = participants.filter((p) => p !== participant)
    })
  }
}

function toggleAudio() {
  if (myAudioEnabled) {
    myAudioTrack.disable()
    myAudioEnabled = false
  } else {
    myAudioTrack.enable()
    myAudioEnabled = true
  }
}

function toggleVideo() {
  if (myVideoEnabled) {
    myVideoTrack.disable()
    myVideoEnabled = false
  } else {
    myVideoTrack.enable()
    myVideoEnabled = true
  }
}

async function disconnect() {
  if (room) {
    myVideoTrack.stop()
    myAudioTrack.stop()
    room.removeAllListeners()
    room.disconnect()
    room = null
    participants = []
  }
  leave()
}

$: onReady($ready, $userId)

onDestroy(async () => {
  await disconnect()
})
</script>

<div
  class="flex items-center justify-between px-4 py-4 text-white bg-gray-800 cursor-pointer"
  on:click="{() => (open = !open)}"
>
  <div>
    {$firstName}'s Room

    <Clock />
  </div>
  <BtnIcon>
    {#if open}expand_more{:else}expand_less{/if}
  </BtnIcon>
</div>
<div class="flex-col" class:open class:closed>
  {#if room !== null}
    <Participant participant="{room.localParticipant}" />
    {#each participants as participant}
      <Participant participant="{participant}" />
    {/each}
  {/if}
  <div class="flex items-center justify-center py-3 space-x-3 bg-gray-800">
    <button
      type="button"
      class="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-full focus:outline-none hover:bg-gray-500"
      on:click="{toggleAudio}"
    >
      <Icon>{myAudioEnabled ? 'mic' : 'mic_off'}</Icon>
    </button>
    <button
      type="button"
      class="flex items-center justify-center w-10 h-10 text-white bg-red-500 rounded-full focus:outline-none hover:bg-red-600"
      on:click="{disconnect}"
    >
      <Icon>call_end</Icon>
    </button>
    <button
      type="button"
      class="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-full focus:outline-none hover:bg-gray-500"
      on:click="{toggleVideo}"
    >
      <Icon>{myVideoEnabled ? 'videocam' : 'videocam_off'}</Icon>
    </button>
  </div>
</div>

<svelte:window on:beforeunload="{disconnect}" />
