<script>
import { VerticalTabs, TabList, VerticalTabPanel, VerticalTab } from "./_Tabs"
import Render from "@local/components/engine/Render.svelte"

export let tabs = undefined
export let className = ""
</script>

<VerticalTabs>
  <TabList
    className="flex flex-col space-y-5 tab-list dark:bg-secondary-dark {className}"
  >
    {#each tabs as tab}
      <VerticalTab label="{tab.label}" icon="{tab.icon}" />
    {/each}
  </TabList>
  {#each tabs as tab}
    <VerticalTabPanel
      className="flex-1 border bg-white dark:bg-secondary-dark dark:border-tertiary-dark p-8"
    >
      {#each tab.elements as element}
        <Render element="{element}" />
      {/each}
    </VerticalTabPanel>
  {/each}
</VerticalTabs>
