<style>
.card-general,
.card-default {
  @apply bg-blue-200 text-black;
}
.card-sms {
  @apply bg-teal-200 text-black;
}
.card-noshow {
  @apply bg-red-200	text-black;
}
.card-followupcall {
  @apply bg-orange-200	text-black;
}
</style>

<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import Icon from "@local/components/images/Icon.svelte"
import NotesModal from "./NotesModal.svelte"
import Breadcrumb from "@local/components/engine/elements/Breadcrumb.svelte"
import Select from "@local/components/form/Select.svelte"
import { currentUser } from "@local/store/auth"

const queryClient = useQueryClient()
const types = ["All Types", "General", "SMS", "No Show", "Follow Up Call"]

export let wfId

let modal
let originalNote
let breadcrumbItems = []
let currentUserId = $currentUser.id
let currentUserRoleLevel = $currentUser.roleLevel
let optionType = "All Types"

async function fetchNotes({ wfId }) {
  const searchParams = { role: "admin", typeFilter: optionType }
  const response = await API.get(`admin/workflow/${wfId}/notes`, {
    searchParams,
  })?.json()
  const rows = (await response.notes) || []

  breadcrumbItems = [
    { label: "Home", url: "/", icon: "home" },
    {
      label: "Workflows",
      url: "/workflows?type=medical",
      icon: "medical_information",
    },
    {
      label: `${response?.name || ""}`,
      url: `/workflows/${wfId}`,
      icon: "person",
    },
    { label: "Internal Notes" },
  ]
  return { rows: rows.filter((x) => !x.disabled) }
}

async function deleteNote(note) {
  const confirmation = confirm(
    "Are you sure you want to remove this internal note?"
  )
  if (!confirmation) {
    return
  }

  try {
    await API.delete(`admin/workflow/${wfId}/notes/${note.noteIndex}`, {
      json: { note },
      searchParams: { role: "admin" },
    }).json()

    queryClient.invalidateQueries("internalNotes")
  } catch (err) {
    window.alert("Only the author can remove this note.")
    console.log({ err })
  }
}

async function pin(note) {
  const confirmation = confirm(
    "Are you sure you want to pin this internal note?"
  )
  if (!confirmation) return
  await updatePin(note, true)
}

async function unpin(note) {
  const confirmation = confirm(
    "Are you sure you want to unpin this internal note?"
  )
  if (!confirmation) return
  await updatePin(note, false)
}

async function updatePin(note, pin) {
  try {
    await API.patch(`admin/workflow/${wfId}/notes/${note.noteIndex}`, {
      json: { originalNote: note, pin },
      searchParams: { role: "admin" },
    })

    queryClient.invalidateQueries("internalNotes")
  } catch (err) {
    console.log({ err })
  }
}

async function openNotesModal(note) {
  originalNote = note
  modal = true
}

$: queryOptions = {
  queryKey: ["internalNotes", wfId, optionType],
  queryFn: () => fetchNotes({ wfId, optionType }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

{#if modal}
  <NotesModal
    note="{originalNote}"
    on:modal="{() => queryClient.invalidateQueries('internalNotes')}"
    on:close="{() => (modal = undefined)}"
    wfId="{wfId}"
  />
{/if}
<Query options="{queryOptions}">
  <div slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="p-5">
        <Breadcrumb items="{breadcrumbItems}" />
        <div class="flex flex-1 items-center">
          <h1 class="text-lg lg:text-2xl font-semibold text-slate-900 mb-4">
            Internal Notes
          </h1>
        </div>
        <div class="flex items-end mb-4">
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white hover:bg-slate-900 text-slate-900 hover:text-white border border-gray-200 shadow-sm focus:outline-none mr-4"
            on:click="{() => openNotesModal()}"
            ><Icon size="3xl">post_add</Icon></button
          >
          <Select
            placeholder="Type"
            name="type"
            bind:value="{optionType}"
            options="{types}"
            class="w-full mx-2"
          />
        </div>
        {#if queryResult.data.rows.length > 0}
          {#each queryResult.data.rows as row}
            <div class="rounded-xl border p-5 shadow-md w-full bg-white mb-8">
              <div
                class="flex w-full items-center justify-between border-b pb-3 mb-4"
              >
                <div class="flex items-center space-x-8 mr-8">
                  <span
                    class="card-{row.type
                      ?.toLowerCase()
                      ?.replaceAll(
                        ' ',
                        ''
                      )} text-base px-2.5 py-0.5 my-auto rounded"
                  >
                    {row.type}
                  </span>
                  <div class="flex flex-row">
                    {#if row.reminder}
                      <div class="mr-2">
                        <Icon size="lg">notifications_active</Icon>
                      </div>
                      <div class="pt-1 pr-4">
                        {row.reminder}
                      </div>
                    {/if}
                    {#if row.period}
                      <div class="pt-1">
                        {row.period}
                      </div>
                    {/if}
                  </div>
                </div>
                <div class="flex items-center space-x-3">
                  <div
                    class="italic text-right text-xs md:text-sm lg:text-base text-slate-900"
                  >
                    {row.authorName}
                    @
                    <TemplateString
                      content="{`{{${row.date}||formatDateTime}}`}"
                    />
                  </div>
                </div>
              </div>
              {#if row.outcome}
                <div
                  class="text-xl font-semibold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200"
                >
                  {row.outcome}
                </div>
              {/if}
              <div class="mt-2 text-gray-600 dark:text-gray-300">
                {row.message}
              </div>
              <div class="flex flex-row justify-end">
                {#if !row.pin}
                  <div
                    class="opacity-50 hover:opacity-100 cursor-pointer ml-2 mr-4 pt-2"
                    on:click="{() => pin(row)}"
                  >
                    <img src="/images/pin.png" alt="pin" />
                  </div>
                {/if}
                {#if row.pin && currentUserRoleLevel < 2}
                  <div
                    class="opacity-50 hover:opacity-100 cursor-pointer ml-2 mr-4 pt-2"
                    on:click="{() => unpin(row)}"
                  >
                    <img src="/images/unpin.png" alt="unpin" />
                  </div>
                {/if}
                {#if row?.authorId === currentUserId || currentUserRoleLevel < 2}
                  <div
                    class="opacity-50 icon-{row.type?.toLowerCase()} hover:opacity-100 cursor-pointer mr-2"
                    on:click="{() => openNotesModal(row)}"
                  >
                    <Icon size="2xl">edit</Icon>
                  </div>
                {/if}
                {#if row?.authorId === currentUserId || currentUserRoleLevel < 2}
                  <div
                    class="opacity-50 icon-{row.type?.toLowerCase()} hover:opacity-100 cursor-pointer"
                    on:click="{() => deleteNote(row)}"
                  >
                    <Icon size="2xl">close</Icon>
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        {:else}
          <div class="flex items-center justify-center mt-16">
            <h2 class="h2">No records</h2>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</Query>
