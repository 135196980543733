<script>
import { payload } from "@local/store/engine"
export let name
export let label
export let placeholder = ""
export let options = []
export let includeEmpty = false
export let errors = []

let _options = []

$: options, optionsChanged(options)
$: hasError = errors && errors.length > 0

function optionsChanged(options) {
  _options = options
  if (
    !options.some(
      (x) => (x.value !== undefined ? x.value : x) === $payload[name]
    )
  ) {
    $payload[name] = includeEmpty
      ? ""
      : _options[0].value !== undefined
      ? _options[0].value
      : _options[0]
  }
}
</script>

<label class="block cursor-pointer {$$props.className || 'w-full'}">
  {#if label}
    <span class="block mb-2" class:error="{hasError}">{label}</span>
  {/if}
  <select
    id="{name}"
    name="{name}"
    bind:value="{$payload[name]}"
    placeholder="{placeholder}"
    class="w-full input"
    class:with-error="{hasError}"
  >
    {#if includeEmpty}
      <option value></option>
    {/if}
    {#each _options as option, i}
      <option value="{option.value !== undefined ? option.value : option}">
        {option.label || option}
      </option>
    {/each}</select
  >
  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</label>
