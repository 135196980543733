<script>
import Render from "@local/components/engine/Render.svelte"

export let elements = []
</script>

<div class="{$$props.className || ''}" class:hidden="{elements.length === 0}">
  {#each elements as element}
    <Render element="{element}" />
  {/each}
</div>
