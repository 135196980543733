<script>
import IMask from "imask"

export let type = "text"
export let label
export let placeholder = ""
export let value
export let errors = []
export let mask = undefined

let inputEl
let imask
let isPasswordVisible = false

$: hasError = errors && errors.length > 0
$: inputEl, mask, updateMask()

function updateMask() {
  if (!mask || !inputEl) {
    imask && imask.destroy()
    return
  }
  if (imask) {
    imask.updateOptions({ mask })
    return
  }
  imask = new IMask(inputEl, { mask })
  imask.on("accept", () => (value = imask.value))
}

function input(evt) {
  value = evt.target.value
}

function togglePasswordVisibility() {
  if (type === "password") {
    isPasswordVisible = !isPasswordVisible
  }
}
</script>

<label class="block cursor-pointer {$$props.class || 'w-full'}">
  {#if label}
    <span class="block mb-2 text" class:error="{hasError}">{label}</span>
  {/if}
  <div class="relative">
    <input
      type="{isPasswordVisible && type === 'password' ? 'text' : type}"
      value="{value}"
      placeholder="{placeholder}"
      bind:this="{inputEl}"
      on:input="{!mask && input}"
      class="w-full input"
      class:with-error="{hasError}"
      on:change
    />
    {#if type === "password"}
      <button
        type="button"
        class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
        on:click="{togglePasswordVisibility}"
      >
        <i class="material-symbols-outlined text-sm align-middle	"
          >{isPasswordVisible ? "visibility_off" : "visibility"}</i
        >
      </button>
    {/if}
  </div>

  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</label>
