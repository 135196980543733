<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import Pager from "@local/components/Pager.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import EditLimitModal from "./EditLimitModal.svelte"

const queryClient = useQueryClient()

let perPage = 20
let page = 0
let query = ""
let queryVal = ""

let modalContext = {
  show: false,
  data: null,
}

$: query, (page = 0)

function search() {
  query = queryVal
}

async function fetchCoupons(query, page) {
  const searchParams = { page, perPage, query }
  const response = await API.get(`admin/coupons`, { searchParams })
  const rows = await response.json()
  const total = response.headers.get("x-total-count")
  const hasNextPage = (page + 1) * perPage < total
  return { rows, hasNextPage, query }
}

$: queryOptions = {
  queryKey: ["coupons", page, query],
  queryFn: () => fetchCoupons(query, page),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}

function clearFilters() {
  queryVal = ""
  search()
}

function transformPercent(value) {
  const valueAsNumber = parseFloat(value)

  if (isNaN(valueAsNumber)) {
    return value
  }

  return valueAsNumber * 100
}
</script>

{#if modalContext.show}
  <EditLimitModal
    data="{modalContext.data}"
    on:dataUpdated="{() => queryClient.invalidateQueries('coupons')}"
    on:close="{() => (modalContext.show = undefined)}"
  />
{/if}

<Query options="{queryOptions}">
  <div
    class="flex flex-col justify-between gap-2 p-5"
    slot="query"
    let:queryResult
  >
    {#if queryResult.isLoading}
      <h2 class="h2">Coupons</h2>
      <div>Loading coupons ...</div>
    {/if}
    {#if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {/if}
    {#if queryResult.data}
      <h2 class="h2">
        {#if queryResult.data.query}
          Coupons Search: "{queryResult.data.query}"
        {:else}Coupons{/if}
      </h2>
      <div class="flex items-center gap-2 mb-2">
        <Pager
          bind:page="{page}"
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
        <form
          class="flex items-center w-full"
          on:submit|preventDefault="{search}"
        >
          <TextInput
            bind:value="{queryVal}"
            class="w-full"
            placeholder="Search by Coupon Code"
          />
          <button
            title="Clear filters"
            class="flex items-center justify-center w-10 h-10 ml-2 bg-white border border-gray-200 shadow-sm focus:outline-none"
            on:click="{clearFilters}"
            type="button"
          >
            <Icon size="3xl">clear</Icon>
          </button>
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 ml-2 bg-white border border-gray-200 shadow-sm focus:outline-none"
            type="submit"><Icon size="3xl">search</Icon></button
          >
        </form>
      </div>
      <table class="table">
        <thead>
          <th>Code</th>
          <th>Qty. Used / Limit</th>
          <th>Value</th>
          <th>Created At</th>
        </thead>
        <tbody>
          {#each queryResult.data.rows as row}
            <tr>
              <td>{row.code}</td>
              <td>
                <div
                  class="flex w-max items-center gap-2 px-4 py-2 font-semibold text-sm border-2 border-cyan-200 rounded-full shadow-sm"
                  class:border-cyan-400="{row.limit > 0}"
                >
                  {row.qtyUsed} /
                  {#if row.limit === 0}
                    No limit
                  {:else}
                    {row.limit}
                    <button
                      type="button"
                      class="focus:outline-none"
                      on:click="{() =>
                        (modalContext = { show: true, data: row })}"
                    >
                      <span class="px-2 py-1 -mr-2 rounded-full bg-gray-100"
                        >&#10010;</span
                      >
                    </button>
                  {/if}
                </div>
              </td>
              <td>
                {#if row.discountType === "flat"}
                  ${row.value}
                {:else}
                  {transformPercent(row.value)}%
                {/if}
              </td>
              <td>
                {#if row.createdAt}
                  <TemplateString
                    content="{`{{${row.createdAt}||formatDateTime}}`}"
                  />
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/if}
  </div>
</Query>
