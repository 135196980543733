import {
  differenceInMinutes, getMinutes, subSeconds, isAfter,
} from "date-fns"

function calculateScheduleStyles({ startsAt, endsAt, column }, columns) {
  const interval = differenceInMinutes(Date.parse(endsAt), Date.parse(startsAt))
  const minutes = getMinutes(Date.parse(startsAt))
  const top = `calc(${minutes / 60} * 9rem)`
  const height = `calc(${interval / 60} * 9rem)`

  return `top: ${top}; height: ${height}; width: calc(100% / ${columns} - 1rem); left: calc(100% / ${columns} * ${column})`
}


function findTagColumnAvailableToSchedule(allocatedColumns, scheduleStartsAt, scheculeEndsAt) {
  let column = 0

  while (isAfter(subSeconds(allocatedColumns[column], 1), scheduleStartsAt)) {
    column++
  }

  if (
    !allocatedColumns[column] ||
    isAfter(scheculeEndsAt, allocatedColumns[column])
  ) {
    allocatedColumns[column] = scheculeEndsAt
  }

  return column
}

function getTagColumnsAmount(tagHours) {
  let columnsTag = 1

  tagHours.forEach((hour) => {
    const maxColumnHour = hour.reduce((columns, schedule) => schedule.column >= columns ? schedule.column + 1 : columns, 1)

    if (maxColumnHour > columnsTag) {
      columnsTag = maxColumnHour
    }
  })

  return columnsTag
}

export { calculateScheduleStyles, findTagColumnAvailableToSchedule, getTagColumnsAmount }
