<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import Pager from "@local/components/Pager.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import RefundPaymentModal from "./RefundPaymentModal.svelte"

const queryClient = useQueryClient()

let refundModal

let perPage = 20
let page = 0
let query = ""
let queryVal = ""

$: query, (page = 0)

function search() {
  query = queryVal
}

async function fetchPayments(query, page) {
  const searchParams = { page, perPage, query }
  const response = await API.get(`admin/payments`, { searchParams })
  const rows = await response.json()
  const total = response.headers.get("x-total-count")
  const hasNextPage = (page + 1) * perPage < total
  return { rows, hasNextPage, query }
}

$: queryOptions = {
  queryKey: ["payments", page, query],
  queryFn: () => fetchPayments(query, page),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}

function refund(row) {
  refundModal = row
}
</script>

{#if refundModal}
  <RefundPaymentModal
    payment="{refundModal}"
    on:refundFinished="{() => queryClient.invalidateQueries('payments')}"
    on:close="{() => (refundModal = undefined)}"
  />
{/if}
<Query options="{queryOptions}">
  <div class="p-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-6">
        <h2 class="h2">
          {#if queryResult.data.query}
            Payments Search: "{queryResult.data.query}"
          {:else}Payments{/if}
        </h2>
      </div>
      <div class="flex items-center gap-2 mb-2">
        <Pager
          bind:page
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
        <form
          class="flex items-center w-full"
          on:submit|preventDefault="{search}"
        >
          <TextInput
            bind:value="{queryVal}"
            class="w-full"
            placeholder="Search by Name or Email or Status or Transaction ID"
          />
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 ml-2 bg-white border border-gray-200 shadow-sm focus:outline-none"
            type="submit"
          ><Icon size="3xl">search</Icon></button>
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <table class="table">
          <thead>
            <th>Status</th>
            <th>Transaction ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Amount</th>
            <th>Coupon</th>
            <th>Refunded Amount</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th></th>
          </thead>
          <tbody>
            {#each queryResult.data.rows as row}
              <tr>
                <td>{row.status}</td>
                <td>{row.transaction}</td>
                <td>{row.fullName}</td>
                <td>{row.email}</td>
                <td>${row.amount}</td>
                <td>
                  {#if row.coupon}
                    {row.coupon.code}
                    / ${row.coupon.value.toFixed(0)}
                  {/if}
                </td>
                <td>${row.refundedAmount}</td>
                <td>
                  <TemplateString
                    content="{`{{${row.createdAt}||formatDateTime}}`}"
                  />
                </td>
                <td>
                  <TemplateString
                    content="{`{{${row.updatedAt}||formatDateTime}}`}"
                  />
                </td>
                <td class="text-right">
                  <button
                    type="button"
                    class="focus:outline-none"
                    on:click="{() => refund(row)}"
                  ><Icon>keyboard_return</Icon></button>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    {/if}
  </div>
</Query>
