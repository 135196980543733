<script>
import IMask from "imask"
import { payload, errors as _errors } from "@local/store/engine"

export let label = ""
export let placeholder = ""
export let name = ""
let value = $payload[name]
export let mask = undefined
export let maskType = undefined
export let maskData = undefined
export let errors = []
$: errors = $_errors[name] || []
$: hasError = errors && errors.length > 0

const masks = {
  creditCardCvv: {
    mask: "0000",
  },
  creditCardExpiration: {
    mask: "00/00",
  },
  creditCardNumber: {
    mask: [
      {
        mask: "0000 0000 0000 0000",
        regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
        maskData: "mastercard",
      },
      {
        mask: "0000 0000 0000 0000",
        regex: "^(6011\\d{0,12})|(65\\d{0,14})",
        maskData: "discover",
      },
      {
        mask: "0000 0000 0000 0000",
        regex: "^4\\d{0,15}",
        maskData: "visa",
      },
      {
        mask: "0000 0000 0000 0000",
        maskData: undefined,
      },
    ],
    dispatch(appended, dynamicMasked) {
      const number = (dynamicMasked.value + appended).replace(/\D/g, "")

      for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
        const re = new RegExp(dynamicMasked.compiledMasks[i].regex)
        if (number.match(re) != null) {
          return dynamicMasked.compiledMasks[i]
        }
      }
    },
  },
}

let inputEl
let imask
$: handleMaskOpts(inputEl, maskType)
function handleMaskOpts(inputEl, maskType) {
  if ((!mask && !maskType) || !inputEl) return imask && imask.destroy()
  if (imask) return imask.updateOptions(mask ? { mask } : masks[maskType])
  imask = new IMask(inputEl, mask ? { mask } : masks[maskType])
  imask.on("accept", accept)
}

function accept() {
  $payload[name] = imask.value
  maskData = imask.masked.currentMask ? imask.masked.currentMask.maskData : {}
}

function input(evt) {
  if (!imask) {
    $payload[name] = evt.target.value
  }
}
</script>

<div class="{$$props.className || $$props.class || ''}">
  <span class="block mb-2" class:error="{hasError}">{label}</span>
  <input
    on:input="{input}"
    bind:this="{inputEl}"
    bind:value="{value}"
    class="w-full input"
    class:with-error="{hasError}"
    type="text"
    name="{name}"
    placeholder="{placeholder}"
    id="{name}"
  />
  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</div>
