<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import { link } from "svelte-routing"
import Pager from "@local/components/Pager.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import CreateCallOutcomeModal from "@local/components/CreateCallOutcomeModal.svelte"
import MultiSelect from "@local/components/MultiSelect.svelte"
import {
  setDataToLocalStorage,
  getDataFromLocalStorage,
} from "@local/utils/localStorage"

const queryClient = useQueryClient()
const localStorageKey = "whoToCall"
const reasonOptions = [
  "Expiring 60 Days",
  "Expiring 30 Days",
  "Nearly Expired",
  "Lapsed 30 Days",
  "Lapsed 60 Days",
  "Lapsed 120 Days",
  "First Follow-up",
  "10-day Follow-up",
  "30-day Follow-up",
]
const subdomainsOptions = [
  "starseed",
  "mymedi",
  "pearson",
  "aurora",
  "tilray",
  "illinois",
  "newyork",
  "ohio",
  "pennsylvania",
  "seniorcareroc",
  "massageaddict",
  "mendo",
  "herbaldispatch",
  "tantus",
  "cannafarms",
  "cccc",
  "ccct",
]
const tagOptions = [
  "Platinum",
  "Gold",
  "Silver",
  "Bronze",
  "Limited Purchase",
  "No Purchase",
]

let perFilter = 20
let showCallOutcomeModal = null
let storedFilters = getDataFromLocalStorage(localStorageKey)
let query = storedFilters?.query || ""
let queryVal = storedFilters?.query || ""
let reasons = storedFilters?.reasons || []
let reasonsVal = storedFilters?.reasons || []
let subdomains = storedFilters?.subdomains || []
let subdomainsVal = storedFilters?.subdomains || []
let tags = storedFilters?.tags || []
let tagsVal = storedFilters?.tags || []
let page = storedFilters?.page || 0
let wfId

function search(pageNumber) {
  page = pageNumber
  query = queryVal
  reasons = reasonsVal
  subdomains = subdomainsVal
  tags = tagsVal
}

async function fetchWhoToCall(page, query, reasons, subdomains, tags) {
  const searchParams = { page, perFilter, query, reasons, subdomains, tags }
  const response = await API.get(`admin/who-to-call`, { searchParams })
  const rows = await response.json()
  const hasNextPage = rows.length > 0
  const count = parseInt(response.headers.get("X-Total-Count"))

  setDataToLocalStorage(localStorageKey, {
    page,
    query,
    reasons,
    subdomains,
    tags,
  })
  return { rows, hasNextPage, query, count }
}

function clearFilters() {
  queryVal = ""
  reasonsVal = []
  subdomainsVal = []
  tagsVal = []
  queryClient.invalidateQueries("whoToCallFilters")
  search(0)
}

async function handleUpload(evt = {}) {
  const inputFiles = evt.target.files

  const reader = new FileReader()
  reader.onload = (readerEvent) => {
    const file = {
      src: readerEvent.target.result,
      name: inputFiles[0].name,
    }

    API.patch(`admin/who-to-call`, { json: { csvBase64: file.src } })
      .then(() => {
        queryClient.invalidateQueries()
        alert("DONE UPDATING RECORDS")
      })
      .catch((err) => alert(`FAILED UPDATING RECORDS WITH ERROR ${err}`))
  }

  reader.readAsDataURL(inputFiles[0])
}

async function handleDel(id) {
  API.delete(`admin/who-to-call/${id}`)
    .then(() => {
      queryClient.invalidateQueries()
    })
    .catch((err) => alert(`FAILED REMOVING RECORD WITH ERROR ${err}`))
}

$: queryOptions = {
  queryKey: ["whoToCall", page, query, reasons, subdomains, tags],
  queryFn: () => fetchWhoToCall(page, query, reasons, subdomains, tags),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

{#if showCallOutcomeModal}
  <CreateCallOutcomeModal
    wfId="{wfId}"
    on:success="{() => queryClient.invalidateQueries('whoToCall')}"
    on:close="{() => (showCallOutcomeModal = null)}"
  />
{/if}

<Query options="{queryOptions}">
  <div class="p-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-6">
        <h2 class="h2">
          {#if queryResult.data.query || reasons.length > 0}
            Who to call Search: "{queryResult.data.query}
            {subdomainsVal}"
          {:else}Who to call{/if}
          - {queryResult.data.count} results found
        </h2>
      </div>
      <div class="flex w-full items-center gap-2 mb-2">
        <Pager
          bind:page="{page}"
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
        <form
          class="flex items-center w-full"
          on:submit|preventDefault="{() => search(0)}"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 cursor-pointer btn btn-outline relative"
          >
            <input
              type="file"
              class="opacity-0 w-full h-full absolute top-0 left-0"
              on:change="{handleUpload}"
            />
            <Icon size="3xl">upload_file</Icon>
          </div>
          <!-- <TextInput
            bind:value="{queryVal}"
            className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
            type="search"
            placeholder="Search by Full Name or Preferred Name or Email or Partner Origin"
          /> -->
          {#key subdomainsVal.length === 0}
            <MultiSelect
              className="w-full md:ml-2 mt-2 md:mt-0"
              bind:value="{subdomainsVal}"
              placeholder="Partners"
            >
              {#each subdomainsOptions as subdomain}
                <option value="{subdomain}">{subdomain}</option>
              {/each}
            </MultiSelect>
          {/key}
          {#key reasonsVal.length === 0}
            <MultiSelect
              className="w-full md:ml-2 mt-2 md:mt-0"
              bind:value="{reasonsVal}"
              placeholder="Call Tags"
            >
              {#each reasonOptions as reason}
                <option value="{reason}">{reason}</option>
              {/each}
            </MultiSelect>
          {/key}
          {#key tagsVal.length === 0}
            <MultiSelect
              className="w-full md:ml-2 mt-2 md:mt-0"
              bind:value="{tagsVal}"
              placeholder="Purchase Tags"
            >
              {#each tagOptions as tag}
                <option value="{tag}">{tag}</option>
              {/each}
            </MultiSelect>
          {/key}
          <button
            title="Clear filters"
            class="flex items-center justify-center w-10 h-10 ml-2 btn btn-outline"
            on:click="{clearFilters}"
            type="button"
          >
            <Icon size="3xl">clear</Icon>
          </button>
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 ml-2 btn btn-outline"
            type="submit"><Icon size="3xl">search</Icon></button
          >
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <table class="table">
          <thead>
            <th>Patient</th>
            <th>Phone</th>
            <th>Origin</th>
            <th>Expiration</th>
            <th>Reason</th>
            <th></th>
            <th></th>
            <th></th>
          </thead>
          <tbody>
            {#each queryResult.data.rows as row}
              <tr>
                <td>{row.name}<br /><i>{row.email}</i></td>
                <td>{row.phone}</td>
                <td>{row.subdomain}</td>
                <td>{row.expiresAt}</td>
                <td>{row.reason}<br /><i>{row.purchaseTag}</i></td>
                <td>
                  <a
                    use:link
                    href="dialpad://{row.phone
                      .replaceAll(' ', '')
                      .replaceAll('-', '')
                      .replaceAll(
                        '+',
                        ''
                      )}?launchMinimode=1&confirmPhone=1&fromNumber=+18552833985&crmLogging=0&showDispositions=1&customData={row.wfId}"
                  >
                    <Icon>phone_in_talk</Icon>
                  </a>
                </td>
                <td>
                  <button
                    type="button"
                    class="focus:outline-none"
                    on:click="{() => handleDel(row._id)}"
                  >
                    <Icon>not_interested</Icon>
                  </button>
                </td>
                <td>
                  <a use:link href="users/{row.userId}">
                    <Icon>arrow_forward</Icon>
                  </a>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {:else}
        <EmptyMessage icon="check">No workflows at the moment</EmptyMessage>
      {/if}
    {/if}
  </div>
</Query>
