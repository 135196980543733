<style>
.error {
  @apply mt-1 text-xs leading-none;
  color: #c4272a;
}
.button {
  @apply flex justify-center items-center w-10 h-10 mt-9 text-4xl rounded-sm;
}
.button.add {
  @apply text-white bg-blue-500;
}
.button.remove {
  @apply text-white bg-red-500;
}
.button.add:disabled,
.button.remove:disabled {
  @apply text-white bg-gray-500;
}
</style>

<script>
import validate from "validate.js"
import { createEventDispatcher } from "svelte"
import API, { handleErrors } from "@local/utils/api"
import TextInput from "@local/components/form/TextInput.svelte"
import Select from "@local/components/form/Select.svelte"
import { format, setHours, setMinutes } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import TemplateString from "../../../../../shared/components/TemplateString.svelte"

export let scheduleTags
export let timezone

function parseWithTz(date) {
  return utcToZonedTime(date, timezone)
}

const dispatch = createEventDispatcher()

validate.validators.presence.options = { message: "^Required" }
validate.validators.format.options = { message: "^Invalid" }

const dateFormat = /^\d{4}-(1[0-2]|0[1-9])-(3[0-1]|0[1-9]|[1-2][0-9])$/
const timeFormat = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/

const constraints = {
  tags: { presence: { allowEmpty: false }, length: { minimum: 1 } },
}

let preview = false
let previewSlots = []
let form = {
  dates: [
    {
      date: format(new Date(), "yyyy-MM-dd"),
      start: "09:00",
      end: "09:30",
    },
  ],
  interval: "",
  timezone,
  tags: [],
  initialStatus: "Open",
}
let errors

async function submit() {
  errors = validate(form, constraints)

  if (!errors) {
    try {
      previewSlots = await API.post(`admin/schedules`, {
        json: form,
        searchParams: { preview: !preview },
      }).json()

      if (preview) {
        dispatch("slotsCreated")
        dispatch("close")
      }

      preview = !preview
    } catch (err) {
      errors = await handleErrors({ err })
    }
  }
}

function toggleTag({ label: tag, duration }) {
  if (form.tags.includes(tag)) {
    form.tags = form.tags.filter((x) => x !== tag)
    if (form.tags.length > 0) {
      form.interval = `${
        scheduleTags.find((tag) => tag.label === form.tags[0]).duration
      }`
    } else {
      form.interval = ""
    }
  } else {
    form.interval = `${duration}`
    form.tags.push(tag)
    form.tags = form.tags
  }
}

function startTimeChanged(index) {
  return (evt) => {
    const [hours, minutes] = evt.target.value.split(":")
    form.dates[index].end = format(
      setMinutes(setHours(new Date(), hours), minutes),
      "H:mm"
    )
    form = form
  }
}

function copyDate(date) {
  const dates = form.dates
  dates.push({ ...date })
  form.dates = dates
}

function removeDate(index) {
  form.dates = [...form.dates.slice(0, index), ...form.dates.slice(index + 1)]
}
</script>

<div class="background-modal">
  <div class="modal w-2/3 max-w-3xl sm:w-3/3">
    <h2 class="mb-6 h2">Add Capacity</h2>
    <form on:submit|preventDefault="{submit}">
      {#if preview === false}
        <div class="mb-2 text">Tags</div>
        <div class="flex flex-row flex-wrap gap-2.5 mb-4">
          {#each scheduleTags as scheduleTag}
            <div
              class="flex justify-center items-center rounded-sm px-4 h-10 cursor-pointer font-semibold"
              class:opacity-50="{!form.tags.includes(scheduleTag.label)}"
              class:opacity-100="{form.tags.includes(scheduleTag.label)}"
              on:click="{() => toggleTag(scheduleTag)}"
              style="background-color: {scheduleTag.backgroundColor}; color: {scheduleTag.textColor}"
            >
              {scheduleTag.label}
            </div>
          {/each}
        </div>
        {#if errors && errors.tags}
          <div class="error">{errors.tags[0]}</div>
        {/if}
        <div class="grid grid-cols-2 gap-4 mb-4">
          <Select
            label="Status"
            bind:value="{form.initialStatus}"
            options="{['Open', 'Hidden']}"
            errors="{errors && errors.initialStatus}"
          />
          <TextInput
            bind:value="{form.interval}"
            label="Duration"
            name="interval"
            placeholder="MMM"
            mask="{`000`}"
            errors="{errors && errors.interval}"
          />
        </div>

        <div class="pb-4 overflow-y-auto max-h-80">
          {#each form.dates as _, i}
            <div class="flex flex-row items-end mb-4 gap-3">
              <div class="flex-grow flex-shrink-0 grid grid-cols-3 gap-4">
                <TextInput
                  bind:value="{form.dates[i].date}"
                  label="Date"
                  name="{`date_${i}`}"
                  type="date"
                  errors="{errors &&
                    errors.dates &&
                    errors.dates[i] &&
                    errors.dates[i].date}"
                />
                <TextInput
                  bind:value="{form.dates[i].start}"
                  label="Start"
                  name="{`start_${i}`}"
                  type="time"
                  on:change="{(evt) => startTimeChanged(i)(evt)}"
                  errors="{errors &&
                    errors.dates &&
                    errors.dates[i] &&
                    errors.dates[i].start}"
                />
                <TextInput
                  bind:value="{form.dates[i].end}"
                  label="End"
                  name="{`end_${i}`}"
                  type="time"
                  errors="{errors &&
                    errors.dates &&
                    errors.dates[i] &&
                    errors.dates[i].end}"
                />
              </div>
              <button
                type="button"
                class="btn btn-outline"
                disabled="{form.dates.length === 1}"
                on:click="{() => removeDate(i)}">-</button
              >
              <button
                type="button"
                class="btn btn-outline"
                on:click="{() => copyDate(form.dates[i])}">+</button
              >
            </div>
          {/each}
        </div>
      {:else}
        <div class="w-full overflow-y-auto max-h-72">
          <table class="table">
            <tbody>
              {#each previewSlots as slot}
                <tr>
                  <td>
                    <TemplateString
                      content="{`{{${parseWithTz(
                        slot.startsAt
                      )}||formatDateTime}}`}"
                    />
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {/if}
      <hr />
      <div class="flex justify-end gap-2 mt-8">
        {#if preview}
          <button
            type="button"
            class="btn btn-outline"
            on:click="{() => (preview = false)}">Back</button
          >
        {:else}
          <button
            type="button"
            class="btn btn-outline"
            on:click="{() => dispatch('close')}">Cancel</button
          >
        {/if}
        <button type="submit" class="btn btn-solid"
          >{#if preview}
            Confirm
          {:else}Preview{/if}</button
        >
      </div>
    </form>
  </div>
</div>
