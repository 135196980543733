<style>
.menu {
  @apply fixed text-white shadow-md h-full overflow-x-auto top-0 left-0 transition-all duration-300 ease-in-out z-50;
  @apply border border-r border-tertiary-dark min-h-screen;
}
.background {
  @apply bg-secondary-light;
}
.dark .background {
  @apply bg-secondary-dark;
}
.item {
  @apply flex cursor-pointer items-center px-4 py-2 whitespace-nowrap;
}
.item:hover {
  color: #e63c24;
}
</style>

<script>
import { link } from "svelte-routing"
import { currentUser, deauth } from "@local/store/auth"
import Logo from "@local/components/images/Logo.svelte"
import Icon from "@local/components/images/Icon.svelte"
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "@local/utils/localStorage"

const itemsSidebar = [
  { to: "/", label: "Dashboard", icon: "queue_play_next" },
  { to: "/users", label: "Users", icon: "person", accessLevel: 3 },
  {
    to: "/workflows?type=medical",
    label: "Workflows",
    icon: "medical_information",
    accessLevel: 3,
  },
  {
    to: "/workflows?type=educator",
    label: "Educator",
    icon: "history_edu",
    accessLevel: 3,
  },
  { to: "/who-to-call", label: "Who to call", icon: "call", accessLevel: 3 },
  {
    to: "/calendar",
    label: "Calendar",
    icon: "calendar_month",
    accessLevel: 3,
  },
  {
    to: "#",
    label: "Payments",
    icon: "local_atm",
    accessLevel: 1,
    subitem: [
      {
        to: "/payments",
        label: "Transactions",
        icon: "receipt_long",
        accessLevel: 1,
      },
      { to: "/coupons", label: "Coupons", icon: "percent", accessLevel: 1 },
    ],
  },
  {
    to: "#",
    label: "Reports",
    icon: "query_stats",
    accessLevel: 1,
    subitem: [
      {
        to: "/reports/post-consults",
        label: "Post Consult",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/starseed",
        label: "Starseed",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/starseed-temp",
        label: "Starseed Temp",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/purchases",
        label: "Purchases",
        icon: "plagiarism",
        accessLevel: 1,
      },
      {
        to: "/reports/mdbilling",
        label: "MD Billing",
        icon: "plagiarism",
        accessLevel: 1,
      },
    ],
  },
  {
    to: "/cancellation-list",
    label: "Cancellations",
    icon: "event_busy",
    accessLevel: 3,
  },
  {
    to: "/analytics",
    label: "Analytics",
    icon: "insert_chart_outlined",
    accessLevel: 5,
  },
]

let isSubMenuOpen = {}
let userPreference = getDataFromLocalStorage("userPreference")
let darkMode = userPreference?.darkMode || false

const toggleSubMenu = (index) => {
  isSubMenuOpen[index] = !isSubMenuOpen[index]
}

function changeViewMode() {
  darkMode = !darkMode
  setDataToLocalStorage("userPreference", { darkMode })
  window.location.reload()
}
</script>

<nav
  class="menu w-24 md:w-52 transition-all duration-300 ease-in-out"
  class:dark="{darkMode}"
>
  <div class="bg-secondary-light dark:bg-secondary-dark min-h-screen">
    <div class="py-8 px-4">
      <a href="/" use:link>
        <div class="md:hidden"><Logo size="small" /></div>
        <div class="hidden md:block"><Logo size="regular" /></div>
      </a>
    </div>

    <div class="mt-4">
      {#each itemsSidebar as item, index}
        {#if item.accessLevel && $currentUser && $currentUser.roleLevel <= item.accessLevel}
          <div class="">
            {#if item.subitem?.length > 0}
              <button
                title="{item.label}"
                class="item justify-center md:justify-start w-full"
                on:click="{() => toggleSubMenu(index)}"
              >
                <Icon size="small">{item.icon}</Icon>
                <span class="ml-2 md:inline hidden">{item.label}</span>
                <Icon size="small" class="ml-2">
                  {isSubMenuOpen[index] ? "arrow_drop_up" : "arrow_drop_down"}
                </Icon>
              </button>

              {#if isSubMenuOpen[index]}
                <div class="pl-6">
                  {#each item.subitem as subitem}
                    <div class="py-2">
                      <a
                        title="{subitem.label}"
                        class="item"
                        href="{subitem.to}"
                        use:link
                      >
                        <Icon size="xl">{subitem.icon}</Icon>
                        <span class="ml-2 md:inline hidden"
                          >{subitem.label}</span
                        >
                      </a>
                    </div>
                  {/each}
                </div>
              {/if}
            {:else}
              <a
                title="{item.label}"
                class="item justify-center md:justify-start w-full"
                href="{item.to}"
                use:link
              >
                <Icon size="xl">{item.icon}</Icon>
                <span class="ml-2 md:inline hidden">{item.label}</span>
              </a>
            {/if}
          </div>
          <hr
            class="border-t border-black border-gray-400 my-4 md:w-16 md:mx-6 w-8 mx-auto"
          />
        {/if}
      {/each}
      <div class="pt-24" on:click="{changeViewMode}">
        <button class="item justify-center md:justify-start w-full">
          {#if darkMode}
            <Icon size="xl">light_mode</Icon>
            <span class="ml-2 md:inline hidden">Light Mode</span>
          {:else}
            <Icon size="xl">dark_mode</Icon>
            <span class="ml-2 md:inline hidden">Dark Mode</span>
          {/if}
        </button>
      </div>

      <div title="Logout" class="py-4" on:click="{deauth}">
        <button class="item justify-center md:justify-start w-full">
          <Icon size="xl">logout</Icon>
          <span class="ml-2 md:inline hidden">Logout</span>
        </button>
      </div>
    </div>
  </div>
</nav>

<!-- <div class="fixed z-50 h-full pb-4 w-36 overflow-y-auto bg-slate-900">
        <div class="absolute flex flex-col flex-shrink-0 w-full">
          <a href="/" class="flex justify-center my-10" use:link>
            <Logo class="w-12" />
          </a>
      
          {#each itemsSidebar as item}
            {#if item.accessLevel && $currentUser && $currentUser.roleLevel <= item.accessLevel}
              <a class="item" href="{item.to}" use:link>
                <Icon class="text-3xl">{item.icon}</Icon>
                <span class="text-sm">{item.label}</span>
              </a>
            {/if}
          {/each}
      
          <div class="item mt-8" on:click="{deauth}">
            <Icon class="text-3xl">logout</Icon>
            <span class="text-sm">{logoutText[$workflowLang]}</span>
          </div>
        </div>
      </div> -->
