<style>
.card-general,
.card-default {
  @apply bg-blue-200 text-black;
}
.card-sms {
  @apply bg-teal-200 text-black;
}
.card-noshow {
  @apply bg-red-200	text-black;
}
.card-followupcall {
  @apply bg-orange-200	text-black;
}
</style>

<script>
import Icon from "@shared/components/Icon.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"

export let content = []
export let leftIcon = ""
export let label = ""
export let targetBlank = false
export let url = ""
export let showOpened = true
export let collapsible = false
export let classContent = ""

let collapsed = showOpened === true

$: content = content ? JSON.parse(content) : []
</script>

<div class="{$$props.className || ''}">
  <div class="flex flex-row items-center m-4 cursor-pointer">
    <a
      href="{url}"
      class="flex flex-1 items-center rounded p-2 select-none"
      target="{targetBlank ? '_blank' : '_self'}"
    >
      {#if leftIcon}
        <Icon class="text-slate-900 mr-2">{leftIcon}</Icon>
      {/if}
      <span class="h2 text-slate-900 font-semibold w-full text-base lg:text-xl"
        >{label}</span
      >
    </a>
    {#if collapsible}
      <div class="select-none" on:click="{() => (collapsed = !collapsed)}">
        <Icon class="text-gray-400 mx-2 hover:text-gray-600">
          <span class="text-3xl">
            {#if collapsed}expand_less{:else}expand_more{/if}
          </span>
        </Icon>
      </div>
    {/if}
  </div>

  <div
    class:block="{collapsible && collapsed}"
    class:hidden="{collapsible && !collapsed}"
  >
    {#if content?.length}
      <div
        class="px-4 overflow-y-auto overflow-x-hidden p-4 {classContent || ''}"
      >
        {#each content as item}
          <div class="mb-2">
            {#if item?.badge}
              {#if url}
                <a href="{url}" target="{targetBlank ? '_blank' : '_self'}">
                  <span
                    class="card-{item.badge
                      ?.toLowerCase()
                      ?.replaceAll(' ', '') ||
                      'default'} px-2.5 py-0.5 my-auto rounded"
                  >
                    {item.badge}
                  </span>
                </a>
              {:else}
                <span
                  class="card-{item.badge?.toLowerCase()?.replaceAll(' ', '') ||
                    'default'} px-2.5 py-0.5 my-auto rounded"
                >
                  {item.badge}
                </span>
              {/if}
            {/if}
            {#if item?.date}
              <span class="text-gray-400 text-sm my-1">
                <TemplateString
                  content="{`{{${item.date}||formatDateTime}}`}"
                />
              </span>
            {/if}
          </div>
          {#if item?.author}
            <p class="text-gray-600 font-semibold text-sm">{item.author}</p>
          {/if}
          {#if item?.title}
            <p class="my-2 text-sm">{item.title}</p>
          {/if}
          <p class="text-gray-600 my-2 text-sm">{item.description}</p>
          <hr class="m-4 border-gray-200" />
        {/each}
      </div>
    {:else}
      <div class="px-4 flex justify-center mt-4 pb-4">
        <p class="text-gray-600 italic">empty list</p>
      </div>
    {/if}
  </div>
</div>
