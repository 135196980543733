<script>
import { createEventDispatcher } from "svelte"

const dispatch = createEventDispatcher()

function handleBack() {
  dispatch("navigateBack")
}
</script>

<div class="bg-white w-full h-12 flex items-center relative">
  <button
    on:click="{handleBack}"
    class="mb-2 font-semibold text-gray-600 hover:text-gray-400 text-base ml-8 cursor-pointer"
  >
    &lt; Back
  </button>
</div>
