export const timezones = [...new Set([
  Intl.DateTimeFormat().resolvedOptions().timeZone,
  "America/Atikokan",
  "America/Blanc-Sablon",
  "America/Cambridge_Bay",
  "America/Creston",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Edmonton",
  "America/Fort_Nelson",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Halifax",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Moncton",
  "America/Nipigon",
  "America/Pangnirtung",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Resolute",
  "America/St_Johns",
  "America/Swift_Current",
  "America/Thunder_Bay",
  "America/Toronto",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yellowknife",
  ])
]

export const canadaTimezonesPerProvince = {
  "(Alberta) America/Edmonton": "America/Edmonton",
  "(British Columbia) America/Vancouver": "America/Vancouver",
  "(Manitoba) America/Winnipeg": "America/Winnipeg",
  "(New Brunswick) America/Moncton": "America/Moncton",
  "(Newfoundland and Labrador) America/St_Johns": "America/St_Johns",
  "(Northwest Territories) America/Yellowknife": "America/Yellowknife",
  "(Nova Scotia) America/Halifax": "America/Halifax",
  "(Nunavut) America/Iqaluit": "America/Iqaluit",
  "(Ontario) America/Toronto": "America/Toronto",
  "(Prince Edward Island) America/Halifax": "America/Halifax",
  // Quebec: "America/Blanc-Sablon",
  "(Quebec) America/Toronto": "America/Toronto",
  "(Saskatchewan) America/Regina": "America/Regina",
  "(Yukon) America/Whitehorse": "America/Whitehorse",
}

export const usTimezonesPerState = {
  "(US Illinois) America/Chicago": "America/Chicago",
  "(US Louisiana) America/Chicago": "America/Chicago",
  "(US Missouri) America/Chicago": "America/Chicago",
  "(US New York) America/New_York": "America/New_York",
  "(US Ohio) America/New_York": "America/New_York",
  "(US Pennsylvania) America/New_York": "America/New_York",
}
