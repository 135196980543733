import { writable } from "svelte/store"
import jwtDecode from "jwt-decode"
import { isPast } from "date-fns"
import { navigate } from "svelte-routing"

import API from "@local/utils/api"
import { partnerId } from "@local/utils/constants"
import {
  clearLocalStorage,
} from "@local/utils/localStorage"

const key = `hellomdToken+${partnerId}`
const isOnTwoFactorAuthKey = `hellomdIsOnTwoFactorAuth`
const partnerPath = `partners/${partnerId}/`
const calendarLocalStorageKey = "calendar"

const isLoggedIn = writable(false)
const currentUser = writable(null)
const isOnTwoFactorAuth = writable(false)

let user = null
let accessToken = null
let refreshToken = null
let refreshRequest = null

try {
  const initialState = JSON.parse(localStorage.getItem(key))

  if (initialState) {
    accessToken = initialState.accessToken
    refreshToken = initialState.refreshToken
    isLoggedIn.set(true)
    user = getUserFromJWT()
    currentUser.set(user)
    isOnTwoFactorAuth.set(Boolean(localStorage.getItem(isOnTwoFactorAuthKey)))
  }
} catch (err) {
  console.log("err", err)
}

function getUserFromJWT() {
  const decodedJWT = jwtDecode(accessToken)
  return {
    id: decodedJWT.id,
    email: decodedJWT.email,
    roles: decodedJWT.roles,
    roleLevel: decodedJWT.roleLevel,
  }
}

export function auth(tokens, isRefreshing = false) {
  localStorage.setItem(key, JSON.stringify(tokens))
  accessToken = tokens.accessToken
  refreshToken = tokens.refreshToken
  isLoggedIn.set(true)
  user = getUserFromJWT()
  currentUser.set(user)
  if (!isRefreshing) {
    localStorage.setItem(isOnTwoFactorAuthKey, true)
    isOnTwoFactorAuth.set(true)
  }
}

export function twoFactorAuthSucceed() {
  localStorage.removeItem(isOnTwoFactorAuthKey)
  isOnTwoFactorAuth.set(false)
}

export function deauth() {
  accessToken = null
  refreshToken = null
  user = null
  isLoggedIn.set(false)
  currentUser.set(null)
  isOnTwoFactorAuth.set(false)

  clearLocalStorage(calendarLocalStorageKey) //rian's comment: keeping calendar tags stored on localstorage when logout from admin

  navigate("/signin")
}

export async function refreshAccessToken() {
  if (refreshRequest) {
    return async function () {
      const [data, { status }] = await refreshRequest
      if (status === 200) return data.accessToken
      deauth()
    }
  }
  try {
    const json = { email: user.email, refreshToken }
    refreshRequest = API.post(`${partnerPath}tokens`, { json }).json()
    const tokens = await refreshRequest
    auth(tokens, true)
  } catch (err) {
    deauth()
    refreshRequest = null
  }
}

export async function getAccessToken() {
  if (!accessToken) return null
  const expiresAt = new Date(jwtDecode(accessToken).exp * 1000)
  if (isPast(expiresAt)) await refreshAccessToken()
  return accessToken
}

export { currentUser, isLoggedIn, isOnTwoFactorAuth }
