<style>
.open {
  @apply text-green-800  bg-green-200;
}
._hidden {
  @apply text-blue-800 bg-blue-200;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

import API from "@local/utils/api"
import Icon from "@local/components/images/Icon.svelte"
import { currentUser } from "@local/store/auth"

import { calculateScheduleStyles } from "./utils"
import { slotTypes } from "./constants"

const dispatch = createEventDispatcher()

export let columns = undefined
export let column = undefined
export let _id = undefined
export let status = undefined
export let startsAt = undefined
export let endsAt = undefined
export let timeZone = undefined
export let checked = false
export let checkboxVisible = true

$: open = status === "Open"
$: _hidden = status === "Hidden"

export function parseOnTz(date, timeZone) {
  return utcToZonedTime(date, timeZone)
}

async function hide() {
  if (window.confirm("Are you sure you want to hide this time slot?")) {
    await API.patch(`admin/schedules/${_id}`, { json: { status: "Hidden" } })
    dispatch("updated")
  }
}

async function show() {
  if (window.confirm("Are you sure you want to show this time slot?")) {
    await API.patch(`admin/schedules/${_id}`, { json: { status: "Open" } })
    dispatch("updated")
  }
}

async function block() {
  if (window.confirm("Are you sure you want to block this time slot?")) {
    await API.delete(`admin/schedules/${_id}`)
    dispatch("deleted")
  }
}

async function toggleCheck() {
  checked = !checked
  dispatch("toggleCheck", {
    _id,
    type: slotTypes.available,
    action: checked ? "check" : "uncheck",
  })
}
</script>

<div
  class="absolute text-xs leading-none font-semibold truncate border-b border-transparent z-10"
  style="{calculateScheduleStyles({ startsAt, endsAt, column }, columns)}"
>
  <div class="flex w-full h-full p-1 truncate group" class:open class:_hidden>
    <div class="flex w-full items-center self-center">
      {#if checkboxVisible}
        <button class="focus:outline-none mr-1" on:click="{toggleCheck}">
          <Icon size="xl">
            {#if checked}check_box{:else}check_box_outline_blank{/if}
          </Icon>
        </button>
      {/if}
      <div
        class="flex flex-row gap-1 font-semibold cursor-pointer mr-auto hover:underline"
        on:click="{() => dispatch('opened')}"
      >
        <div>{format(parseOnTz(startsAt, timeZone), 'h:mmaaa')}</div>
        <div>
          {#if status === 'Open'}open{:else}hidden{/if}
        </div>
      </div>
      {#if $currentUser.roleLevel < 2}
        <div class="flex gap-1">
          {#if status === 'Open'}
            <button
              class="opacity-0 group-hover:opacity-100 focus:outline-none"
              on:click="{hide}"
            ><Icon size="md">visibility_off</Icon></button>
          {:else}
            <button
              class="opacity-0 group-hover:opacity-100 focus:outline-none"
              on:click="{show}"
            ><Icon size="md">visibility</Icon></button>
          {/if}
          <button
            class="opacity-0 group-hover:opacity-100 focus:outline-none"
            on:click="{block}"
          ><Icon size="md">block</Icon></button>
        </div>
      {/if}
    </div>
  </div>
</div>
