<script>
import { payload } from "@local/store/engine"

export let label
export let placeholder
export let rows
export let name
export let errors = []
export let inputEl

$: hasError = errors && errors.length > 0
$: inputEl

let value = $payload[name]

function input(evt) {
  $payload[name] = evt.target.value
}
</script>

<div class="{$$props.className || $$props.class || ''}">
  <label for="{name}">{label}</label>
  <textarea
    name="{name}"
    placeholder="{placeholder}"
    rows="{rows}"
    bind:value="{value}"
    bind:this="{inputEl}"
    on:input="{input}"
    class="w-full h-full input"
    class:with-error="{hasError}"
    on:change></textarea>

  {#if hasError}
    <div class="mt-2 error">{errors[0]}</div>
  {/if}
</div>
