<style>
menu {
  margin-block-start: 0.125rem;
}

.disabled {
  @apply bg-gray-200 text-gray-400;
}
</style>

<script>
import Icon from "@shared/components/Icon.svelte"

export let label = ""
export let className = ""
export let options = []

let showItems = false
let showAll = false

function showAllItems(show) {
  showAll = show
}
function handleMenu() {
  showItems = true
}
function closeMenu() {
  showItems = false
  showAll = false
}
</script>

<div
  class="{`relative bg-white hover:bg-slate-900 text-slate-900 hover:text-white border border-slate-900 rounded-md select-none ${className}`}"
  class:disabled="{!options.length}"
  on:mouseenter="{handleMenu}"
  on:mouseleave="{closeMenu}"
>
  <div class="flex items-center h-8 cursor-pointer">
    <span class="text-sm font-medium mx-2 hidden lg:block">{label}</span>
    {#if showItems}
      <Icon size="lg">expand_less</Icon>
    {:else}
      <Icon size="lg">expand_more</Icon>
    {/if}
  </div>

  {#if showItems}
    {#if !showAll}
      <menu
        class="shadow-2xl p-0 bg-white absolute right-0 w-60 overflow-hidden"
      >
        {#each options.slice(0, 6) as option}
          <a href="{option.url}" target="_blank" rel="noreferrer">
            <li
              class="relative block p-4 text-gray-600 font-semibold text-sm cursor-pointer hover:bg-gray-300 {option.className}"
            >
              {option.label}
            </li>
          </a>
        {/each}
        {#if options.length > 6}
          <li
            class="relative block p-4 text-gray-600 font-semibold text-sm cursor-pointer hover:bg-gray-300"
            on:click="{showAllItems(!showAll)}"
          >
            <div>
              <Icon size="lg">expand_more</Icon>
              <span class="align-top">More</span>
            </div>
          </li>
        {/if}
      </menu>
    {:else}
      <menu
        class="shadow-2xl p-0 bg-white absolute right-0 w-60 overflow-hidden"
      >
        {#each options as option}
          <a href="{option.url}" target="_blank" rel="noreferrer">
            <li
              class="relative block p-4 text-gray-600 font-semibold text-sm cursor-pointer hover:bg-gray-300 {option.className}"
            >
              {option.label}
            </li>
          </a>
        {/each}
        <li
          class="relative block p-4 text-gray-600 font-semibold text-sm cursor-pointer hover:bg-gray-300"
          on:click="{showAllItems(!showAll)}"
        >
          <div>
            <Icon size="lg">expand_less</Icon>
            <span class="align-top">Less</span>
          </div>
        </li>
      </menu>
    {/if}
  {/if}
</div>
