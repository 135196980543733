<script>
import { onMount } from "svelte"
import { navigate } from "svelte-routing"

export let location = {}
export let to = ""
export let replace = false
export let keepNext = false

onMount(() => {
  if (!keepNext) {
    navigate(to, { replace })
    return
  }
  const query = new URLSearchParams()
  query.set("next", location.pathname)
  navigate(`${to}?${query.toString()}`, { replace })
})
</script>
