<script>
import { tick } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"
import API, { anonymousApi, handleErrors } from "@local/utils/api"
import { partnerId } from "@local/utils/constants"
import { auth } from "@local/store/auth"

import Logo from "@local/components/images/Logo.svelte"
import TextInput from "@local/components/form/TextInput.svelte"

export let location = {}

let queryParams
$: queryParams = queryString.parse(location.search)
$: next = queryParams.next

let form = {
  email: "",
  password: "",
}
let errors = {}

const customErrMsgs = {
  email: {
    shouldBeValid: "Your email or password is incorrect.",
  },
}

async function submit() {
  try {
    const partnerPath = `partners/${partnerId}/`
    const tokens = await anonymousApi
      .post(`${partnerPath}tokens`, { json: form })
      .json()
    auth(tokens)
    await tick()

    API.post("two-factor-auth", {
      searchParams: {
        role: "admin",
      },
    }).then()

    const nextRoute = next
      ? `/two-factor-auth?next=${next}`
      : "/two-factor-auth"
    navigate(nextRoute, { replace: true })
  } catch (err) {
    errors = await handleErrors({ err, customErrMsgs })
  }
}
</script>

<div class="flex items-center justify-center h-screen background">
  <div class="w-full max-w-sm bg-white rounded-lg shadow-md">
    <form on:submit|preventDefault="{submit}">
      <div class="px-6 py-8">
        <div class="flex justify-center mb-8">
          <Logo class="h-24" darkMode="{false}" />
        </div>

        {#if errors.email}
          <div class="mb-6 text-center error">{errors.email[0]}</div>
        {/if}

        <TextInput
          bind:value="{form.email}"
          label="Email"
          name="email"
          type="email"
          class="mb-4"
        />

        <TextInput
          bind:value="{form.password}"
          label="Password"
          name="password"
          type="password"
          class="mb-4"
        />
      </div>

      <div class="flex justify-end px-6 py-3 rounded-b-lg bg-gray-50">
        <button type="submit" class="btn btn-solid">Sign In</button>
      </div>
    </form>
  </div>
</div>
