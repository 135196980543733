export const firstHour = 8
export const lastHour = 32 // 32 - 24 = 8 (includes the 0-7 hours at the end of the display instead of the start)

export const hourLabels = {
  0: "12am",
  1: "1am",
  2: "2am",
  3: "3am",
  4: "4am",
  5: "5am",
  6: "6am",
  7: "7am",
  8: "8am",
  9: "9am",
  10: "10am",
  11: "11am",
  12: "Noon",
  13: "1pm",
  14: "2pm",
  15: "3pm",
  16: "4pm",
  17: "5pm",
  18: "6pm",
  19: "7pm",
  20: "8pm",
  21: "9pm",
  22: "10pm",
  23: "11pm",
}

export const hours = new Array(lastHour - firstHour)
  .fill()
  .map((_, i) => i + firstHour >= 24 ? i + firstHour - 24 : i + firstHour)

export const slotTypes = { available: "available", taken: "taken", past: "past" }
