<script>
import Icon from "@local/components/images/Icon.svelte"
export let icon = ""
</script>

<div
  class="card flex flex-col py-8 space-y-2 text-xl font-semibold text-center border border-gray-200 border-dashed rounded-xl bg-gray-50 {$$props.class ||
    ''}"
>
  <Icon size="6xl">{icon}</Icon>
  <span><slot /></span>
</div>
