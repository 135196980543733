<script>
import DownloadLink from "./DownloadLink.svelte"

export let label = ""
export let files = []
export let className = ""
export let labelLink = ""
</script>

<div class="mb-6">
  <div class="text-xs font-semibold text-slate-900">{label}</div>

  <div class="{className}">
    {#each files as file}
      <span class="mb-4 text-green-600 ">
        <p class="text-gray-600">{file.path.split("/").pop()}</p>
        <DownloadLink
          links="{[{ url: file.url, label: labelLink }]}"
          icon="file_download"
        />
      </span>
    {/each}
  </div>
</div>
