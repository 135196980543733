<script>
import Icon from "@shared/components/Icon.svelte"
import ListCommunications from "@local/components/ListCommunications.svelte"
import * as transformCommunications from "@local/utils/transformCommunications"

export let content = []
export let leftIcon = ""
export let label = ""
export let type = "all"
export let url = ""
export let targetBlank = false
export let numberOfEventsToShow = 0

let itemsToShow = []

const emptyListMessages = {
  call: "no calls found",
  sms: "no messages found",
  all: "no events found",
}

$: {
  if (content?.length > 0) {
    let items = []
    if (type === "call") {
      items = transformCommunications.toCalls(content)
    } else if (type === "sms") {
      items = content
    } else {
      items = transformCommunications.toAllCommunications(content)
    }

    if (numberOfEventsToShow) {
      itemsToShow = items.slice(0, numberOfEventsToShow)
    } else {
      itemsToShow = items
    }
  }
}

let showOpened = true
let collapsed = showOpened === true
</script>

<div class="{$$props.className || ''}">
  {#if label}
    <div class="flex flex-row items-center cursor-pointer m-4">
      <a
        href="{url}"
        class="flex flex-1 items-center rounded select-none"
        target="{targetBlank ? '_blank' : '_self'}"
      >
        {#if leftIcon}
          <Icon class="text-slate-900 mr-2">{leftIcon}</Icon>
        {/if}
        <span
          class="h2 text-slate-900 font-semibold w-full text-base lg:text-xl"
        >
          {label}
        </span>
      </a>

      <div class="select-none" on:click="{() => (collapsed = !collapsed)}">
        <Icon class="text-gray-400 mx-2 hover:text-gray-600">
          <span class="text-3xl">
            {#if collapsed}expand_less{:else}expand_more{/if}
          </span>
        </Icon>
      </div>
    </div>
  {/if}

  <div class:block="{collapsed}" class:hidden="{!collapsed}">
    <div class="overflow-y-auto overflow-x-hidden">
      <ListCommunications
        content="{itemsToShow}"
        emptyListMessage="{emptyListMessages[type]}"
        typesToShow="{[type]}"
      />

      {#if itemsToShow?.length}
        <div class="text-sm text-gray-400 text-right pr-2 pb-1">
          <a
            href="{url}"
            target="{targetBlank ? '_blank' : '_self'}"
            class="cursor-pointer hover:text-gray-600 hover:underline"
          >
            ... show all
          </a>
        </div>
      {/if}
    </div>
  </div>
</div>
