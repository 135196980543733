<script>
import Icon from "@local/components/images/Icon.svelte"

//export let label = "Download"
export let links = []
export let className = ""
export let icon = undefined
</script>

<div class="w-full">
  {#each links as link}
    <a
      href="{link.url}"
      target="_blank"
      class="block {`hover:underline ${className}`}"
      >{#if icon}
        <Icon>{icon}</Icon>
      {:else}{link.label}{/if}</a
    >
  {/each}
</div>
