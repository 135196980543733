<script>
import Icon from "@shared/components/Icon.svelte"
import TemplateString from "@shared/components/TemplateString.svelte"
import CommunicationHeader from "./CommunicationHeader.svelte"

export let sms
export let reduced = true
</script>

<div
  class="bg-gray-100 rounded mt-4 p-2 border-white"
  class:border-2="{!reduced}"
>
  {#if !reduced}
    <div class="flex items-center">
      {#if sms.direction === "outbound"}
        <Icon class="text-slate-900 mx-2 text-xs">call_made</Icon>
      {:else}
        <Icon class="text-slate-900 mx-2 text-xs">call_received</Icon>
      {/if}
      <p class="text-slate-900 text-sm font-semibold">
        {sms.direction} SMS
      </p>
    </div>
  {/if}

  <CommunicationHeader
    direction="{sms?.direction}"
    reduced="{reduced}"
    target="{sms?.target}"
    contact="{sms?.contact}"
  />

  <div class="bg-white rounded p-1" class:pointer="{sms?.mms && sms?.mmsUrl}">
    <div class="text-sm flex items-center justify-center mb-1">
      <p class="text-gray-400" class:text-xs="{reduced}">
        <TemplateString
          content="{`{{${sms.createdDate}||formatDateShort}} {{${sms.createdDate}||formatFullTime}}`}"
        />
      </p>
    </div>

    {#if sms?.mms}
      <a
        href="{sms.mmsUrl}"
        class="text-gray-400 hover:text-slate-900"
        target="_blank"
        rel="noreferrer"
      >
        <div class="text-sm flex items-center my-2">
          <Icon class="mr-2">mms</Icon>

          <span class="text-slate-900 hover:underline">
            MMS sent (click to visualize)
          </span>
        </div>
      </a>
    {:else}
      <div class="bg-white rounded p-1">
        <p class="whitespace-pre-wrap" class:text-xs="{reduced}">
          {sms.text}
        </p>
      </div>
    {/if}
  </div>
</div>
