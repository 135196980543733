<script>
import { Tabs, TabList, TabPanel, Tab } from "./_Tabs"
import Render from "@local/components/engine/Render.svelte"

export let tabs = undefined
export let className = ""
</script>

<Tabs>
  <TabList className="tab-list px-8 border-b {className}">
    {#each tabs as tab}
      <Tab>{tab.label}</Tab>
    {/each}
  </TabList>
  {#each tabs as tab}
    <TabPanel className="flex-1 border-l border-r border-b bg-white p-8">
      {#each tab.elements as element}
        <Render element="{element}" />
      {/each}
    </TabPanel>
  {/each}
</Tabs>
