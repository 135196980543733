<script>
import { Query } from "@sveltestack/svelte-query"
import { startOfWeek, endOfWeek, format } from "date-fns"
import API from "@local/utils/api"
import List from "@local/components/List.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import Menu from "./Menu.svelte"

let queryVal = ""
let fromDateVal = format(startOfWeek(new Date()), "yyyy-MM-dd")
let toDateVal = format(endOfWeek(new Date()), "yyyy-MM-dd")
let filters = {
  query: "",
  scheduledToDateFrom: fromDateVal,
  scheduledToDateTo: toDateVal,
}

let csvUrl

function search() {
  const newFilters = {
    query: queryVal,
  }
  if (fromDateVal) newFilters.scheduledToDateFrom = fromDateVal
  if (toDateVal) newFilters.scheduledToDateTo = toDateVal
  filters = newFilters
}

async function fetchWorkflows(filters) {
  const defKey = "admStarseedReport"
  const searchParams = {
    role: "admin",
    page: 0,
    perPage: 10000000,
    ...filters,
  }
  const response = await API.get(`lists/def-key/${defKey}`, { searchParams })
  const rows = await response.json()

  if (rows.length > 0) {
    let csv = rows[0].columns.map((row) => row.label).join(",") + "\n"

    csv += rows
      .map((row) => row.columns.map((row) => row.value).join(","))
      .join("\n")

    csvUrl = URL.createObjectURL(new Blob([csv], { type: "text/csv" }))
  } else {
    csvUrl = URL.createObjectURL(new Blob([""], { type: "text/csv" }))
  }

  return { rows }
}

$: queryOptions = {
  queryKey: ["reports/starseed", JSON.stringify(filters)],
  queryFn: () => fetchWorkflows(filters),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

<Menu />

<Query options="{queryOptions}">
  <div class="px-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-6">
        <h2 class="h2">Report: Starseed</h2>
      </div>
      <div class="flex items-end gap-2 mb-2">
        <form
          class="flex items-end w-full gap-2"
          on:submit|preventDefault="{search}"
        >
          <TextInput bind:value="{fromDateVal}" label="From" type="date" />
          <TextInput bind:value="{toDateVal}" label="To" type="date" />
          <TextInput bind:value="{queryVal}" label="Local" placeholder="" />
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
            type="submit"><Icon size="3xl">search</Icon></button
          >

          <a
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
            type="button"
            href="{csvUrl}"
            target="blank"
            download="report.csv"><Icon size="3xl">download</Icon></a
          >
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <List rows="{queryResult.data.rows}" />
      {:else}
        <div class="mt-12 flex justify-center">
          <h2 class="h2">No records</h2>
        </div>
      {/if}
    {/if}
  </div>
</Query>
