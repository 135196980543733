<script>
import { Query, useQueryClient, useQuery } from "@sveltestack/svelte-query"
import queryString from "query-string"
import API from "@local/utils/api"
import List from "@local/components/List.svelte"
import Pager from "@local/components/Pager.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import MultiSelect from "@local/components/MultiSelect.svelte"
import {
  setWfFiltersToLocalStorage,
  getWfFiltersFromLocalStorage,
} from "@local/utils/localStorage"

export let location

const queryClient = useQueryClient()

let query
let queryOptions
let previousType
let perPage = 20
let lastQueryValLenght = 0
let type = queryString.parse(location.search).type
let stages = []
let storedFilters = getWfFiltersFromLocalStorage(type)
let page = storedFilters?.page || 0
let queryVal = storedFilters?.query || ""
let selectedSubdomains = storedFilters?.selectedSubdomains || null
let selectedStages = storedFilters?.selectedStages || null
let selectedStatus = storedFilters?.selectedStatus || null

$: wfFilters
  ? (query = {
      filter: storedFilters?.query,
      partnerOrigin: getSubdomainsGrouped(selectedSubdomains),
      currStageName: selectedStages,
      "props.returningPatient.value": parseStatus(selectedStatus),
    })
  : (query = {
      filter: "",
      partnerOrigin: [],
      currStageName: [],
      "props.returningPatient.value": [],
    })

$: type = queryString.parse(location.search).type
$: stages = getStages(wfFilters, selectedSubdomains || [])
$: previousType === type &&
  setWfFiltersToLocalStorage({
    wfType: type,
    selectedStages,
    selectedSubdomains,
    selectedStatus,
    page,
    query: queryVal,
  })

function getSubdomainsGrouped(originSubdomains) {
  let subdomains = []
  if (originSubdomains) {
    originSubdomains.forEach((subdomain) => {
      if (!wfFilters[subdomain]?.additionalSubdomains) {
        subdomains.push(subdomain)
      } else {
        subdomains = subdomains.concat([
          ...wfFilters[subdomain]?.additionalSubdomains,
        ])
      }
    })
  }
  return subdomains
}

function getStages(filters, subdomains) {
  if (wfFilters) {
    let updatedStages = []
    if (subdomains.length) {
      subdomains.forEach((subdomain) => {
        Object.assign(updatedStages, {
          ...filters[subdomain]?.stages,
        })
      })
    } else if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((subdomain) => {
        if (filters[subdomain]?.stages) {
          updatedStages = updatedStages.concat(...filters[subdomain].stages)
        }
      })
    }

    updatedStages = [...new Set([...updatedStages.sort()])]

    return updatedStages
  }
  return []
}

function search(pageNumber) {
  page = pageNumber
  query.partnerOrigin = getSubdomainsGrouped(selectedSubdomains)
  query.filter = queryVal
  query.currStageName = selectedStages
  query["props.returningPatient.value"] = parseStatus(selectedStatus)

  queryClient.invalidateQueries("workflows")
}

function parseStatus(selectedStatus) {
  return selectedStatus?.map((x) => x === "Renewal") || []
}

function clearFilters() {
  queryVal = ""
  selectedSubdomains = []
  selectedStages = []
  selectedStatus = []

  queryClient.invalidateQueries("workflowFilters")

  search(0)
}

function loadFilters(type) {
  queryClient.invalidateQueries("workflowFilters")
  storedFilters = getWfFiltersFromLocalStorage(type)

  page = storedFilters?.page || 0
  queryVal = storedFilters?.query || ""
  selectedSubdomains = storedFilters?.selectedSubdomains
  selectedStages = storedFilters?.selectedStages
  selectedStatus = storedFilters?.selectedStatus

  if (wfFilters) {
    search(page)
  }
}

async function fetchWorkflowFilters(type) {
  const searchParams = {
    role: "admin",
    type,
  }
  const response = await API.get("admin/workflow/filters", { searchParams })
  return await response.json()
}
const wfFiltersQuery = useQuery({
  queryKey: ["workflowFilters", type],
  queryFn: () => fetchWorkflowFilters(type),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
})
$: wfFilters = $wfFiltersQuery.data?.filters || null

async function fetchWorkflows(query, page, type) {
  query.filter = queryVal
  const defKey = type === "educator" ? "admEducatorWorkflows" : "admWorkflows"
  const searchParams = {
    role: "admin",
    page,
    perPage,
    query: JSON.stringify(query),
  }
  const response = await API.get(`lists/def-key/${defKey}`, { searchParams })
  const rows = await response.json()
  const total = response.headers.get("x-total-count")
  const hasNextPage = (page + 1) * perPage < total
  return { rows, hasNextPage, query }
}
$: {
  queryOptions = {
    queryKey: ["workflows", page, type],
    queryFn: () => fetchWorkflows(query, page, type),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  }

  if (previousType !== type) {
    previousType = type
    loadFilters(type)
  }
}

$: {
  if (lastQueryValLenght !== queryVal.length) {
    lastQueryValLenght = queryVal.length

    if (lastQueryValLenght === 0) {
      search(0)
    }
  }
}
</script>

<Query options="{queryOptions}">
  <div class="p-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-2 md:mb-6">
        <h2 class="h2">
          {#if queryResult.data.query?.filter}
            {#if type === "educator"}
              Educator Workflows Search:
              {queryResult.data.query.filter}
            {:else}
              Medical Workflows Search:
              {queryResult.data.query.filter}
            {/if}
          {:else if type === "educator"}
            Educator Workflows
          {:else}Medical Workflows{/if}
        </h2>
      </div>
      <div class="flex items-center mb-2">
        <Pager
          class="hidden md:flex"
          bind:page="{page}"
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
        <form
          class="flex items-center w-full ml-2 flex-col md:flex-row"
          on:submit|preventDefault="{() => search(0)}"
        >
          {#if type === "educator"}
            <TextInput
              bind:value="{queryVal}"
              type="search"
              class="w-full md:w-2/4 mt-2 md:mt-0"
              placeholder="Search by Patient Name, Educator Name, Email, Referral or Stage"
            />
          {:else}
            <TextInput
              bind:value="{queryVal}"
              type="search"
              class="w-full md:w-2/4 mt-2 md:mt-0"
              placeholder="Search by Patient Name, Practitioner Name, Email, Phones, Referral or Type"
            />
          {/if}
          {#if !wfFiltersQuery.isLoading}
            {#key wfFilters}
              <MultiSelect
                className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
                bind:value="{selectedSubdomains}"
                placeholder="Partners"
              >
                {#each Object.keys(wfFilters || {}) as subdomain}
                  <option value="{subdomain}">{subdomain}</option>
                {/each}
              </MultiSelect>
            {/key}
            {#key stages}
              <MultiSelect
                className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
                bind:value="{selectedStages}"
                placeholder="Stages"
              >
                {#each stages as stage}
                  <option value="{stage}">{stage}</option>
                {/each}
              </MultiSelect>
            {/key}
            {#if type === "medical"}
              {#key selectedStatus?.length === 0}
                <MultiSelect
                  className="w-full md:w-1/4 md:ml-2 mt-2 md:mt-0"
                  bind:value="{selectedStatus}"
                  placeholder="Status"
                >
                  <option value="New">New</option>
                  <option value="Renewal">Renewal</option>
                </MultiSelect>
              {/key}
            {/if}
          {/if}
          <div class="flex mt-2 md:mt-0 md:ml-2 w-full md:w-auto">
            <button
              title="Clear filters"
              class="flex items-center justify-center w-10 h-10 mr-2 btn btn-outline"
              on:click="{clearFilters}"
              type="button"
            >
              <Icon size="3xl">clear</Icon>
            </button>
            <button
              title="Search"
              class="flex items-center justify-center w-10 h-10 btn btn-outline"
              type="submit"
            >
              <Icon size="3xl">search</Icon>
            </button>
          </div>
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <List rows="{queryResult.data.rows}" />
      {/if}
      <div class="flex items-center justify-center mt-2">
        <Pager
          class="md:hidden"
          bind:page="{page}"
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
      </div>
    {/if}
  </div>
</Query>
