<script>
import * as Elements from "./elements"
import { payload, errors } from "@local/store/engine"

export let element

$: component = Elements[element.type]
</script>

{#if element}
  <svelte:component
    this="{component}"
    {...element?.props}
    allErrors="{$errors}"
    errors="{element?.props?.name && $errors?.[element?.props?.name]}"
    bind:payload="{$payload}"
    bind:value="{$payload[element?.props?.name]}"
  />
{/if}
