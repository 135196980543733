<script>
import Select from "@local/components/form/Select.svelte"
const allTypes = ["All Consults", "Phone", "Video"]

export let value
</script>

<div class="{$$props.class || ''}">
  <Select bind:value options="{allTypes}" />
</div>
