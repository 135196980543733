<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-50;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white w-96 rounded-sm shadow-sm px-4 py-6;
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import validate from "validate.js"
import API, { handleErrors } from "@local/utils/api"
import {
  canadaTimezonesPerProvince,
  usTimezonesPerState,
} from "@local/utils/timezones"
import TextInput from "@local/components/form/TextInput.svelte"
import Select from "@local/components/form/Select.svelte"

const dispatch = createEventDispatcher()

const allTimezones = {
  ...canadaTimezonesPerProvince,
  ...usTimezonesPerState,
}

export let user

let optionTimezoneSelected =
  Object.entries(allTimezones)
    .find((timezone) => timezone[1] === user?.timezone)
    ?.at(0) || ""

let form = {
  email: user?.email || "",
  timezone: allTimezones[user?.timezone] || "",
}
$: form.timezone = allTimezones[optionTimezoneSelected] || ""

let errors

const customErrMsgs = {
  email: {
    alreadyExists: "This email is taken.",
    notFound: "User not found.",
  },
}

const constraints = {
  email: { presence: { allowEmpty: false } },
  timezone: {
    presence: { allowEmpty: false },
    inclusion: Object.values({
      ...canadaTimezonesPerProvince,
      ...usTimezonesPerState,
    }),
  },
}

async function submit() {
  errors = validate(form, constraints)

  if (!errors) {
    try {
      if (user.email !== form.email || user.timezone !== form.timezone) {
        await API.patch(`admin/users/${user.id}`, { json: form }).json()
        dispatch("userUpdated")
      }
      dispatch("close")
    } catch (err) {
      errors = await handleErrors({ err, customErrMsgs })
    }
  }
}
</script>

<div class="background">
  <div class="modal">
    <h2 class="mb-8 h2">Edit User</h2>
    <form on:submit|preventDefault="{submit}">
      <TextInput
        bind:value="{form.email}"
        label="Email"
        name="email"
        errors="{errors && errors.email}"
        class="mb-4"
      />

      <Select
        bind:value="{optionTimezoneSelected}"
        label="Timezone"
        name="timezone"
        options="{Object.entries(allTimezones).map(([label]) => label)}"
        errors="{errors && errors.timezone}"
        allowEmpty="{false}"
        class="mb-4"
      />

      <div class="flex justify-end gap-2 mt-4">
        <button
          type="button"
          class="btn btn-outline-red"
          on:click="{() => dispatch('close')}">Cancel</button
        >
        <button type="submit" class="btn btn-solid-red">Save</button>
      </div>
    </form>
  </div>
</div>
