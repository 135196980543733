function orderByCreationDateDesc(a, b) {
  let date1 = new Date(a?.createdDate)

  let date2 = new Date(b?.createdDate)

  if (date1 > date2) {
    return -1
  }
  if (date1 < date2) {
    return 1
  }
  return 0
}

export function toCalls(data) {
  if (data && data.length <= 0) return []

  let calls = []

  data.forEach((callEvent) => {
    const {
      callId,
      masterCallId,
      entryPointCallId,
      state,
      eventTimestamp,
      dateStarted,
      dateConnected,
      direction,
      operatorTarget,
      target,
      contact,
      type,
    } = callEvent

    let createdDate = new Date(dateStarted)

    const currentCall = {
      user: callEvent.user,
      contact,
      target,
      masterCallId: callEvent?.masterCallId,
      entryPointCallId: callEvent?.entryPointCallId,
      callId: callId,
      state,
      direction,
      createdDate,
      eventTimestamp,
      type,
    }

    switch (state) {
      case "hangup":
        if ((dateConnected && (entryPointCallId || target.type === "user")) || (!dateConnected && !entryPointCallId && !masterCallId)) {
          currentCall["hangup"] = {
            startedAt: callEvent?.dateStarted,
            connectedAt: callEvent?.dateConnected,
            endedAt: callEvent?.dateEnded,
            duration: callEvent?.duration || "",
            totalDuration: callEvent?.totalDuration || "",
          }
          calls.push(currentCall)
        }
        break

      case "dispositions":
          const callIndex = calls.findIndex((call) => call.callId === Number(callId))
          let notes = calls[callIndex]?.dispositionNotes || []

          if (callEvent?.dispositionNotes?.length) {
            const authorName = operatorTarget?.name || ""
            const authorId = operatorTarget?.id || ""

            notes.concat(
              callEvent.dispositionNotes.map((note) => ({
                text: note.text,
                author:
                  note.author_id === authorId ? authorName : note?.author_id,
              }))
            )
        }
        currentCall["dispositions"] = {
          callDispositions : callEvent.callDispositions,
          dispositionNotes: notes,
          startedAt: callEvent?.dateStarted,
          endedAt: callEvent?.dateEnded,
          duration: callEvent?.duration || "",
        }

        calls.push(currentCall)
        break

      case "voicemail_uploaded":
        currentCall["voicemailUploaded"] = {
          voicemailLink: callEvent?.voicemailLink || "",
          startedAt: callEvent?.dateStarted,
        }
        calls.push(currentCall)
        break
    }
  })

  console.log({calls})
  return calls
}

export function toAllCommunications(data) {
  const calls = toCalls(data.filter((event) => event.type === "call"))
  const smss = data.filter((event) => event.type === "sms")

  return [...calls, ...smss].sort(orderByCreationDateDesc)
}
