<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import { startOfWeek, endOfWeek, format } from "date-fns"
import API from "@local/utils/api"
import List from "@local/components/List.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Select from "@local/components/form/Select.svelte"
import Icon from "@local/components/images/Icon.svelte"

const rankingOptions = [
  { label: "Platinum", value: 1 },
  { label: "Gold", value: 2 },
  { label: "Silver", value: 3 },
  { label: "Bronze", value: 4 },
  { label: "Limited Purchase", value: 5 },
  { label: "No Purchase", value: 10 },
]

const queryClient = useQueryClient()

let queryVal = 1
let fromDateVal = format(startOfWeek(new Date()), "yyyy-MM-dd")
let toDateVal = format(endOfWeek(new Date()), "yyyy-MM-dd")
let filters = {
  query: 1,
  expiresAtFrom: fromDateVal,
  expiresAtTo: toDateVal,
}

let csvUrl

function search() {
  const newFilters = {
    query: queryVal,
  }
  if (fromDateVal) newFilters.expiresAtFrom = fromDateVal
  if (toDateVal) newFilters.expiresAtTo = toDateVal
  filters = newFilters
}

async function fetchWorkflows(filters) {
  const defKey = "admPurchases"
  const searchParams = {
    role: "admin",
    page: 0,
    perPage: 10000000,
    ...filters,
  }
  const response = await API.get(`lists/def-key/${defKey}`, { searchParams })
  const rows = await response.json()

  if (rows.length > 0) {
    let csv = rows[0].columns.map((row) => row.label).join(",") + "\n"

    csv += rows
      .map((row) => row.columns.map((row) => row.value).join(","))
      .join("\n")

    csvUrl = URL.createObjectURL(new Blob([csv], { type: "text/csv" }))
  } else {
    csvUrl = URL.createObjectURL(new Blob([""], { type: "text/csv" }))
  }

  return { rows }
}

async function handleUpload(evt = {}) {
  const inputFiles = evt.target.files

  const reader = new FileReader()
  reader.onload = (readerEvent) => {
    const file = {
      src: readerEvent.target.result,
      name: inputFiles[0].name,
    }

    API.patch(`admin/reports/purchases`, { json: { csvBase64: file.src } })
      .then(() => {
        queryClient.invalidateQueries()
        alert("Processing records")
      })
      .catch((err) => {
        alert(`Failed updating records`)
      })
  }

  reader.readAsDataURL(inputFiles[0])
}

function resetInput(evt = {}) {
  evt.target.value = ""
}

$: queryOptions = {
  queryKey: ["reports/purchases", JSON.stringify(filters)],
  queryFn: () => fetchWorkflows(filters),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

<Query options="{queryOptions}">
  <div class="p-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-6">
        <h2 class="h2">Report: Purchases</h2>
      </div>
      <div class="flex items-end gap-2 mb-2">
        <form
          class="flex items-end w-full gap-2"
          on:submit|preventDefault="{search}"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 cursor-pointer btn btn-outline relative"
          >
            <input
              type="file"
              class="opacity-0 w-full h-full absolute top-0 left-0"
              on:change="{handleUpload}"
              on:click="{resetInput}"
            />
            <Icon size="3xl">upload_file</Icon>
          </div>
          <TextInput bind:value="{fromDateVal}" label="From" type="date" />
          <TextInput bind:value="{toDateVal}" label="To" type="date" />
          <Select
            bind:value="{queryVal}"
            label="Rankings"
            options="{rankingOptions}"
          />
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 btn btn-outline"
            type="submit"><Icon size="3xl">search</Icon></button
          >

          <a
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 btn btn-outline"
            type="button"
            href="{csvUrl}"
            target="blank"
            download="report.csv"><Icon size="3xl">download</Icon></a
          >
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <List rows="{queryResult.data.rows}" />
      {:else}
        <EmptyMessage icon="check">No records</EmptyMessage>
      {/if}
    {/if}
  </div>
</Query>
