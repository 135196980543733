export const partners = [
  {
    id: "5ffe4aa654f4105608116eb3",
    label: "MyMedi",
    origins: [
      "mymedi",
      "massageaddict",
      "seniorcareroc",
      "dosist",
      "floweroflife",
      "mymedimva",
    ],
  },
  {
    id: "60788c328e5a00002b00095c",
    label: "TidalHealth",
    origins: ["tidalhealth"],
  },
  {
    id: "60788bc78e5a00002b00095a",
    label: "Zyus",
    origins: ["zyus"],
  },
  {
    id: "5ffef80c52e22826bdf9211f",
    label: "HelloMD",
    origins: [
      "my",
      "illinois",
      "missouri",
      "newyork",
      "pennsylvania",
      "louisiana",
      "ohio",
    ],
  },
  {
    id: "6092d99c1d3f858a906a0fdb",
    label: "Starseed",
    origins: ["starseed"],
  },
  {
    id: "620567d9f8fa64f6fe59bf46",
    label: "Boulevard Med",
    origins: ["boulevardmed"],
  },
  {
    id: "62226c1ae70174319f77a8b9",
    label: "Canna Farms",
    origins: ["cannafarms"],
  },
  {
    id: "60c76f53550f1c04d8c39c18",
    label: "Tantus Health",
    origins: ["tantus"],
  },
  {
    id: "615f254d43f479134c658bc0",
    label: "Alterna Pharma",
    origins: ["alternapharma"],
  },
  {
    id: "629f866c039cd54b00590446",
    label: "Health Haven",
    origins: ["healthhaven"],
  },
  {
    id: "62d71629bd8b15065b504914",
    label: "Herbal Dispatch",
    origins: ["herbaldispatch"],
  },
  {
    id: "6304ec7b5a2b28092850b62e",
    label: "Syndicate",
    origins: ["syndicate"],
  },
  {
    id: "63486a9a03e108738388f3f5",
    label: "Tilray",
    origins: ["tilray", "tilraywomenshow"],
  },
  {
    id: "63939bf811f29b06b4e50f8b",
    label: "Aurora",
    origins: ["aurora", "cccc", "ccct"],
  },
  {
    id: "6419d3bfee5f260afb64524e",
    label: "Mendo",
    origins: ["mendo"],
  },
  {
    id: "64c027735e3129cf7a150257",
    label: "Pearson",
    origins: ["pearson"],
  },
  {
    id: "64bfdc79ecec0c93e5090934",
    label: "Cosmic",
    origins: ["cosmic"],
  },
  {
    id: "65024ade458bb305a676b363",
    label: "Leafly",
    origins: ["leafly, leafly-la", "leafly-oh"],
  },
  {
    id: "66ad36d5bcba631aa8930bb5",
    label: "Aqualitas",
    origins: ["aqualitas"],
  },
]
