<script>
import Icon from "@local/components/images/Icon.svelte"
import ListCommunications from "@local/components/ListCommunications.svelte"
import * as transformCommunications from "@local/utils/transformCommunications"

export let content = []
export let leftIcon = ""
export let label = ""
export let type = "all"
export let url = ""
export let targetBlank = false
export let numberOfEventsToShow = 0
export let collapsible = true
export let classContent = ""

let itemsToShow = []

const emptyListMessages = {
  call: "no calls found",
  sms: "no messages found",
  all: "no events found",
}

$: {
  if (content?.length > 0) {
    let items = []
    if (type === "call") {
      items = transformCommunications.toCalls(content)
    } else if (type === "sms") {
      items = content
    } else {
      items = transformCommunications.toAllCommunications(content)
    }

    if (numberOfEventsToShow) {
      itemsToShow = items.slice(0, numberOfEventsToShow)
    } else {
      itemsToShow = items
    }
  }
}

let showOpened = true
let collapsed = showOpened === true
</script>

<div class="dark:border-tertiary-dark {$$props.className || ''}">
  {#if label}
    <div class="flex flex-row items-center cursor-pointer m-4 text">
      <a
        href="{url}"
        class="flex flex-1 items-center rounded select-none hover:text-accent-red"
        target="{targetBlank ? '_blank' : '_self'}"
      >
        {#if leftIcon}
          <Icon class="mr-2">{leftIcon}</Icon>
        {/if}
        <span class="font-semibold w-full text-base lg:text-lg underline">
          {label}
        </span>
      </a>

      {#if collapsible}
        <div class="select-none" on:click="{() => (collapsed = !collapsed)}">
          <Icon class="mx-2 hover:text-accent-red">
            <span class="text-3xl">
              {#if collapsed}expand_less{:else}expand_more{/if}
            </span>
          </Icon>
        </div>
      {/if}
    </div>
  {/if}

  <div class:block="{collapsed}" class:hidden="{!collapsed}">
    <div class="overflow-y-auto overflow-x-hidden">
      <ListCommunications
        content="{itemsToShow}"
        emptyListMessage="{emptyListMessages[type]}"
        typesToShow="{[type]}"
        className="{classContent}"
      />

      {#if itemsToShow?.length}
        <div class="text-sm text text-right pr-2 pb-1">
          <a
            href="{url}"
            target="{targetBlank ? '_blank' : '_self'}"
            class="cursor-pointer hover:text-accent-red hover:underline"
          >
            ... show all
          </a>
        </div>
      {/if}
    </div>
  </div>
</div>
