<script>
import { onMount, onDestroy } from "svelte"
import socketio from "socket.io-client"
import { getAccessToken } from "@local/store/auth"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import List from "@local/components/List.svelte"

let socket

const observers = {
  awaiting: "group:*:6023780c6d286526183a0ed8",
  connected: `group:*:6023780c6d286526183a0ed7`,
  testSetup: `group:*:603931e7b584c45e41cefeae`,
}

async function connectWs() {
  const jwt = await getAccessToken()
  socket = socketio("SVELTE_APP_API_URL", {
    query: { jwt },
    transports: ["websocket"],
  })

  socket.on("connect", () => {
    socket.emit("observe", observers.awaiting)
    socket.emit("observe", observers.connected)
    socket.emit("observe", observers.testSetup)
  })

  socket.on("error", (err) => {
    console.log("err", err)
  })

  socket.on("initialState", ({ path, data }) => {
    switch (path) {
      case observers.awaiting:
        awaitingRows = data
        break
      case observers.connected:
        connectedRows = data
        break
      case observers.testSetup:
        testSetupRows = data
        break
    }
  })

  socket.on("set", ({ path, data, ...rest }) => {
    let currIdx

    switch (path) {
      case observers.awaiting:
        currIdx = awaitingRows.findIndex((row) => row.wfId === data.wfId)
        if (currIdx >= 0) {
          awaitingRows[currIdx] = data
        } else {
          awaitingRows.push(data)
        }
        awaitingRows = awaitingRows
        break
      case observers.connected:
        currIdx = connectedRows.findIndex((row) => row.wfId === data.wfId)
        if (currIdx >= 0) {
          connectedRows[currIdx] = data
        } else {
          connectedRows.push(data)
        }
        connectedRows = connectedRows
        break
      case observers.testSetup:
        currIdx = testSetupRows.findIndex((row) => row.wfId === data.wfId)
        if (currIdx >= 0) {
          testSetupRows[currIdx] = data
        } else {
          testSetupRows.push(data)
        }
        testSetupRows = testSetupRows
        break
    }
  })

  socket.on("unset", ({ path, data, ...rest }) => {
    switch (path) {
      case observers.awaiting:
        awaitingRows = awaitingRows.filter((row) => row.wfId !== data.wfId)
        break
      case observers.connected:
        connectedRows = connectedRows.filter((row) => row.wfId !== data.wfId)
        break
      case observers.testSetup:
        testSetupRows = testSetupRows.filter((row) => row.wfId !== data.wfId)
        break
    }
  })
}

onDestroy(function () {
  if (socket) {
    socket.disconnect()
  }
})

let awaitingRows = []
let connectedRows = []
let testSetupRows = []

onMount(connectWs)
</script>

<div class="p-5">
  <h2 class="mb-8 h2">Awaiting Patients</h2>
  {#if awaitingRows.length > 0}
    <List rows="{awaitingRows}" />
  {:else}
    <EmptyMessage icon="check">
      All awaiting patients taken care of!
    </EmptyMessage>
  {/if}

  <h2 class="my-8 h2">Connected Patients</h2>
  {#if connectedRows.length > 0}
    <List rows="{connectedRows}" />
  {:else}
    <EmptyMessage icon="check">
      All connected patients taken care of!
    </EmptyMessage>
  {/if}

  <h2 class="my-8 h2">Test Setup Patients</h2>
  {#if testSetupRows.length > 0}
    <List rows="{testSetupRows}" />
  {:else}
    <EmptyMessage icon="check">All test setup patients proceeded!</EmptyMessage>
  {/if}
</div>
