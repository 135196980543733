<script>
import { onDestroy } from "svelte"
import Render from "@local/components/engine/Render.svelte"
import BackBar from "@local/components/BackBar.svelte"
import API, { handleErrors } from "@local/utils/api"
import {
  payload,
  setPayload,
  clearErrors,
  setErrors,
  clearPayload,
} from "@local/store/engine"
import { navigate } from "svelte-routing"

export let wfId
export let viewKey
let data

async function fetchView({ wfId, viewKey }) {
  const role = ["role", "admin"]

  const searchParams = [role]
  const response = await API.get(`workflows/${wfId}/views/${viewKey}`, {
    searchParams,
  }).json()

  setPayload(response.payload)
  clearErrors()

  data = response
}

$: fetchView({ wfId, viewKey })
// const queryResult = useQuery(["workflowId", wfId, "views", viewKey], () => fetchView({ wfId, viewKey }))

async function submit() {
  try {
    const role = ["role", "admin"]
    const searchParams = [role]
    await API.patch(`workflows/${wfId}/views/${viewKey}`, {
      json: $payload,
      searchParams,
    }).json()
    navigate(`/workflows/${wfId}`)
  } catch (err) {
    console.log(err)
    let _errors = await handleErrors({ err, customErrMsgs: {} })
    setErrors(_errors)

    const firstField = Object.keys(_errors)[0]
    if (!Array.isArray(firstField)) {
      const el = document.querySelector(`#${firstField}`)
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" })
        return
      }
    }
  }
}

onDestroy(function () {
  clearPayload()
  clearErrors()
})
</script>

<!-- {#if $queryResult.isLoading}
    <div></div>
  {:else if $queryResult.error}
    <div>{$queryResult.error}</div>
  {:else} -->
{#if data}
  <BackBar on:navigateBack="{() => history.back()}" />

  <form
    on:submit|preventDefault
    class="w-full p-5"
    autocomplete="off"
    on:submit="{submit}"
  >
    {#each data.elements as element}
      <Render element="{element}" />
    {/each}
  </form>
{/if}
