<style>
.addMore {
  @apply flex items-center mt-6 cursor-pointer;
  font-size: 15px;
}
.addMore:hover {
  color: #e63c24;
}
.loading {
  @apply cursor-wait;
}
.remove {
  @apply absolute flex items-center cursor-pointer;
  font-size: 15px;
  right: 16px;
}

.remove:hover {
  color: #e63c24;
}
.hint {
  @apply text-center px-4 mt-4 mb-4;
  font-size: 15px;
  letter-spacing: -0.36px;
  line-height: 1.4;
}
.error {
  @apply mt-2 text-center;
  color: #c4272a;
}
</style>

<script>
import { workflowLang } from "@shared/store/workflowKey"
import { payload } from "@local/store/engine"
import {
  removeText,
  addAnotherText,
  loadingText,
  errorUploadingFileText,
  errorRemovingFileText,
  errorUploadingFileWithSameNameText,
} from "@shared/utils/translations"
import API from "@local/utils/api"

export let label = ""
export let multi = false
export let buttonLabel = ""
export let role = undefined
export let name = undefined
export let wfId = undefined
export let userId = undefined
export let allowedExtension = undefined
export let files = []
let value = $payload[name]

let uploadInput
let _errors = []

$: loading = false
$: errors = _errors || []
$: hasErrors = errors.length > 0
$: hasFile = files && files.length > 0

async function onChange(evt = {}) {
  try {
    loading = true
    const file = evt.target.files[0]
    const blob = await file.arrayBuffer()
    const contentType = file.type
    const fileName = file.name.substr(0, file.name.lastIndexOf("."))

    if (files && files.length > 1) {
      const fileNameAlreadyExists = files.find(
        (file) => file.split("/").pop() === fileName
      )

      if (fileNameAlreadyExists) {
        const errorMsg = errorUploadingFileWithSameNameText[$workflowLang]
        errors = [...errors, errorMsg]
        value = files
        return
      }
    }

    const { uploadUrl, url } = await API.post(`uploads/${wfId}`, {
      searchParams: { role: role },
      json: {
        field: name,
        fieldContentType: contentType,
        userId,
        fileName,
      },
    }).json()

    await fetch(uploadUrl, {
      method: "PUT",
      body: blob,
      headers: { "Content-Type": contentType },
    })

    files = multi ? [...files, url] : url
    $payload[name] = files
  } catch (err) {
    let errorMsg = errorUploadingFileText[$workflowLang]
    errors = [...errors, errorMsg]
  } finally {
    loading = false
  }
}

async function remove(filePath) {
  try {
    loading = true

    await API.delete(`uploads/${wfId}`, {
      searchParams: { role: role },
      json: {
        field: name,
        userId,
        path: filePath,
      },
    })

    files = multi ? files.filter((file) => file !== filePath) : null
    $payload[name] = files
  } catch (err) {
    let errorMsg = errorRemovingFileText[$workflowLang]
    errors = [...errors, errorMsg]
  } finally {
    loading = false
  }
}
</script>

<div class="{$$props.className}">
  {#if label}<label class="text" for="{name}">{label}</label>{/if}

  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}

  <input
    bind:value="{value}"
    bind:this="{uploadInput}"
    on:change="{onChange}"
    type="file"
    class="hidden"
    accept="{allowedExtension.join(',')}"
  />
  {#if !hasFile}
    {#if loading}
      <button type="button" class="mt-4 btn btn-outline" disabled="{loading}"
        >{loadingText[$workflowLang]}</button
      >
    {:else}
      <button
        type="button"
        class="mt-4 btn btn-outline"
        on:click="{() => uploadInput.click()}">{buttonLabel}</button
      >
    {/if}
  {/if}

  {#if hasFile}
    {#if multi}
      {#each files as file}
        <div class="relative mt-4 ml-8 dark:text-white">
          <span class="remove" on:click="{() => remove(file)}">
            <i class="material-symbols-outlined mr-2"> cancel </i>
            {removeText[$workflowLang]}
          </span>

          <p class="max-w-xs italic">{file.split("/").pop()}</p>
        </div>
      {/each}
    {:else}
      <div class="relative mt-4 ml-8 dark:text-white">
        <span class="remove" on:click="{() => remove(files)}">
          <i class="material-symbols-outlined mr-2"> cancel </i>
          {removeText[$workflowLang]}
        </span>

        <p class="max-w-xs italic">{files.split("/").pop()}</p>
      </div>
    {/if}
  {/if}

  {#if multi && hasFile}
    {#if loading}
      <span class="dark:text-white addMore loading">
        <i class="material-symbols-outlined mr-2"> hourglass_empty </i>
        {loadingText[$workflowLang]}
      </span>
    {:else}
      <span
        class="dark:text-white addMore"
        on:click="{() => uploadInput.click()}"
      >
        <i class="material-symbols-outlined mr-2"> add_circle </i>
        {addAnotherText[$workflowLang]}
      </span>
    {/if}
  {/if}
</div>
