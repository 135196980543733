<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import Icon from "@local/components/images/Icon.svelte"
import CreateNotesModal from "./CreateNotesModal.svelte"
import UpdateNotesModal from "./UpdateNotesModal.svelte"
import Breadcrumb from "@local/components/engine/elements/Breadcrumb.svelte"
import { currentUser } from "@local/store/auth"

const queryClient = useQueryClient()
let breadcrumbItems = []

export let wfId
let createModal
let updateModal
let originalNote
let currentUserId = $currentUser.id
let currentUserRoleLevel = $currentUser.roleLevel

async function fetchNotes({ wfId }) {
  const searchParams = { role: "admin" }
  const response = await API.get(`admin/workflow/${wfId}/notes`, {
    searchParams,
  })?.json()
  const rows = await response.notes
  breadcrumbItems = [
    { label: "Home", url: "/", icon: "home" },
    {
      label: "Workflows",
      url: "/workflows?type=medical",
      icon: "medical_information",
    },
    {
      label: `${response?.name || ""}`,
      url: `/workflows/${wfId}`,
      icon: "person",
    },
    { label: "Internal Notes" },
  ]
  return { rows }
}

async function deleteNote(note) {
  const confirmation = confirm(
    "Are you sure you want to remove this internal note?"
  )
  if (!confirmation) {
    return
  }

  try {
    await API.delete(`admin/workflow/${wfId}/notes`, {
      json: { note },
      searchParams: { role: "admin" },
    }).json()

    queryClient.invalidateQueries("internalNotes")
  } catch (err) {
    window.alert("Only the author can remove this note.")
    console.log({ err })
  }
}

async function pin(note) {
  const confirmation = confirm(
    "Are you sure you want to pin this internal note?"
  )
  if (!confirmation) return
  await updatePin(note, true)
}

async function unpin(note) {
  const confirmation = confirm(
    "Are you sure you want to unpin this internal note?"
  )
  if (!confirmation) return
  await updatePin(note, false)
}

async function updatePin(note, pin) {
  try {
    await API.patch(`admin/workflow/${wfId}/notes`, {
      json: { originalNote: note, pin },
      searchParams: { role: "admin" },
    })

    queryClient.invalidateQueries("internalNotes")
  } catch (err) {
    console.log({ err })
  }
}

async function openCreateNoteModal() {
  createModal = true
}

async function openUpdateNoteModal(note) {
  originalNote = note
  updateModal = true
}

$: queryOptions = {
  queryKey: ["internalNotes", wfId],
  queryFn: () => fetchNotes({ wfId }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

{#if createModal}
  <CreateNotesModal
    on:notesCreated="{() => queryClient.invalidateQueries('internalNotes')}"
    on:close="{() => (createModal = undefined)}"
    wfId="{wfId}"
  />
{/if}
{#if updateModal}
  <UpdateNotesModal
    note="{originalNote}"
    on:notesUpdated="{() => queryClient.invalidateQueries('internalNotes')}"
    on:close="{() => (updateModal = undefined)}"
    wfId="{wfId}"
  />
{/if}
<Query options="{queryOptions}">
  <div slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="p-5">
        <Breadcrumb items="{breadcrumbItems}" />
        <div class="flex flex-1 items-center mb-8">
          <h1 class="text-lg lg:text-2xl font-semibold text-slate-900">
            Internal Notes
          </h1>
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white hover:bg-slate-900 text-slate-900 hover:text-white border border-gray-200 shadow-sm focus:outline-none ml-4"
            on:click="{() => openCreateNoteModal()}"
            ><Icon size="3xl">add</Icon></button
          >
        </div>
        {#if queryResult.data.rows.length > 0}
          {#each queryResult.data.rows as row}
            <div class="mb-4 border-black bg-white p-4 w-full lg:w-1/2 rounded">
              <div class="flex">
                <div
                  class="font-semibold text-xs md:text-sm lg:text-base text-left mb-2 flex-1 text-slate-900"
                >
                  {row.message}
                </div>
                {#if !row.pin}
                  <div
                    class="self-center opacity-50 hover:opacity-100 cursor-pointer ml-2 mr-4"
                    on:click="{() => pin(row.message)}"
                  >
                    <img src="/images/pin.png" alt="pin" />
                  </div>
                {/if}
                {#if row.pin && currentUserRoleLevel < 2}
                  <div
                    class="self-center opacity-50 hover:opacity-100 cursor-pointer ml-2 mr-4"
                    on:click="{() => unpin(row.message)}"
                  >
                    <img src="/images/unpin.png" alt="unpin" />
                  </div>
                {/if}
                {#if row.authorId === currentUserId || currentUserRoleLevel < 2}
                  <div
                    class="opacity-50 hover:opacity-100 cursor-pointer mr-2"
                    on:click="{() => openUpdateNoteModal(row.message)}"
                  >
                    <Icon size="2xl">edit</Icon>
                  </div>
                {/if}
                {#if row.authorId === currentUserId || currentUserRoleLevel < 2}
                  <div
                    class="opacity-50 hover:opacity-100 cursor-pointer"
                    on:click="{() => deleteNote(row.message)}"
                  >
                    <Icon size="2xl">close</Icon>
                  </div>
                {/if}
              </div>
              <div
                class="italic text-right text-xs md:text-sm lg:text-base text-slate-900"
              >
                {row.name}
                @
                <TemplateString content="{`{{${row.date}||formatDateTime}}`}" />
              </div>
            </div>
          {/each}
        {/if}
      </div>
    {/if}
  </div>
</Query>
