<script>
import { Query, useQueryClient } from "@sveltestack/svelte-query"
import { navigate } from "svelte-routing"
import { currentUser } from "@local/store/auth"
import API from "@local/utils/api"
import TemplateString from "@shared/components/TemplateString.svelte"
import Pager from "@local/components/Pager.svelte"
import EmptyMessage from "@local/components/EmptyMessage.svelte"
import TextInput from "@local/components/form/TextInput.svelte"
import Icon from "@local/components/images/Icon.svelte"
import CreateUserModal from "./CreateUserModal.svelte"
import UpdateUserModal from "./UpdateUserModal.svelte"

const queryClient = useQueryClient()

let createModal
let updateUserModal = {
  show: false,
  user: null,
}

let perPage = 20
let page = 0
let query = ""
let queryVal = ""

$: query, (page = 0)

function search() {
  query = queryVal
}

async function fetchUsers(query, page) {
  const searchParams = { page, perPage, query }
  const response = await API.get(`admin/users`, { searchParams })
  const rows = await response.json()
  const total = response.headers.get("x-total-count")
  const hasNextPage = (page + 1) * perPage < total
  return { rows, hasNextPage, query }
}

async function auth(row) {
  const { path } = await API.put(`admin/users/${row.id}/auth-token`).json()
  const url = `SVELTE_APP_PROTOCOL://${row.partnerOrigin}.SVELTE_APP_DOMAIN/${path}`
  window.open(url)
}

async function toggleUserStatus(userId, disabled) {
  try {
    const confirmation = confirm(
      `Are you sure you want to ${disabled ? "able" : "disable"} this user?`
    )
    if (!confirmation) return

    const searchParams = {
      role: "admin",
    }

    disabled = !disabled

    await API.patch(`admin/users/${userId}`, {
      json: { disabled },
      searchParams,
    }).json()

    alert("Success!")
  } catch (err) {
    alert(`Failed: ${err}\n\nCheck if the user is not an admin`)
  } finally {
    await queryClient.refetchQueries()
  }
}

$: queryOptions = {
  queryKey: ["users", page, query],
  queryFn: () => fetchUsers(query, page),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
</script>

{#if createModal}
  <CreateUserModal
    on:userCreated="{() => queryClient.invalidateQueries('users')}"
    on:close="{() => (createModal = undefined)}"
  />
{/if}
{#if updateUserModal.show}
  <UpdateUserModal
    user="{updateUserModal.user}"
    on:userUpdated="{() => queryClient.invalidateQueries('users')}"
    on:close="{() => (updateUserModal.show = undefined)}"
  />
{/if}
<Query options="{queryOptions}">
  <div class="p-5" slot="query" let:queryResult>
    {#if queryResult.isLoading}
      <div></div>
    {:else if queryResult.error}
      <EmptyMessage icon="error">{queryResult.error}</EmptyMessage>
    {:else}
      <div class="flex flex-row items-center justify-between mb-6">
        <h2 class="h2">
          {#if queryResult.data.query}
            Users Search: "{queryResult.data.query}"
          {:else}Users{/if}
        </h2>
      </div>
      <div class="flex items-center gap-2 mb-2">
        <button
          class="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border border-gray-200 shadow-sm focus:outline-none"
          on:click="{() => (createModal = true)}"
          ><Icon size="3xl">add</Icon></button
        >
        <Pager
          bind:page="{page}"
          hasNextPage="{queryResult.data.hasNextPage}"
          isPreviousData="{queryResult.isPreviousData}"
        />
        <form
          class="flex items-center w-full"
          on:submit|preventDefault="{search}"
        >
          <TextInput
            bind:value="{queryVal}"
            class="w-full"
            type="search"
            placeholder="Search by Name or Email or Partner Origin"
          />
          <button
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 ml-2 bg-white border border-gray-200 shadow-sm focus:outline-none"
            type="submit"><Icon size="3xl">search</Icon></button
          >
        </form>
      </div>
      {#if queryResult.data.rows.length > 0}
        <table class="table">
          <thead>
            <th>Partner</th>
            <th>Name</th>
            <th>Email</th>
            <th>Timezone</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th></th>
          </thead>
          <tbody>
            {#each queryResult.data.rows as row}
              <tr
                class:text-gray-400="{row.disabled}"
                class:italic="{row.disabled}"
              >
                <td>{row.partner} / {row.partnerOrigin}</td>
                <td>{row.fullName}</td>
                <td
                  class:text-red-500="{!row.isEmailConfirmed && !row.disabled}"
                  class:text-green-500="{row.isEmailConfirmed && !row.disabled}"
                >
                  {row.email}
                  {#if row.disabled}(disabled){/if}
                </td>
                <td>{row.timezone}</td>
                <td>
                  <TemplateString
                    content="{`{{${row.createdAt}||formatDateTime}}`}"
                  />
                </td>
                <td>
                  <TemplateString
                    content="{`{{${row.updatedAt}||formatDateTime}}`}"
                  />
                </td>
                {#if !row.disabled}
                  <td class="text-right">
                    <button
                      type="button"
                      class="focus:outline-none"
                      on:click="{() =>
                        (updateUserModal = { show: !!row, user: row })}"
                      ><Icon>edit</Icon></button
                    >
                  </td>
                {:else}
                  <td></td>
                {/if}

                <td class="text-right">
                  {#if $currentUser && $currentUser.roleLevel <= 1}
                    <button
                      type="button"
                      class="focus:outline-none"
                      on:click="{() => toggleUserStatus(row.id, row.disabled)}"
                    >
                      {#if row.disabled}
                        <Icon>person_add</Icon>
                      {:else}
                        <Icon>not_interested</Icon>
                      {/if}
                    </button>
                  {/if}
                </td>

                {#if !row.disabled}
                  <td class="text-right">
                    {#if $currentUser && $currentUser.roleLevel <= 3}
                      <button
                        type="button"
                        class="focus:outline-none"
                        on:click="{() => auth(row)}"
                        ><Icon>lock_open</Icon></button
                      >
                    {/if}
                  </td>
                {/if}

                <td class="text-right">
                  {#if $currentUser && $currentUser.roleLevel <= 3 && $currentUser && row.roles.includes("patient") && row.isEmailConfirmed === true}
                    <button
                      type="button"
                      class="focus:outline-none"
                      on:click="{() => navigate(`/users/${row.id}`)}"
                      ><Icon>arrow_forward</Icon></button
                    >
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    {/if}
  </div>
</Query>
