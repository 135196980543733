<style>
span {
  @apply text-base px-2.5 py-0.5 my-auto rounded;
}
.info {
  background-color: #d6dee2;
  color: black;
}
.warn {
  background-color: #da282a;
  color: white;
}
.purchase-platinum {
  background-color: #c8d3e2;
  color: black;
}
.purchase-gold {
  background-color: #e6b21f;
  color: black;
}
.purchase-silver {
  background-color: #93a2b9;
  color: white;
}
.purchase-bronze {
  background-color: #f79144;
  color: black;
}
.purchase-limited-purchase {
  background-color: yellow;
  color: black;
}
.purchase-no-purchase {
  background-color: #da282a;
  color: white;
}
.status-active {
  background-color: #3df759;
  color: black;
}
.status-deactive {
  background-color: #da282a;
  color: white;
}
.appointment-approved {
  background-color: #56ccff;
  color: black;
}
.appointment-denied {
  background-color: #ff8c00;
  color: white;
}
.stage {
  background-color: #caf7ff;
  color: black;
}
</style>

<script>
import TemplateString from "@shared/components/TemplateString.svelte"

export let content = ""
export let type = ""

$: color = `${type.toLowerCase().replaceAll(" ", "-")}`
</script>

<span
  class="{color} text-base px-2.5 py-0.5 my-auto rounded {$$props.className ||
    ''}"
>
  <TemplateString content="{content}" />
</span>
